function generateRandomUser(id) {
    let role = "ROLE_ADMINISTRATOR";
    if (id % 3 === 1) {
        role = "ROLE_BMW";
    }
    if (id % 3 === 2) {
        role = "ROLE_DEALER";
    }
    return {
        id: id,
        login: `mario.rossi_${id}@bmw.it`,
        firstName: "Mario " + id,
        lastName: "Rossi",
        email: `mario.rossi_${id}@bmw.it`,
        authorities: [role],
        hstList: ["26559", "25308"],
        createdAt: "09/07/2016",
        updatedAt: "05/31/2013",
    };
}

const users = [];
for (let i = 1; i <= 100; i++) {
    users.push(generateRandomUser(i));
}
export default users;
