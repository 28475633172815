import axios from "axios";

export const USERS_URL = "/api/users";
const {REACT_APP_API_URL} = process.env;

export function createUser(user) {
    return axios.post(`${REACT_APP_API_URL}${USERS_URL}`, user);
}

export function getAllUsers() {
    return axios.get(`${REACT_APP_API_URL}${USERS_URL}`);
}

export function exportUsers() {
    return axios.get(`${REACT_APP_API_URL}${USERS_URL}/download`, {responseType: "arraybuffer"});
}

export function getUserByLogin(login) {
    return axios.get(`${REACT_APP_API_URL}${USERS_URL}/${login}`);
}

export function findUsers(params) {
    return axios.get(`${REACT_APP_API_URL}${USERS_URL}`, {params});
}

export function updateUser(user) {
    return axios.put(`${REACT_APP_API_URL}${USERS_URL}`, user);
}

export function getAllRoles() {
    return axios.get(`${REACT_APP_API_URL}${USERS_URL}/authorities`);
}