import {
    getUserFoldersWithWritePermission,
    userHasOnlyReadOnlyPermissions,
    userHasWritePermissionOverBrandAndFolderType
} from "./PermissionsHelpers";
import * as Yup from "yup";
import {folderTypeHasFieldRequired} from "./FolderTypesHelpers";

export function userCanCreateFolder(user) {
    if (user) {
        return !userHasOnlyReadOnlyPermissions(user);
    }
    return false;
}

export function userCanUpdateFolder(user, folder) {
    if (folder && user) {
        return (folder.numberOfDocuments <= 0) && userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type)
    }
    return false;
}

export function userCanDeleteFolder(user, folder) {
    return userCanUpdateFolder(user, folder);
}


export function getFoldersValidationSchema(brands, folderTypesExt, user, namePrefix = "",) {
    return Yup.object().shape({
        brand: Yup.string().required().oneOf(brands).max(255),
        type: Yup.string().required().max(255).when(`${namePrefix}brand`, (brand, schema) => {
            return schema.oneOf(getUserFoldersWithWritePermission(user, brand, folderTypesExt));
        }),
        processDate: Yup.date().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "PROCESS_DATE") ? schema.required() : schema.nullable();
        }),
        businessId: Yup.string().max(255).ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "BUSINESS_ID") ? schema.required() : schema;
        }),
        plate: Yup.string().max(255).ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "PLATE") ? schema.required() : schema;
        }),
        vin: Yup.string().max(17).ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "VIN") ? schema.required() : schema;
        }),
        notes: Yup.string().ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "NOTES") ? schema.required() : schema.nullable();
        }),
    });
}