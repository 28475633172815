import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as dealers from "../app/modules/Common/_redux/dealers/dealersRedux";
import * as configurations from "../app/modules/Common/_redux/configurations/configurationsRedux";

import {yupSlice} from "../_intesys/i18n/_redux/yupSlice";
import {usersSlice} from "../app/modules/Admin/_redux/users/usersSlice";
import {servicesSlice} from "../app/modules/Admin/_redux/services/servicesSlice";
import {activationsSlice} from "../app/modules/Admin/_redux/activations/activationsSlice";
import {licensesSlice} from "../app/modules/App/_redux/licenses/licensesSlice";
import {dossiersSlice} from "../app/modules/App/_redux/dossiers/dossiersSlice";
import {customersSlice} from "../app/modules/App/_redux/customers/customersSlice";
import {foldersSlice} from "../app/modules/App/_redux/folders/foldersSlice";
import {videosSlice} from "../app/modules/App/_redux/videos/videosSlice";
import {documentsSlice} from "../app/modules/App/_redux/documents/documentsSlice";
import {signedDocumentsSlice} from "../app/modules/App/_redux/signed-documents/signedDocumentsSlice";
import {auditsSlice} from "../app/modules/App/_redux/audits/auditsSlice";
import {statisticsSlice} from "../app/pages/dashboard/_redux/statistics/statisticsSlice";

export const rootReducer = combineReducers({
    yup: yupSlice.reducer,
    auth: auth.reducer,
    dealers: dealers.reducer,
    users: usersSlice.reducer,
    services: servicesSlice.reducer,
    licenses: licensesSlice.reducer,
    activations: activationsSlice.reducer,
    dossiers: dossiersSlice.reducer,
    customers: customersSlice.reducer,
    folders: foldersSlice.reducer,
    videos: videosSlice.reducer,
    documents: documentsSlice.reducer,
    signedDocuments: signedDocumentsSlice.reducer,
    audits: auditsSlice.reducer,
    configurations: configurations.reducer,
    statistics: statisticsSlice.reducer,
});

export function* rootSaga() {
    // yield all([auth.saga()]);
    //https://github.com/redux-saga/redux-saga/issues/160
    yield all([
        auth.saga(),
        dealers.saga(),
        configurations.saga(),
    ]);
}
