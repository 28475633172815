import * as requestFromServer from "./signedDocumentsCrud";
import {signedDocumentsSlice} from "./signedDocumentsSlice";

const {actions} = signedDocumentsSlice;

export const downloadSignedDocument = (signedDocumentId) => dispatch => {
    const key = signedDocumentId;
    dispatch(actions.downloadStart({key}));
    return requestFromServer
        .downloadSignedDocument(signedDocumentId)
        .then(response => {
            const mimeType = response.headers["mimetype"];
            const file = new Blob(
                [response.data],
                {type: mimeType});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            dispatch(actions.downloadDone({download: fileURL, key}));

            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(error => {
            dispatch(actions.downloadError({error, key}));
        });
}
