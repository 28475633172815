import axios from "axios";

export const DOSSIERS_URL = "/api/dossiers";
const {REACT_APP_API_URL} = process.env;

export function searchDossiers(data) {
    return axios.post(`${REACT_APP_API_URL}${DOSSIERS_URL}/search?page=${data.page}&size=${data.size}&sort=${data.sort}`, data);
}

export function createDossier(dossier) {
    return axios.post(`${REACT_APP_API_URL}${DOSSIERS_URL}`, dossier);
}

export function getDossierById(dossierId) {
    return axios.get(`${REACT_APP_API_URL}${DOSSIERS_URL}/${dossierId}`);
}

export function updateDossier(dossier) {
    return axios.put(`${REACT_APP_API_URL}${DOSSIERS_URL}`, dossier);
}

export function deleteDossier(dossierId) {
    return axios.delete(`${REACT_APP_API_URL}${DOSSIERS_URL}/${dossierId}`);
}

export function scheduleRspSynchronization(legalEntity) {
    return axios.post(`${REACT_APP_API_URL}${DOSSIERS_URL}/rsp-synchronization/${legalEntity}`);
}

export function getRspSynchronizationStatus(legalEntity) {
    return axios.get(`${REACT_APP_API_URL}${DOSSIERS_URL}/rsp-synchronization/${legalEntity}/status`);
}
