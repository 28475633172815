import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../../_intesys/layout";
import {Card, CardBody, CardHeader} from "../../../../_intesys/_partials/controls";
import {shallowEqual, useSelector} from "react-redux";
import {formatBytes, getChartDarkColor, getChartLightColor, STATE_ERROR} from "../../../../_intesys/_helpers";
import LoadingMessage from "../../../modules/Common/loading-dialog/LoadingMessage";
import ErrorAlert from "../../../modules/Common/error-alert/ErrorAlert";
import {FormattedMessage, injectIntl} from "react-intl";
import Chart from "react-apexcharts";
import ChartColorLegend from "./ChartColorLegend";


function SpaceGraph({className, intl, dealer}) {
    const uiService = useHtmlClassService();

    const {isError, spaceArchivedUsed, spaceArchivedUsedError, spaceArchivedTotal} = useSelector(
        (state) => ({
            isError: state.statistics.spaceArchivedUsedState === STATE_ERROR,
            spaceArchivedUsed: state.statistics.spaceArchivedUsed,
            spaceArchivedUsedError: state.statistics.spaceArchivedUsedError,
            spaceArchivedTotal: state.statistics.spaceArchivedTotal,
        }),
        shallowEqual
    );

    const [value, setValue] = useState();

    const [showLoading, setShowLoading] = useState(true);
    const [showData, setShowData] = useState(false);

    const [chartOptions, setChartOptions] = useState();
    const [chartSeries, setChartSeries] = useState();
    const [showChart, setShowChart] = useState(false);

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
            colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        if (spaceArchivedUsed >= 0 && !isNaN(spaceArchivedTotal)) {
            //spaceArchivedTotal:100=spaceArchivedUsed:x
            const value = spaceArchivedUsed * 100 / spaceArchivedTotal;
            setValue(value.toFixed(1));
            setShowLoading(false);
            setShowData(true);
        }

    }, [spaceArchivedUsed, spaceArchivedTotal]);

    useEffect(() => {
        if (isError) {
            setShowLoading(false);
        }
    }, [isError]);

    useEffect(() => {

        if (showData) {
            let type = 'ok';
            if (value > 60 && value < 90) {
                type = 'warning';
            } else if (value >= 90) {
                type = 'alert';
            }

            const lightColor = getChartLightColor(type);
            const darkColor = getChartDarkColor(type);

            const options = {
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            // size: "65%"
                        },
                        dataLabels: {
                            showOn: "always",
                            name: {
                                show: false,
                                fontWeight: "700",
                            },
                            value: {
                                color: layoutProps.colorsGrayGray700,
                                fontSize: "30px",
                                fontWeight: "700",
                                offsetY: 12,
                                show: true
                            },
                        },
                        track: {
                            // background: layoutProps.colorsThemeLightSuccess,
                            background: lightColor,
                            strokeWidth: '100%'
                        }
                    }
                },
                // colors: [layoutProps.colorsThemeBaseSuccess],
                colors: [darkColor],
                // stroke: {
                // lineCap: "round",
                // },
                labels: ["Progress"],
                grid: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }
                }
            };

            setChartOptions(options);
            setChartSeries([value]);
            setShowChart(true);
        }
    }, [layoutProps, showData, value]);

    const title = intl.formatMessage({id: "DASHBOARD.ARCHIVEDSPACE.TITLE"});
    const dealerName = dealer ? ' - ' + dealer.name : '';

    return (

        <>
            <Card className={className}>
                <CardHeader
                    title={title + dealerName}
                    className={"border-0 pt-5"} titleClassName={"font-weight-bolder text-white "}
                    icon={"/media/svg/icons/Devices/Server.svg"}
                >
                </CardHeader>
                <CardBody className={"d-flex flex-column"}>
                    <div className="flex-grow-1 has-chart-right">

                        <LoadingMessage isLoading={showLoading} className={"text-center"}/>

                        {showChart && <>
                            <div style={{
                                display: "flex",
                                flexWrap: 'wrap'
                            }}>
                                <ChartColorLegend/>
                                <Chart
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="radialBar"
                                    width="300"
                                />
                            </div>
                        </>}
                        <ErrorAlert error={spaceArchivedUsedError}/>
                    </div>
                    {showData &&
                        <div className="pt-5">
                            <table className={"table table-bordered"}>
                                <tbody>
                                <tr>
                                    <td>
                                        <FormattedMessage id={"DASHBOARD.ARCHIVEDSPACE.TOTAL"}/>
                                    </td>
                                    <td>
                                        {formatBytes(spaceArchivedTotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage id={"DASHBOARD.ARCHIVEDSPACE.USED"}/>
                                    </td>
                                    <td>
                                        {formatBytes(spaceArchivedUsed)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage id={"DASHBOARD.ARCHIVEDSPACE.REMAINING"}/>
                                    </td>
                                    <td>
                                        {formatBytes(spaceArchivedTotal - spaceArchivedUsed)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    }
                </CardBody>
            </Card>
        </>
    );
}


export default injectIntl(SpaceGraph);