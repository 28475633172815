const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc'); // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone');
const customParseFormat = require('dayjs/plugin/customParseFormat')
// const advancedFormat = require('dayjs/plugin/advancedFormat')


export const API_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";//"YYYY-MM-DDTHH:mm:ssZZ" "YYYY-MM-DDTHH:mm:ssS"
export const LOCAL_FORMAT = "YYYY-MM-DDTHH:mm";
export const ITALIAN_DATE_FORMAT = "DD/MM/YYYY";
export const ISO8601_DATE_FORMAT = "YYYY-MM-DD";

dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// dayjs.extend(advancedFormat);

export function getDayJsItem(datetime) {
    return dayjs(datetime);
}

export function convertDatetimeFromApi(datetime) {
    if (!datetime) {
        return datetime;
    }
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dayjs(datetime).tz(browserTimeZone).format(LOCAL_FORMAT);
}

export function convertDatetimeForApi(datetime) {
    if (!datetime) {
        return datetime;
    }
    const apiDate = dayjs(datetime);
    return apiDate.toISOString();
}

export function convertDateFromItalianToIso(date) {
    if (!date) {
        return date;
    }

    return dayjs(date, ITALIAN_DATE_FORMAT).format(ISO8601_DATE_FORMAT);
}

export function convertDateFromIsoToItalian(date) {
    if (!date) {
        return date;
    }
    return dayjs(date).format(ITALIAN_DATE_FORMAT);
}

export function formatDayJSForApi(dayjs) {
    return dayjs.toISOString();
}

export function getNow() {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dayjs().tz(browserTimeZone).format(LOCAL_FORMAT);
}

export function getNextYear() {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dayjs().add(1, 'year').tz(browserTimeZone).format(LOCAL_FORMAT);
}
