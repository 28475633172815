import React from "react";
import {BRAND_BMW, BRAND_BMW_I, BRAND_BMW_MOTORRAD, BRAND_MINI, BRANDS_VALUES, getBrandLogo} from "./BrandsHelpers";
import {userCanSeeAllDealers, userHasWritePermissionOverBrand} from "./PermissionsHelpers";
import {getBrandIntlIdFromValue} from "./IntlHelpers";
import {uniqBy} from "lodash";

const DEALER_TYPE = 'C'; //Concessionaria
const BRANCH_TYPE = 'F'; //Filiale
const SALON_TYPE = 'S'; //Salone
const GARAGE_TYPE = 'O'; //Officina
const NO_TYPE = '-'; //Nessun tipo

export const DEFAULT_SEARCH_TYPE = "";
export const RSP_SEARCH_TYPE = "RSP";
export const DMS_SEARCH_TYPE = "DMS";

//https://gitlab.intesys.it/bmw/bmw_sviluppo_rete_2017_portale_dealers/-/blob/master/app/Traits/ConcTrait.php
//https://gitlab.intesys.it/bmw/bmw_sviluppo_rete_2017_portale_dealers/-/blob/master/app/Models/ConcStorico.php
const APP_DEALER_TYPES = [
    DEALER_TYPE,
    BRANCH_TYPE,
    SALON_TYPE,
    GARAGE_TYPE
];

const SEARCH_TYPES = [
    RSP_SEARCH_TYPE,
    DMS_SEARCH_TYPE,
];

export function getDealersListForLegalEntitySelection(dealers) {
    let foundLegalEntities = [];

    if (typeof dealers !== 'object') {
        return false
    }

    return dealers.filter((dealer) => {
        if (!foundLegalEntities.find(foundLegalEntity => foundLegalEntity === dealer.legal_entity)) {
            foundLegalEntities.push(dealer.legal_entity);
            return true;
        }
        return false;
    });
}

export function getUniqueDealersListForUser(user, dealers) {
    if (typeof dealers !== 'object') {
        return [];
    }
    if (user && Array.isArray(user.hstList) && user.hstList.length > 0) {
        return getDealersListForLegalEntitySelection(dealers.filter((dealer => user.hstList.includes(dealer.hst2011))));
    }
    return getDealersListForLegalEntitySelection(dealers);
}


function getDealerFieldByBrand(brand) {
    let field = "";
    switch (brand) {
        case BRAND_BMW_I:
            field = "tipo_bmwi";
            break;
        case BRAND_MINI:
            field = "tipo_mini";
            break;
        case BRAND_BMW_MOTORRAD:
            field = "tipo_moto";
            break;
        case BRAND_BMW:
        default:
            field = "tipo_auto";
    }
    return field;
}

export function getDealersByBrand(dealers, brand) {
    const field = getDealerFieldByBrand(brand);
    return dealers.filter((dealer) => {
        return APP_DEALER_TYPES.find(type => type === dealer[field]);
    });
}

export function getDealersListForHstSelection(dealers, user, brand = null) {
    if (dealers) {
        let dealersList = dealers;
        if (brand) {
            dealersList = getDealersByBrand(dealers, brand);
        }
        if (userCanSeeAllDealers(user)) {
            return dealersList;
        }
        return dealersList.filter((dealer) => {
            let userCanSeeDealer = false;
            if (user.hstList.find(userHst => userHst === dealer.hst2011)) {
                // user has this hst
                const dealerBrands = getDealerBrands(dealer);
                for (let i = 0; i < dealerBrands.length; i++) {
                    // check the permission on brand
                    const dealerBrand = dealerBrands[i];
                    if (userHasWritePermissionOverBrand(user, dealerBrand)) {
                        userCanSeeDealer = true;
                        break;
                    }
                }
            }
            return userCanSeeDealer;
        });
    } else {
        return [];
    }
}

export function getDealersListFilteredByLegalEntity(dealers, legalEntity) {
    return dealers.filter(dealer => dealer.legal_entity === legalEntity);
}

export function getDealerLabelForLegalEntity(dealer) {
    return String(dealer.legal_entity) + " - " + dealer.ragione_sociale;
}

export function getDealerLabelForHst(dealer) {
    return String(dealer.hst2011) + " - " + dealer.name + " - " + dealer.citta;
}

export function getDealerLabelForHstWithType(dealer, brand = null) {
    if (brand) {
        const field = getDealerFieldByBrand(brand);
        return '[' + dealer[field] + ' ' + String(dealer.hst2011) + "] " + dealer.name + " - " + dealer.citta + " (" + getDealerBrands(dealer) + ")";
    }
    return '[' + String(dealer.hst2011) + "] " + dealer.name + " - " + dealer.citta + " (" + getDealerBrands(dealer) + ")";
}

export function getDealerLabelForHstWithBrands(dealer, intl) {
    return '[' + String(dealer.hst2011) + "] " + dealer.name + " - " + dealer.citta + " (" + getDealerBrandsLabel(dealer, intl) + ")";
}

export function getDealerFromLegalEntity(dealers, legalEntity) {
    return dealers.find(dealer => legalEntity === dealer.legal_entity);
}

export function getDealerLabelFromLegalEntity(dealers, legalEntity) {
    //const filteredDealers = getDealersListFilteredByLegalEntity(dealers, legalEntity);
    //return getDealerLabelForLegalEntity(filteredDealers[0]);
    //const dealer = dealers.find(dealer => legalEntity === dealer.legal_entity);
    const dealer = getDealerFromLegalEntity(dealers, legalEntity);
    return getDealerLabelForLegalEntity(dealer);
}

export function getDealerLabelForTableFromLegalEntity(dealers, legalEntity) {
    const dealer = getDealerFromLegalEntity(dealers, legalEntity);
    if (dealer) {
        return (<>{String(dealer.legal_entity)}<br/>{dealer.ragione_sociale}</>);
    }
    return (<>{legalEntity}</>);
}

export function getDealerLabelForTableFromHst(dealers, hst) {
    const dealer = getDealerFromHst(dealers, hst);
    if (dealer) {
        return (<>{String(dealer.hst2011)}<br/>{dealer.name}<br/>{dealer.citta}</>);
    }
    return (<>{hst}</>);
}


export function getDealerLabelFromHst(dealers, hst) {
    const dealer = getDealerFromHst(dealers, hst);
    return getDealerLabelForHst(dealer);
}

export function getDealerFromHst(dealers, hst) {
    return dealers.find(dealer => hst === dealer.hst2011);
}

export function getDealerLegalEntityFromHst(dealers, hst) {
    const dealer = getDealerFromHst(dealers, hst);
    return dealer.legal_entity;
}

export function getDealersLegalEntitiesFromNameOrLegalEntity(dealers, name, caseSensitive = false) {
    return uniqBy(dealers.filter((dealer) => {
        const label = getDealerLabelForLegalEntity(dealer);
        if (caseSensitive) {
            return label.includes(name);
        } else {
            return label.toLowerCase().includes(name.toLowerCase());
        }
        //return !user.hstList.find(userHst => userHst === dealer.hst2011);
    }), function (e) {
        return e.legal_entity;
    }).map((dealer) => {
        //TODO Distinct
        return dealer.legal_entity;
    });
}

export function getDealersHstFromNameOrHst(dealers, name, caseSensitive = false) {
    return uniqBy(dealers.filter((dealer) => {
        const label = getDealerLabelForHst(dealer);
        if (caseSensitive) {
            return label.includes(name);
        } else {
            return label.toLowerCase().includes(name.toLowerCase());
        }
        //return !user.hstList.find(userHst => userHst === dealer.hst2011);
    }), function (e) {
        return e.hst2011;
    }).map((dealer) => {
        //TODO Distinct
        return dealer.hst2011;
    });
}

/*
export function searchDealersFromName(searchText) {
    const dealers = [];

    return dealers.filter((dealer) => {
        const value = dealer.name.toString().trim().toLowerCase();
        return (value.indexOf(searchText) > -1)
    });
}

export function getDealersHstFromName(searchText) {
    const filteredDealers = searchDealersFromName(searchText);
    filteredDealers.map((dealer) => dealer.hst2011);
}
*/


export function getSearchTypes(dealer) {
    // todo:implement
    // solo dms se "O" in tutti i brand valorizzati
    // altrimenti rsp +dms

    if (dealer) {
        let hasOnlyO = true;

        for (let i = 0; i < BRANDS_VALUES.length; i++) {
            const brand = BRANDS_VALUES[i];
            const field = getDealerFieldByBrand(brand);
            hasOnlyO = hasOnlyO && (dealer[field] === GARAGE_TYPE || dealer[field] === NO_TYPE);
        }

        if (hasOnlyO) {
            return [DMS_SEARCH_TYPE];
        } else {
            return SEARCH_TYPES;
        }
    } else {
        return [];
    }
}

export function isDmsSearch(searchType) {
    return searchType === DMS_SEARCH_TYPE;
}

export function isRspSearch(searchType) {
    return searchType === RSP_SEARCH_TYPE;
}


export function getSearchTypesFromHst(dealers, hst) {
    if (dealers && hst) {
        const dealer = getDealerFromHst(dealers, hst);
        return getSearchTypes(dealer);
    } else {
        return [];
    }
}

export function getDealerBrands(dealer) {
    return BRANDS_VALUES.filter((brand) => {
        const field = getDealerFieldByBrand(brand);
        return APP_DEALER_TYPES.find(type => type === dealer[field]);
    })
}

export function getDealerBrandsFromHst(dealers, hst) {
    const dealer = getDealerFromHst(dealers, hst);
    return getDealerBrands(dealer);
}

export function getDealerBrandsLabel(dealer, intl) {
    if (dealer && intl) {
        const hstBrands = getDealerBrands(dealer);
        const hstBrandsLabels = hstBrands.map((brand) => intl.formatMessage({id: getBrandIntlIdFromValue(brand)}));
        return hstBrandsLabels.join(', ');
    } else {
        return '';
    }
}

export function getDealerBrandsLabelFromHst(dealers, hst, intl) {
    if (dealers && hst && intl) {
        const dealer = getDealerFromHst(dealers, hst);
        return getDealerBrandsLabel(dealer, intl);
    } else {
        return '';
    }
}

export function getDealerBrandsImages(dealer, intl, white) {
    if (dealer && intl) {
        const hstBrands = getDealerBrands(dealer);
        const hstBrandsLabels = hstBrands.map((brand) =>
            getBrandLogo(brand, intl, null, "32px", white)
        );
        return hstBrandsLabels;
    } else {
        return '';
    }
}

export function getDealerBrandsImagesFromHst(dealers, hst, intl, white) {
    if (dealers && hst && intl) {
        const dealer = getDealerFromHst(dealers, hst);
        return getDealerBrandsImages(dealer, intl, white);
    } else {
        return '';
    }
}

export function getDealerHstListByBrand(dealers, brand) {
    const dealerList = getDealersByBrand(dealers, brand);
    return dealerList.map(dealer => dealer.hst2011);
}

export function getDealerHstOptions(dealers, user, brand = null) {

    const dealerList = getDealersListForHstSelection(dealers, user, brand);
    //filter remove Dipendente BMW dealers
    return dealerList ? dealerList.filter(dealer => (!!dealer.hst2011)).map(dealer => {
        return {
            value: dealer.hst2011,
            label: getDealerLabelForHst(dealer),
        };
    }) : [];
}


