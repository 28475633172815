import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {FieldTooltip} from "./FieldTooltip";
import {getIn} from "formik";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-check-input"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function Boolean({
                            field, // { name, value, onChange, onBlur }
                            form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            label,
                            initialFeedBackLabel,
                            withFeedbackLabel = true,
                            customFeedbackLabel,
                            type = "text",
                            checkBoxLabel,
                            helpText = "",
                            ...props
                        }) {

    const fieldError = getIn(errors, field.name);
    const fieldTouched = getIn(touched, field.name);

    return (
        <>
            {label && <label>{label}<FieldTooltip text={helpText}/></label>}
            <div className={"form-check"}>
                <input
                    type={type}
                    id={field.name}
                    className={getFieldCSSClasses(fieldTouched, fieldError)}
                    {...field}
                    {...props}
                    value={field.value || ''}
                /><label className={"form-check-label"} htmlFor={field.name}>{checkBoxLabel}</label>
            </div>
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={fieldError}
                    touched={fieldTouched}
                    label={label}
                    initialFeedBackLabel={initialFeedBackLabel}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
