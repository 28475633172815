import qs from "qs";
import {actionTypes} from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {

    //https://stackoverflow.com/questions/52482203/axios-multiple-values-comma-separated-in-a-parameter
    //https://stackoverflow.com/questions/39116731/how-to-prevent-axios-from-encoding-my-request-parameters/39152383#39152383
    axios.defaults.paramsSerializer = function (params) {
        return qs.stringify(params, { allowDots: true });
    }

    axios.interceptors.request.use(
        config => {
            const {
                auth: {authToken}
            } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );
    axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response && error.response.status === 401) {
                //TODO: validate token and logout
                store.dispatch({type: actionTypes.Logout});
                //   window.location.href="";
            }
            return Promise.reject(error);
        }
    );
}
