import {createSlice} from "@reduxjs/toolkit";
import {downloadDone, downloadError, downloadStart, initialState} from "../../../../../_intesys/_helpers";

export const auditsSlice = createSlice({
    name: "audits",
    initialState: {
        ...initialState,
    },
    reducers: {
        downloadStart: downloadStart,
        downloadDone: downloadDone,
        downloadError: downloadError,
    }
});
