import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {checkIsActive} from "../../../../../_metronic/_helpers";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {userCanSeeLicenses, userCanSeeUsers, userIsAdmin} from "../../../../_helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faArchive,
    faEllipsisH,
    faHome,
    faKey,
    faUsers,
    faServer,
    faFileInvoice,
    faFilm
} from "@fortawesome/free-solid-svg-icons";

export function AsideMenuList({layoutProps}) {
    const {REACT_APP_SAFETY_TOOL_URL} = process.env;
    const safetyToolUrl = REACT_APP_SAFETY_TOOL_URL;

    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    const {user} = useSelector(state => state.auth);
    const isAdmin = userIsAdmin(user);
    const canSeeLicenses = userCanSeeLicenses(user);
    const canSeeAllUser = userCanSeeUsers(user);


    let adminMenu = undefined;
    if (isAdmin || canSeeAllUser) {
        adminMenu = <>
            {/* Admin */}
            {/* begin::section */}
            <li className="menu-section ">
                <h4 className="menu-text">
                    <FormattedMessage id={"MENU.ADMIN"}/>
                </h4>
                {/*<i className="menu-icon flaticon-more-v2"/>*/}
                <span className={"menu-icon"}>
                    <FontAwesomeIcon icon={faEllipsisH}/>
                </span>
            </li>
            {/* end:: section */}


            <li
                className={`menu-item ${getMenuItemActive(
                    "/admin/users"
                )}`}
                aria-haspopup="true"
            >
                <NavLink className="menu-link" to="/admin/users">
                    <span className="svg-icon menu-icon">
                        <FontAwesomeIcon icon={faUsers}/>
                    </span>
                    <span className="menu-text">
                        <FormattedMessage id={"MENU.USERS"}/>
                    </span>
                </NavLink>
            </li>

            {canSeeLicenses && <li
                className={`menu-item ${getMenuItemActive(
                    "/app/licenses"
                )}`}
                aria-haspopup="true"
            >
                <NavLink className="menu-link" to="/app/licenses">
                    <span className="svg-icon menu-icon">
                        <FontAwesomeIcon icon={faKey}/>
                    </span>
                    <span className="menu-text">
                        <FormattedMessage id={"MENU.LICENSES"}/>
                    </span>
                </NavLink>
            </li>
            }

            {isAdmin && <li

                className={`menu-item ${getMenuItemActive(
                    "/admin/services"
                )}`}
                aria-haspopup="true"
            >
                <NavLink className="menu-link" to="/admin/services">
                    <span className="svg-icon menu-icon">
                        <FontAwesomeIcon icon={faServer}/>
                    </span>
                    <span className="menu-text">
                        <FormattedMessage id={"MENU.SERVICES"}/>
                    </span>
                </NavLink>
            </li>
            }
        </>
    }


    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <FontAwesomeIcon icon={faHome}/>
                        </span>

                        <span className="menu-text">
                            <FormattedMessage id={"MENU.DASHBOARD"}/>
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {adminMenu}

                {/* Admin */}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">
                        <FormattedMessage id={"MENU.APP"}/>
                    </h4>
                    {/*<i className="menu-icon flaticon-more-v2"/>*/}
                    <span className={"menu-icon"}>
                        <FontAwesomeIcon icon={faEllipsisH}/>
                    </span>
                </li>
                {/* end:: section */}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/app/dossiers", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/dossiers">
                        <span className="svg-icon menu-icon">
                            <FontAwesomeIcon icon={faAddressBook}/>
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id={"MENU.DOSSIERS"}/>
                        </span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/app/folders", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/folders">
                        <span className="svg-icon menu-icon">
                            <FontAwesomeIcon icon={faArchive}/>
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id={"MENU.FOLDERS"}/>
                        </span>
                    </NavLink>
                </li>

                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">
                        <FormattedMessage id={"MENU.SAFETY"}/>
                    </h4>
                    {/*<i className="menu-icon flaticon-more-v2"/>*/}
                    <span className={"menu-icon"}>
                        <FontAwesomeIcon icon={faEllipsisH}/>
                    </span>
                </li>
                {/* end:: section */}
                <li
                    className={`menu-item`}
                    aria-haspopup="true"
                >
                    <a
                        className="menu-link"
                        href={safetyToolUrl}
                        target={'_blank'}
                        rel={"noopener noreferrer"}
                    >
                        <span className="svg-icon menu-icon">
                            <FontAwesomeIcon icon={faFileInvoice}/>
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id={"MENU.SAFETY_TEXT"}/>
                        </span>
                    </a>
                </li>
                {/*end::1 Level*/}

                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">
                        <FormattedMessage id={"MENU.VIDEO"}/>
                    </h4>
                    {/*<i className="menu-icon flaticon-more-v2"/>*/}
                    <span className={"menu-icon"}>
                        <FontAwesomeIcon icon={faEllipsisH}/>
                    </span>
                </li>
                {/* end:: section */}

                <li
                    className={`menu-item`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/videos">

                    <span className="svg-icon menu-icon">
                        <FontAwesomeIcon icon={faFilm}/>
                    </span>
                        <span className="menu-text">
                        <FormattedMessage id={"MENU.VIDEO_TEXT"}/>
                    </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
