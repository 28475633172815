import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../../_intesys/layout";
import {Card, CardBody, CardHeader} from "../../../../_intesys/_partials/controls";
import {shallowEqual, useSelector} from "react-redux";
import {
    getFolderLabelFromType,
    getFolderTypeColor,
    STATE_ERROR
} from "../../../../_intesys/_helpers";
import LoadingMessage from "../../../modules/Common/loading-dialog/LoadingMessage";
import ErrorAlert from "../../../modules/Common/error-alert/ErrorAlert";
import {injectIntl} from "react-intl";
import Chart from "react-apexcharts";

function SignedDocumentsGraph({className, intl, dealer}) {
    const uiService = useHtmlClassService();

    const {isError, signedDocumentsCount, error, folderTypesExt} = useSelector(
        (state) => ({
            isError: state.statistics.signedDocumentsCountState === STATE_ERROR,
            signedDocumentsCount: state.statistics.signedDocumentsCount,
            error: state.statistics.signedDocumentsCountError,
            folderTypesExt: state.configurations.folderTypesExt,
        }),
        shallowEqual
    );

    // const [value, setValue] = useState();

    const [showLoading, setShowLoading] = useState(true);
    const [showData, setShowData] = useState(false);

    const [chartOptions, setChartOptions] = useState();
    const [chartSeries, setChartSeries] = useState();
    const [showChart, setShowChart] = useState(false);

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
            colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {

        if (signedDocumentsCount) {
            setShowLoading(false);
            setShowData(true);
        }

    }, [signedDocumentsCount]);

    useEffect(() => {
        if (isError) {
            setShowLoading(false);
        }
    }, [isError]);


    useEffect(() => {

        if (showData && signedDocumentsCount) {

            const values = Object.keys(signedDocumentsCount).map((key) => {
                return signedDocumentsCount[key]
            });
            const labels = Object.keys(signedDocumentsCount).map((key) => (
                getFolderLabelFromType(key, folderTypesExt)
            ));
            const colors = Object.keys(signedDocumentsCount).map((key) => (
                getFolderTypeColor(key)
            ));
            // https://apexcharts.com/docs/chart-types/pie-donut/
            // https://apexcharts.com/docs/options/plotoptions/pie/
            const options = {
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    textAnchor: 'middle',
                    distributed: false,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        colors: undefined
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#fff',
                        opacity: 0.9,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                plotOptions: {
                    pie: {
                        // customScale: 0.8,
                        dataLabels: {
                            // offset: 0,
                            // minAngleToShowLabel: 30,
                        },

                        donut: {
                            labels: {
                                show: true,
                            },
                        }
                    }
                },
                colors: colors,
                labels: labels
            };

            setChartOptions(options);
            setChartSeries(values);
            setShowChart(true);
        }
    }, [layoutProps, showData, signedDocumentsCount, folderTypesExt, intl]);

    const title = intl.formatMessage({id: "DASHBOARD.SIGNED_DOCUMENTS_COUNT.TITLE"});
    const dealerName = dealer ? ' - ' + dealer.name : '';

    return (

        <>
            <Card className={className}>
                <CardHeader
                    title={title + dealerName}
                    className={"border-0"}
                    titleClassName={"border-0 pt-5"}
                    icon={"/media/svg/icons/Files/File-done.svg"}
                >
                </CardHeader>
                <CardBody className={"d-flex flex-column"}>
                    <div className="flex-grow-1">
                        <LoadingMessage isLoading={showLoading} className={"text-center"}/>

                        {showChart &&
                            <div style={{marginTop: "30px"}} className={"text-center"}>
                                <Chart
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="donut"
                                    width="450"
                                />
                            </div>
                        }

                        <ErrorAlert error={error}/>
                    </div>
                    {showData &&
                        <div className="pt-5">
                            <table className={"table table-bordered"}>
                                <tbody>
                                {Object.keys(signedDocumentsCount).map((key) => (
                                    <tr key={key}>
                                        <td>
                                            {getFolderLabelFromType(key, folderTypesExt)}
                                        </td>
                                        <td>
                                            {signedDocumentsCount[key]}
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>

                        </div>
                    }
                </CardBody>
            </Card>

        </>
    );
}


export default injectIntl(SignedDocumentsGraph);