import * as requestFromServer from "./statisticsCrud";
import {statisticsSlice} from "./statisticsSlice";
import {getDocumentsCount, searchDocumentsExt} from "../../../../modules/App/_redux/documents/documentsCrud";
import CPromise from "c-promise2";

const {actions} = statisticsSlice;

let signedDocumentsCallCount = 0;

export const readStatistics = (dealerLegalEntity, folderTypesExt) => dispatch => {

    dispatch(actions.smsUsedCall({}));
    dispatch(actions.spaceUsedCall({}));
    dispatch(actions.documentsCall({}));
    dispatch(actions.signedDocumentsCountCall({}));

    // SMS

    requestFromServer.getNumberOtpUsed(dealerLegalEntity)
        .then(response => {
            let {used: smsUsed, total: smsTotal} = response.data;
            // setTimeout(function (){
            dispatch(actions.smsUsedDone({smsUsed, smsTotal}));
            // },3000);
            // dispatch(actions.smsUsedError({error:{message:"Si è verificato un errore"}}));
        })
        .catch(error => {
            dispatch(actions.smsUsedError({error}));
        });

    // SPACE

    requestFromServer.getDocumentsSize(dealerLegalEntity)
        .then(response => {
            let data = response.data;
            const spaceUsed = data.totalSizeUsed.size;
            const spaceTotal = data.totalSizeAvailable.size;
            dispatch(actions.spaceUsedDone({spaceUsed, spaceTotal}));
        })
        .catch(error => {
            dispatch(actions.spaceUsedError({error}));
        });

    // ARCHIVED SPACE

    requestFromServer.getArchivedDocumentsSize(dealerLegalEntity)
        .then(response => {
            let data = response.data;
            const spaceArchivedUsed = data.totalSizeUsed.size;
            const spaceArchivedTotal = data.totalSizeAvailable.size;
            dispatch(actions.spaceArchivedUsedDone({spaceArchivedUsed, spaceArchivedTotal}));
        })
        .catch(error => {
            dispatch(actions.spaceArchivedUsedError({error}));
        });

    // DOCUMENTS
    dispatch(readDocumentsStatistics(dealerLegalEntity));

    let result = {};
    signedDocumentsCallCount = folderTypesExt.length;

    CPromise.all(folderTypesExt, {
        mapper: async (folderTypeExt) => {
            const folderType = folderTypeExt.slug;

            result[folderType] = null;

            const getDocumentsCountParams = {
                "status.in": ["PRIVACY_UPDATING", "SIGNED"],
                "folderType.equals": folderType,
                "legalEntity.equals": dealerLegalEntity
            }
            getDocumentsCount(getDocumentsCountParams).then(response => {
                result[folderType] = response.data;
                signedDocumentsCallCount--;
                if (signedDocumentsCallCount === 0) {
                    dispatch(actions.signedDocumentsCountFetched({signedDocumentsCount: result}));
                }
            })
        }
    }).catch(error => {
        dispatch(actions.signedDocumentsCountError({error}));
    });
};

export const readDocumentsStatistics = (dealerLegalEntity) => dispatch => {
    const getDocumentsParams = {
        sort: "id,desc",
        page: 0,
        size: 15,
        status: {
            in: [
                "DRAFT",
                "READY_TO_SIGN",
                "PENDING_START_SIGN"
            ]
        },
        legalEntity: {
            "equals": dealerLegalEntity
        }

    }

    searchDocumentsExt(getDocumentsParams)
        .then(response => {
            let documents = response.data;
            dispatch(actions.documentsFetched({documents}));
        })
        .catch(error => {
            dispatch(actions.documentsError({error}));
        });
}
