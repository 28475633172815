import React from "react";
import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/it";
import flatten from "flat";
import YupSetLocale from "./YupSetLocale";
import enMessages from "./messages/en";
import itMessages from "./messages/it";


const dayjs = require('dayjs');
require('dayjs/locale/it');
require('dayjs/locale/en');


itMessages["WIZARD"]["FIELDS"]["DOSSIER"] = itMessages["DOSSIERS"]["FIELDS"];
itMessages["WIZARD"]["FIELDS"]["FOLDER"] = itMessages["FOLDERS"]["FIELDS"];
itMessages["WIZARD"]["FIELDS"]["DOCUMENT"] = itMessages["DOCUMENTS"]["FIELDS"];

const allMessages = {
    en: flatten(enMessages),
    it: flatten(itMessages),
};

export function I18nProvider({children}) {
    const locale = useLang();
    const messages = allMessages[locale];

    dayjs.locale(locale);
    return (
        <IntlProvider locale={locale} messages={messages} defaultFormats={{date: {month: '2-digit', day: '2-digit'}}}>
            <YupSetLocale/>
            {children}
        </IntlProvider>
    );
}
