import {createSlice} from "@reduxjs/toolkit";
import {
    resetItemState,
    startCall,
    catchError,
    itemsFetched,
    itemFetched,
    itemCreated,
    itemUpdated,
    itemDeleted,
    initialState,
    saveDone,
    STATE_FETCHING,
    STATE_FETCHED,
    getError,
    STATE_ERROR,
    STATE_INITIAL,
} from "../../../../../_intesys/_helpers";


const initialRspSynchronizationStatusState = {
    state: STATE_INITIAL,
    error: null,
    data: undefined,
};


export const dossiersSlice = createSlice({
    name: "dossiers",
    initialState: {
        ...initialState,
        legalEntityRspSynchronizationStatuses: {},
        lastLegalEntityRspSynchronizationDate: undefined,
    },
    reducers: {
        resetItemState: resetItemState,
        saveDone: saveDone,
        catchError: catchError,
        startCall: startCall,
        itemsFetched: itemsFetched,
        itemFetched: itemFetched,
        itemCreated: itemCreated,
        itemUpdated: itemUpdated,
        itemDeleted: itemDeleted,
        getRspSynchronizationStatusStart: (state, action) => {
            const {key} = action.payload;
            if (state.legalEntityRspSynchronizationStatuses[key] === undefined) {
                state.legalEntityRspSynchronizationStatuses[key] = {...initialRspSynchronizationStatusState};
            }
            state.legalEntityRspSynchronizationStatuses[key].state = STATE_FETCHING;
        },
        getRspSynchronizationStatusDone: (state, action) => {
            const {key} = action.payload;

            state.legalEntityRspSynchronizationStatuses[key].state = STATE_FETCHED;
            state.legalEntityRspSynchronizationStatuses[key].data = action.payload.data;
            state.legalEntityRspSynchronizationStatuses[key].error = null;
        },
        getRspSynchronizationStatusError: (state, action) => {
            const error = getError(action);
            const {key} = action.payload;

            state.legalEntityRspSynchronizationStatuses[key].state = STATE_ERROR;
            state.legalEntityRspSynchronizationStatuses[key].data = undefined;
            state.legalEntityRspSynchronizationStatuses[key].error = error;
        },


        scheduleRspSynchronizationStart: (state, action) => {
            const {key} = action.payload;
            if (state.legalEntityRspSynchronizationStatuses[key] === undefined) {
                state.legalEntityRspSynchronizationStatuses[key] = {...initialRspSynchronizationStatusState};
            }
            state.legalEntityRspSynchronizationStatuses[key].state = STATE_FETCHING;
        },

        scheduleRspSynchronizationDone: (state, action) => {
            state.lastLegalEntityRspSynchronizationDate = Date.now();
            // const {key} = action.payload;
            // state.legalEntityRspSynchronizationStatuses[key].state = STATE_FETCHING;
            // state.legalEntityRspSynchronizationStatuses[key].data = undefined;
            // state.legalEntityRspSynchronizationStatuses[key].error = null;
        },

        scheduleRspSynchronizationError: (state, action) => {
            const error = getError(action);
            const {key} = action.payload;

            state.legalEntityRspSynchronizationStatuses[key].state = STATE_ERROR;
            state.legalEntityRspSynchronizationStatuses[key].data = undefined;
            state.legalEntityRspSynchronizationStatuses[key].error = error;
        },

    }

});
