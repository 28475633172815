import {createSlice} from "@reduxjs/toolkit";
import {catchError, initialState, itemCreated, itemDeleted, itemFetched, itemsFetched, itemUpdated, resetItemState, saveDone, startCall} from "../../../../../_intesys/_helpers";

export const licensesSlice = createSlice({
    name: "licenses",
    initialState: initialState,
    reducers: {
        resetItemState: resetItemState,
        saveDone: saveDone,
        catchError: catchError,
        startCall: startCall,
        itemsFetched: itemsFetched,
        itemFetched: itemFetched,
        itemCreated: itemCreated,
        itemUpdated: itemUpdated,
        itemDeleted: itemDeleted,
    }
});
