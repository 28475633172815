import React, {useEffect} from "react";
import {
    callTypes,
    notifyCreated,
    notifyCreateError,
    notifyCreating,
    notifyDeleted,
    notifyDeleteError,
    notifyDeleting,
    notifyUpdated,
    notifyUpdateError,
    notifyUpdating
} from "../../../../_intesys/_helpers";

export default function Notifier({
                                     object,
                                     isCreating = false,
                                     isCreated = false,
                                     isUpdating = false,
                                     isUpdated = false,
                                     isDeleting = false,
                                     isDeleted = false,
                                     isError = false,
                                     error = null,
                                 }) {

    useEffect(() => {
        if (isCreating) {
            notifyCreating(object);
        }
    }, [isCreating, object]);

    useEffect(() => {
        if (isCreated) {
            notifyCreated(object);
        }
    }, [isCreated, object]);

    useEffect(() => {
        if (isUpdating) {
            notifyUpdating(object);
        }
    }, [isUpdating, object]);

    useEffect(() => {
        if (isUpdated) {
            notifyUpdated(object);
        }
    }, [isUpdated, object]);

    useEffect(() => {
        if (isDeleting) {
            notifyDeleting(object);
        }
    }, [isDeleting, object]);

    useEffect(() => {
        if (isDeleted) {
            notifyDeleted(object);
        }
    }, [isDeleted, object]);

    useEffect(() => {
        if (isError && error) {
            if (error.callType === callTypes.create) {
                notifyCreateError(object);
            }
            if (error.callType === callTypes.update) {
                notifyUpdateError(object);
            }
            if (error.callType === callTypes.delete) {
                notifyDeleteError(object);
            }
        }
    }, [isError, error, object]);

    return (<></>);
}

