import {createSlice} from "@reduxjs/toolkit";
import {initialState, catchError, itemCreated, itemFetched, itemsFetched, itemUpdated, resetItemState, startCall, itemDeleted, saveDone} from "../../../../../_intesys/_helpers";

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        ...initialState,
        roles: [],
    },
    reducers: {
        resetItemState: resetItemState,
        saveDone: saveDone,
        catchError: catchError,
        startCall: startCall,
        itemsFetched: itemsFetched,
        itemFetched: itemFetched,
        itemCreated: itemCreated,
        itemUpdated: itemUpdated,
        itemDeleted: itemDeleted,
        rolesFetched: (state, action) => {
            const {roles} = action.payload;
            state.roles = roles;
        },
    }
});
