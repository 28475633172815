import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../../../_intesys/layout";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

export function Brand() {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            brandClasses: uiService.getClasses("brand", true),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            ),
            headerLogo: objectPath.get(uiService.config, "brand.self.theme") === "light" ? toAbsoluteUrl("/media/logos/BMW_eSign_grey.svg") : toAbsoluteUrl("/media/logos/BMW_eSign_white.svg"),
            headerStickyLogo: uiService.getStickyLogo()
        };
    }, [uiService]);

    return (
        <>
            {/* begin::Brand */}
            <div
                className={`brand flex-column-auto mt-3 ${layoutProps.brandClasses}`}
                id="kt_brand"
            >
                {/* begin::Logo */}
                <Link to="" className="brand-logo">
                    <img alt="logo" src={layoutProps.headerLogo}/>
                </Link>
                {/* end::Logo */}

                {layoutProps.asideSelfMinimizeToggle && (
                    <>
                        {/* begin::Toggle */}
                        <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
                            <span className="svg-icon svg-icon-xl">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                            </span>

                        </button>
                        {/* end::Toolbar */}
                    </>
                )}
            </div>
            {/* end::Brand */}
        </>
    );
}
