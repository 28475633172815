const WARNING_THRESHOLD = 60
const ALERT_THRESHOLD = 90

export const getThresholdStatus = (value) => {
    let type = 'ok';
    if (value > WARNING_THRESHOLD && value < ALERT_THRESHOLD) {
        type = 'warning';
    } else if (value >= ALERT_THRESHOLD) {
        type = 'alert';
    }

    return type
}

export const getLegendItems = (intl) => {
    return [
        {
            type: 'ok',
            label: intl.formatMessage({id: "DASHBOARD.LEGENDS.OK"}, {percent: WARNING_THRESHOLD})
        },
        {
            type: 'warning',
            label: intl.formatMessage({id: "DASHBOARD.LEGENDS.WARNING"}, {
                percent1: WARNING_THRESHOLD,
                percent2: ALERT_THRESHOLD
            })
        },
        {
            type: 'alert',
            label: intl.formatMessage({id: "DASHBOARD.LEGENDS.ALERT"}, {percent: ALERT_THRESHOLD})
        },
    ]
}


export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
