import * as requestFromServer from "./documentsCrud";
import {documentsSlice} from "./documentsSlice";
import {callTypes, convertDatetimeForApi, toBase64} from "../../../../../_intesys/_helpers";
import {pick} from "lodash";
import CPromise from "c-promise2";
import {statisticsSlice} from "../../../../pages/dashboard/_redux/statistics/statisticsSlice";

const {actions} = documentsSlice;
const {actions: statisticsActions} = statisticsSlice;

const fixDataForApp = (item) => {
    if (!item.sigType) {
        //conversion done (null to undefined) because otherwise select throw an error
        item.sigType = undefined;
    }
    return fixVariableFieldsForApp(item);
}

function fixVariableFieldsForApp(data) {
    let result = {...data};
    for (const documentKey in result.variableFields) {
        result["variableFields_" + documentKey] = result.variableFields[documentKey];
    }
    return result;
}

const fixDataForApi = (item) => {
    let result = {...item};
    let temp = {...item};
    if (temp.id) {
        result = pick(temp, "id", "archivable", "sigType", "signable", "variableFields_*");
        for (const documentKey in temp) {
            if (documentKey.startsWith("variableFields_")) {
                result[documentKey] = temp[documentKey];
            }
        }
    }
    //ensure correct values for booleans
    result.signable = !!result.signable;
    result.archivable = !!result.archivable;
    return fixVariableFields(result);
}

function fixVariableFields(data) {
    let document = {};
    let documentVariableFields = {};


    for (const documentKey in data) {

        if (documentKey.startsWith("variableFields_")) {

            documentVariableFields[documentKey.replace('variableFields_', '')] = data[documentKey];
        } else {
            document[documentKey] = data[documentKey];
        }

    }
    //document["variableFields"] = JSON.stringify(documentVariableFields);
    document["variableFields"] = documentVariableFields;
    return document;

}

let fetchDocumentsChain = [];

export const getDocumentsFromFolderId = folderId => dispatch => {
    const callType = callTypes.listByKey;
    const key = folderId;

    if (!folderId) {
        //console.error('getDocumentsFromFolderId missing folderId');
        return;
    }

    fetchDocumentsChain[key] && fetchDocumentsChain[key].cancel();

    dispatch(actions.startCall({callType, key}));

    fetchDocumentsChain[key] = new CPromise((resolve, reject, {onCancel}) => {
        requestFromServer
            .getDocumentsByFolderId(folderId)
            .then(resolve, reject);
    }).then(response => {
        const documents = response.data;
        const totalCount = documents.length;
        dispatch(actions.itemsByKeyFetched({
            totalCount: totalCount,
            entities: documents,
            key: key
        }));
    }).catch(error => {
        if (!CPromise.isCanceledError(error)) {
            dispatch(actions.catchError({error, callType, key}));
        }
    });
}


export const fetchDocument = id => dispatch => {
    const callType = callTypes.fetch;
    if (!id) {
        return dispatch(actions.itemFetched({item: undefined}));
    }

    dispatch(actions.startCall({callType}));
    return requestFromServer
        .getDocumentById(id)
        .then(response => {
            let document = response.data;

            const documentFetched = fixDataForApp(document);

            dispatch(actions.itemFetched({item: documentFetched}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};


export const createDocument = document => dispatch => {
    const callType = callTypes.create;

    dispatch(actions.startCall({callType}));
    const documentToCreate = fixDataForApi(document);


    return new Promise((resolve, reject) => {
        if (documentToCreate.file) {
            const file = documentToCreate.file[0];

            documentToCreate.fileName = file.name;
            documentToCreate.mimeType = file.type;
            documentToCreate.fileSize = file.size;

            delete documentToCreate.file;

            toBase64(file, function (fileContent) {
                documentToCreate.content = fileContent;
                resolve();
            });
        } else {
            resolve();
        }
    }).then(response => {

        return requestFromServer
            .createDocument(documentToCreate)
            .then(response => {
                const document = response.data;
                dispatch(actions.itemCreated({item: document}));

                //reload documents of the same folder
                dispatch(getDocumentsFromFolderId(document.folderId));

            })
            .catch(error => {
                dispatch(actions.catchError({error, callType}));
            });

    }).catch(error => {
        dispatch(actions.catchError({error, callType}));
    });
};

export const updateDocument = document => dispatch => {
    const callType = callTypes.update;
    dispatch(actions.startCall({callType}));

    const documentToUpdate = fixDataForApi(document);
    return requestFromServer
        .updateDocument(documentToUpdate)
        .then((response) => {
            const document = response.data;
            const documentUpdated = fixDataForApp(document);
            dispatch(actions.itemUpdated({item: documentUpdated}));
            dispatch(getDocumentsFromFolderId(document.folderId));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};


export const startSignDocument = documentId => dispatch => {
    const key = documentId;
    dispatch(actions.startStartSignCall({key}));
    return requestFromServer
        .documentSign(documentId)
        .then(response => {
            const document = response.data;
            const listByKeyKey = document.folderId;
            dispatch(actions.startSignDone({document, key, listByKeyKey}));
            dispatch(statisticsActions.startSignDone({document}));
            /*
            if (reloadDocument) {
                dispatch(fetchDocument(documentId));
            }
            if (reloadFolderDocuments) {
                dispatch(getDocumentsFromFolderId(folderId));
            }
            if (reloadStatistics) {
                dispatch(readDocumentsStatistics());
            }
            */
        })
        .catch(error => {
            dispatch(actions.startSignError({error, key}));
        });
}


export const downloadDocument = (documentId) => dispatch => {
    const key = documentId;
    dispatch(actions.downloadStart({key}));
    return requestFromServer
        .downloadDocument(documentId)
        .then(response => {
            const mimeType = response.headers["mimetype"];
            const file = new Blob(
                [response.data],
                {
                    //type: 'application/pdf'
                    type: mimeType
                }
            );
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            dispatch(actions.downloadDone({download: fileURL, key}));

            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(error => {
            dispatch(actions.downloadError({error, key}));
        });
}

export const getDocumentQrCode = (documentId) => dispatch => {
    const key = documentId;

    dispatch(actions.startQrCodeCall({key}));
    return requestFromServer
        .getDocumentQrCode(documentId)
        .then(response => {
            let qrCode = response.data;
            dispatch(actions.qrCodeFetched({qrCode, key}));
        })
        .catch(error => {
            dispatch(actions.qrCodeError({error, key}));
        });
}


export const deleteDocument = id => dispatch => {
    const callType = callTypes.delete;
    dispatch(actions.startCall({callType}));
    return requestFromServer
        .deleteDocument(id)
        .then(response => {
            dispatch(actions.itemDeleted({id}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const saveFromWizard = data => dispatch => {
    const callType = callTypes.create;

    dispatch(actions.wizardStart({callType}));

    let document = {};
    let documentVariableFields = {};


    for (const documentKey in data.document) {

        if (documentKey.startsWith("variableFields_")) {

            documentVariableFields[documentKey.replace('variableFields_', '')] = data.document[documentKey];
        } else {
            document[documentKey] = data.document[documentKey];
        }

    }
    //document["variableFields"] = JSON.stringify(documentVariableFields);
    document["variableFields"] = documentVariableFields;

    const wizardData = {
        folder: {...data.folder},
        document: document,
    };

    wizardData.document.signable = !!wizardData.document.signable;
    wizardData.document.archivable = !!wizardData.document.archivable;

    if (data.dossierId) {
        wizardData.dossierId = data.dossierId;
    } else {
        wizardData.dossier = {...data.dossier};
    }

    wizardData.folder.processDate = convertDatetimeForApi(wizardData.folder.processDate);

    return new Promise((resolve, reject) => {
        if (wizardData.document.file) {
            const file = wizardData.document.file[0];

            wizardData.document.fileName = file.name;
            wizardData.document.mimeType = file.type;
            wizardData.document.fileSize = file.size;

            delete wizardData.document.file;

            toBase64(file, function (fileContent) {
                wizardData.document.content = fileContent;
                resolve();
            });
        } else {
            resolve();
        }
    }).then(response => {

        return requestFromServer
            .wizard(wizardData)
            .then(response => {
                const wizardResult = response.data;
                dispatch(actions.wizardDone({wizardResult}));
            })
            .catch(error => {
                dispatch(actions.wizardError({error, callType}));
            });

    }).catch(error => {
        dispatch(actions.wizardError({error, callType}));
    });
}

export const createFeaDocument = document => dispatch => {
    dispatch(actions.createFeaDocument({}));
    const documentToCreate = {...document};
    return requestFromServer
        .createFeaDocument(documentToCreate)
        .then(response => {
            const feaDocument = response.data;
            dispatch(actions.createFeaDocumentDone({feaDocument}));
        })
        .catch(error => {
            dispatch(actions.createFeaDocumentError({error}));
        });
};

