import axios from "axios";


export const STATISTICS_URL = "/api/statistics";
const {REACT_APP_API_URL} = process.env;


export function getNumberOtpUsed(dealerLegalEntity) {
    let params = {};
    if (dealerLegalEntity) {
        params.legalEntity = dealerLegalEntity;
    }
    return axios.get(`${REACT_APP_API_URL}${STATISTICS_URL}/number-otp-used`, {params});
}

export function getDocumentsSize(dealerLegalEntity) {
    let params = {};
    if (dealerLegalEntity) {
        params.legalEntity = dealerLegalEntity;
    }
    return axios.get(`${REACT_APP_API_URL}${STATISTICS_URL}/documents-size`, {params});
}

export function getArchivedDocumentsSize(dealerLegalEntity) {
    let params = {};
    if (dealerLegalEntity) {
        params.legalEntity = dealerLegalEntity;
    }
    return axios.get(`${REACT_APP_API_URL}${STATISTICS_URL}/archived-documents-size`, {params});
}
