import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Alert} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {getErrorMessage} from "../../../../_intesys/_helpers";

function ErrorAlert({error, intl}) {
    if (!error) {
        return null;
    }
    return (
        <Alert variant={"danger"}>
            <div className={"m-t-2 m-b-2"}>
                <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
                    />
                </span>
                {` `} {getErrorMessage(error, intl)}
            </div>
        </Alert>
    );
}

export default injectIntl(ErrorAlert);