export const SIG_TYPES = [
    "OTP",
    "GRAPHOMETRIC",
];


export const SIGNABLE_TYPES = [
    // "OTHER_IDENTITY",
    // "OTHER_DRIVING_LICENSE",
    "PRIVACY_NSC",
    "PRIVACY_DEALER",
    "SALE_NEW_RFO",
    "SALE_NEW_PROPOSAL",
    "SALE_NEW_ACCEPTANCE",
    "T_B_D",
    "SERVICE_ODL",
    "SERVICE_VAB",
];
