import {createSlice} from "@reduxjs/toolkit";
import {STATE_ERROR, STATE_FETCHED, STATE_FETCHING, STATE_INITIAL} from "../../../../../_intesys/_helpers";

const initialState = {

    //rspCompanies list
    rspCompaniesState: STATE_INITIAL,
    rspCompaniesEntities: [],
    rspCompaniesError: null,
    rspCompaniesTotalCount: null,

    //rspCustomers list
    rspCustomersState: STATE_INITIAL,
    rspCustomersEntities: [],
    rspCustomersError: null,
    rspCustomersTotalCount: null,


    //dmsCustomers list
    dmsCustomersState: STATE_INITIAL,
    dmsCustomersEntities: [],
    dmsCustomersError: null,
    dmsCustomersTotalCount: null,

    //dmsCustomer single
    dmsCustomerState: STATE_INITIAL,
    dmsCustomerEntity: undefined,
    dmsCustomerError: null,
    dmsCustomerLastId: undefined,

    // dmsCustomer: undefined,
    // dmsCustomerLoading: false,
    // error: null,
};
export const callTypes = {
    dmsList: "dmsList",
    dmsSingle: "dmsSingle",
    rspCompanies: "rspCompanies",
    rspCustomers: "rspCustomers"
};

export const customersSlice = createSlice({
    name: "customers",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            const error = {
                type: action.type,
                error: action.payload.error
            };

            switch (action.payload.callType) {
                case callTypes.rspCompanies:
                    state.rspCompaniesState = STATE_ERROR;
                    state.rspCompaniesError = error;
                    // state.rspCompaniesEntities = [];
                    // state.rspCompaniesTotalCount = null;
                    break;
                case callTypes.rspCustomers:
                    state.rspCustomersState = STATE_ERROR;
                    state.rspCustomersError = error;
                    break;
                case callTypes.dmsList:
                    state.dmsCustomersState = STATE_ERROR;
                    state.dmsCustomersError = error;
                    break;
                case callTypes.dmsSingle:
                    state.dmsCustomerState = STATE_ERROR;
                    state.dmsCustomerError = error;
                    break;
                default:
                    break;
            }
        },

        startCall: (state, action) => {
            //when starting calling api full reset of errors to make theme not show in fe
            switch (action.payload.callType) {
                case callTypes.rspCompanies:
                    state.rspCompaniesState = STATE_FETCHING;
                    state.rspCompaniesError = null;
                    // state.rspCompaniesEntities = [];
                    // state.rspCompaniesTotalCount = 0;
                    break;
                case callTypes.rspCustomers:
                    state.rspCustomersState = STATE_FETCHING;
                    state.rspCustomersError = null;
                    // state.rspCustomersEntities = [];
                    // state.rspCustomersTotalCount = 0;
                    break;
                case callTypes.dmsList:
                    state.dmsCustomersState = STATE_FETCHING;
                    state.dmsCustomersError = null;
                    // state.dmsCustomersEntities = [];
                    // state.dmsCustomersTotalCount = 0;
                    break;
                case callTypes.dmsSingle:
                    state.dmsCustomerState = STATE_FETCHING;
                    state.dmsCustomerError = null;
                    state.dmsCustomerEntity = undefined;
                    state.dmsCustomerLastId = undefined;
                    break;

                default:
                    break;
            }
        },
        rspCompaniesFetched: (state, action) => {
            const {rspCompanies} = action.payload;

            state.rspCompaniesState = STATE_FETCHED;
            state.rspCompaniesError = null;
            state.rspCompaniesEntities = rspCompanies;
            state.rspCompaniesTotalCount = rspCompanies.length;

        },
        rspCustomersFetched: (state, action) => {
            const {rspCustomers} = action.payload;

            state.rspCustomersState = STATE_FETCHED;
            state.rspCustomersError = null;
            state.rspCustomersEntities = rspCustomers;
            state.rspCustomersTotalCount = rspCustomers.length;
        },


        dmsCustomersFetched: (state, action) => {
            const {dmsCustomers} = action.payload;

            state.dmsCustomersState = STATE_FETCHED;
            state.dmsCustomersError = null;
            state.dmsCustomersEntities = dmsCustomers;
            state.dmsCustomersTotalCount = dmsCustomers.length;

        },
        dmsCustomerFetched: (state, action) => {
            const {dmsCustomer} = action.payload;

            state.dmsCustomerState = STATE_FETCHED;
            state.dmsCustomersError = null;
            state.dmsCustomerEntity = dmsCustomer;
            state.dmsCustomerLastId = undefined;
        },

    }
});
