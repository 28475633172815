import {createSlice} from "@reduxjs/toolkit";

import {
    initialState,
    resetItemState,
    catchError,
    itemsFetched,
    itemFetched,
    itemCreated,
    itemUpdated,
    itemDeleted,
    itemsByKeyFetched,
    startCall,
    saveDone,
    STATE_INITIAL,
    STATE_FETCHED,
    STATE_ERROR,
    getError,
    STATE_FETCHING,
    STATE_CREATING,
    STATE_CREATED,
    downloadStart,
    downloadDone,
    downloadError
} from "../../../../../_intesys/_helpers";

export const callTypes = {
    startSign: "startSign",
};

const initialStartSignState = {
    state: STATE_INITIAL,
    error: null,
};

const initialQrCodeState = {
    state: STATE_INITIAL,
    qrCode: undefined,
    error: null,
};


export const documentsSlice = createSlice({
    name: "documents",
    initialState: {
        ...initialState,

        startSignState: STATE_INITIAL,
        startSignError: null,

        startSigns: {},

        // qrCode: undefined,
        // qrCodeState: STATE_INITIAL,
        // qrCodeError: null,
        qrCodes: {},


        wizardResult: undefined,
        wizardState: STATE_INITIAL,
        wizardError: null,


        feaDocumentState: STATE_INITIAL,
        feaDocumentForEdit: undefined,
        feaDocumentError: null,


    },
    reducers: {
        resetItemState: resetItemState,
        saveDone: saveDone,
        catchError: catchError,
        startCall: startCall,
        itemsFetched: itemsFetched,
        itemsByKeyFetched: itemsByKeyFetched,
        itemFetched: itemFetched,
        itemCreated: itemCreated,
        itemUpdated: itemUpdated,
        itemDeleted: itemDeleted,

        downloadStart: downloadStart,
        downloadDone: downloadDone,
        downloadError: downloadError,

        startStartSignCall: (state, action) => {
            // state.startSignState = STATE_FETCHING;
            // state.startSignError = null;
            const {key} = action.payload;
            if (state.startSigns[key] === undefined) {
                state.startSigns[key] = {...initialStartSignState};
            }
            state.startSigns[key].state = STATE_FETCHING;
        },
        startSignDone: (state, action) => {
            const {document, key, listByKeyKey} = action.payload;
            const documentId = document.id;
            // state.startSignState = STATE_FETCHED;
            // state.startSignError = null;

            // const {key} = action.payload;

            state.startSigns[key].state = STATE_FETCHED;
            // state.startSigns[key].qrCode = action.payload.qrCode;
            state.startSigns[key].error = null;


            //update list without refreshing lists
            //if id of document is the same in single -> update
            state.itemForEdit = document;

            //if found in list -> update
            state.listEntities = state.listEntities.map(entity => {
                if (entity.id === documentId) {
                    return {...entity, ...document};
                }
                return entity;
            });

            //if found in list by key -> update
            if (listByKeyKey && state.listByKey !== undefined && state.listByKey[listByKeyKey] !== undefined) {
                state.listByKey[listByKeyKey].listEntities = state.listByKey[listByKeyKey].listEntities.map(entity => {
                    if (entity.id === document.id) {
                        return {...entity, ...document};
                    }
                    return entity;
                });
            }


        },
        startSignError: (state, action) => {
            const {key} = action.payload;
            const error = getError(action);

            state.startSigns[key].state = STATE_ERROR;
            state.startSigns[key].error = error;
            // state.startSignState = STATE_ERROR;
            // state.startSignError = error;
        },


        startQrCodeCall: (state, action) => {
            const {key} = action.payload;
            if (state.qrCodes[key] === undefined) {
                state.qrCodes[key] = {...initialQrCodeState};
            }
            state.qrCodes[key].state = STATE_FETCHING;
        },
        qrCodeFetched: (state, action) => {
            const {key} = action.payload;

            state.qrCodes[key].state = STATE_FETCHED;
            state.qrCodes[key].qrCode = action.payload.qrCode;
            state.qrCodes[key].error = null;
        },
        qrCodeError: (state, action) => {
            const {key} = action.payload;
            const error = getError(action);

            state.qrCodes[key].state = STATE_ERROR;
            state.qrCodes[key].qrCode = undefined;
            state.qrCodes[key].error = error;
        },


        wizardStart: (state, action) => {
            state.wizardState = STATE_CREATING;
            state.wizardResult = undefined;
            state.wizardError = null;
        },
        wizardDone: (state, action) => {
            state.wizardState = STATE_CREATED;
            state.wizardResult = action.payload.wizardResult;
            state.wizardError = null;
        },
        wizardError: (state, action) => {
            const error = getError(action);
            state.wizardState = STATE_ERROR;
            state.wizardResult = undefined;
            state.wizardError = error;
        },
        resetWizardState: (state, action) => {
            state.wizardState = STATE_INITIAL;
            state.wizardResult = undefined;
            state.wizardError = null;
        },
        createFeaDocument: (state, action) => {
            state.feaDocumentState = STATE_CREATING;
            state.feaDocumentForEdit = undefined;
            state.feaDocumentError = null;
        },
        createFeaDocumentDone: (state, action) => {
            state.feaDocumentState = STATE_CREATED;
            state.feaDocumentForEdit = action.payload.feaDocument;
            ;
            state.feaDocumentError = null;
        },
        createFeaDocumentError: (state, action) => {
            const error = getError(action);
            state.feaDocumentState = STATE_ERROR;
            state.feaDocumentForEdit = undefined;
            state.feaDocumentError = error;
        },


    }
});
