import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import {getUserByToken} from "./authCrud";
import {fixUserDataForApp} from "../../Admin/_redux/users/usersActions";

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserLogged: "[User Logged] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
    user: undefined,
    authToken: undefined
};

export const reducer = persistReducer(
    {storage, key: "fdd-auth-v1", whitelist: ["user", "authToken"]},
    (state = initialAuthState, action) => {
        switch (action.type) {

            case actionTypes.Login: {
                return {authToken: action.payload.authToken, user: undefined};
            }

            case actionTypes.UserLogged: {
                return {authToken: action.payload.authToken, user: undefined};
            }

            case actionTypes.Register: {
                return {authToken: action.payload.authToken, user: undefined};
            }

            case actionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                return {...state, user: action.payload.user};
            }


            default:
                return state;
        }
    }
);

export const actions = {
    login: authToken => ({type: actionTypes.Login, payload: {authToken}}),
    register: authToken => ({
        type: actionTypes.Register,
        payload: {authToken}
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: user => ({type: actionTypes.UserRequested, payload: {user}}),
    fulfillUser: user => ({type: actionTypes.UserLoaded, payload: {user}}),
    userLogged: authToken => ({type: actionTypes.UserLogged, payload: {authToken}}),
};

export function* saga() {
    yield takeLatest(actionTypes.UserLogged, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data} = yield getUserByToken();

        const user = fixUserDataForApp(data);

        yield put(actions.fulfillUser(user));
    });
}
