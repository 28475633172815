import axios from "axios";
import {BRANDS} from "../../../../../_intesys/_helpers";

const {REACT_APP_BMW_TOOLS_HOST, REACT_APP_BMW_TOOLS_TEST} = process.env;

const fields = [
    'name',
    'hst2011',
    'legal_entity',
    'nome_commerciale',
    'ragione_sociale',
    'indirizzo',
    'citta',
    'provincia',
    'tipo_auto',
    'tipo_bmwi',
    'tipo_moto',
    'tipo_mini',
];

export function getAllDealers() {
    const brand = BRANDS.map(brand => brand.value).join(',');
    return axios.post(`${REACT_APP_BMW_TOOLS_HOST}/conc/list`, {
        brand: brand,
        fields: fields.join(','),
        test: REACT_APP_BMW_TOOLS_TEST,
    });
}
