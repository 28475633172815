export function getDocumentTypesFromFolder(folderType, folderTypesExt) {
    if (folderType && folderTypesExt) {
        const filteredFolderTypeExt = folderTypesExt.find(folderTypeExt => folderTypeExt.slug === folderType);
        return filteredFolderTypeExt.documentTypes;
    }
    return [];
}

export function getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt) {
    if (documentType && folderType && folderTypesExt) {
        const documentTypes = getDocumentTypesFromFolder(folderType, folderTypesExt);
        return documentTypes.find(documentTypeExt => documentType.toUpperCase() === documentTypeExt.slug.toUpperCase());
    }
    return false;
}

export function getFieldsFromDocumentType(documentType, folderType, folderTypesExt) {
    if (documentType && folderType && folderTypesExt) {
        const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt);
        return documentTypeExt.fields.map((documentTypeField) => documentTypeField.field);
    }
    return [];
}

export function getDocumentTypesFilteredByFolderType(folderType, folderTypesExt, excludeWizard = true) {
    if (folderType && folderTypesExt) {
        const documentTypes = getDocumentTypesFromFolder(folderType, folderTypesExt);
        return documentTypes
            .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0))
            .filter((documentTypeExt) => excludeWizard ? documentTypeExt.visibleInWizard : true)
            .map((documentTypeExt) => documentTypeExt.slug);
    }
    return [];
}

export function getDocumentTypeField(documentType, folderType, folderTypesExt, field) {
    if (documentType && folderType && folderTypesExt) {
        const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt);
        return documentTypeExt.fields.find((documentTypeField) => documentTypeField.field === field);
    }
    return null;
}

export function documentTypeHasField(documentType, folderType, folderTypesExt, field) {
    return getFieldsFromDocumentType(documentType, folderType, folderTypesExt).includes(field);
}

export function documentTypeHasFieldRequired(documentType, folderType, folderTypesExt, field) {
    if (documentType && folderType && folderTypesExt) {
        const documentTypeField = getDocumentTypeField(documentType, folderType, folderTypesExt, field);
        if (documentTypeField) {
            return documentTypeField.required;
        }
    }
    return false;
}

const defaultFields = [
    'ARCHIVABLE',
    'SIGNABLE',
    'SIG_TYPE',
    'FILE',
];

export function documentTypeExtraFields(documentType, folderType, folderTypesExt) {
    if (documentType && folderType && folderTypesExt) {
        const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt);
        if (documentTypeExt && documentTypeExt.fields){
            return documentTypeExt.fields.filter((field) => !defaultFields.includes(field.field))
        }
    }
    return [];
}

export function getAllExtraFields(folderTypesExt) {
    let extraFields = {};
    if (folderTypesExt) {
        folderTypesExt.forEach((folderType) => {
            folderType.documentTypes.forEach((documentType) => {
                documentType.fields.filter((field) => !defaultFields.includes(field.field)).forEach((field) => {


                    const fieldName = field.field;
                    //const fieldLabel = field.label;
                    const fieldType = field.type;
                    const inputName = `variableFields.${fieldName}`;

                    extraFields[fieldName] = {
                        'name': fieldName,
                        'type': fieldType,
                        'inputName': inputName
                    }
                });

            })
        });
    }
    return extraFields;
}
