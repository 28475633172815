import React from "react";
import {useField} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {FieldTooltip} from "./FieldTooltip";

const getFieldCSSClasses = (touched, errors, withValidationClasses) => {
    const classes = ["form-control", "form-control-solid"];
    if (withValidationClasses) {
        if (touched && errors) {
            classes.push(" is-invalid-select");
        }

        if (touched && !errors) {
            classes.push("is-valid-select");
        }
    }

    return classes.join(" ");
};

export function Select({
                           label,
                           initialFeedBackLabel,
                           withFeedbackLabel = true,
                           withValidationClasses = true,
                           type = "select",
                           customFeedbackLabel,
                           children,
                           helpText = "",
                           ...props
                       }) {
    const [field, meta] = useField(props);
    const {touched, error} = meta;
    return (
        <>
            {label && <label>{label}<FieldTooltip text={helpText}/></label>}
            <select
                className={getFieldCSSClasses(touched, error, withValidationClasses)}
                {...field}
                {...props}
            >
                {children}
            </select>
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={error}
                    touched={touched}
                    label={label}
                    initialFeedBackLabel={initialFeedBackLabel}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
