import {userIsAdmin, userCanSeeLicenses} from "./PermissionsHelpers";

export function userCanShowLicense(user, license) {
    if (license && user) {
        return userCanSeeLicenses(user);
    }
    return false;
}

export function userCanCreateLicense(user) {
    if (user) {
        return userIsAdmin(user);
    }
    return false;
}

export function userCanUpdateLicense(user, license) {
    if (license && user) {
        return (license.activationsCount <= 0) && userIsAdmin(user);
    }
    return false;
}


export function userCanDeleteLicense(user, license) {
    if (license && user) {
        return (license.activationsCount <= 0) && userIsAdmin(user);
    }
    return false;
}