import {userIsAdmin} from "./PermissionsHelpers";

export function userCanCreateUser(user) {
    if (user) {
        return userIsAdmin(user);
    }
    return false;
}

export function userCanCreateService(user) {
    if (user) {
        return userIsAdmin(user);
    }
    return false;
}

export function userCanUpdateUser(user, userToEdit) {
    //TODO: maybe disable edit of admin users
    if (user) {
        return userIsAdmin(user);
    }
    return false;
}

export function userCanUpdateService(user, userToEdit) {
    //TODO: maybe disable edit of admin users
    if (user) {
        return userIsAdmin(user);
    }
    return false;
}