import {FormattedMessage} from "react-intl";
import React from "react";
import {Spinner} from "react-bootstrap";

export default function LoadingMessage({isLoading, text, className}) {
    return (
        isLoading ?
            <div className={className}>
                <Spinner animation="border" role="status"/>

                {' '}
                <span>
                    {text ? text : <FormattedMessage id={'GENERIC.LOADING'}/>}
                </span>
            </div>
            : null
    );
}

