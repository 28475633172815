import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircleNotch, faExclamation, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

export function FontAwesomeButton({
                                      id = null,
                                      icon = faExclamation,
                                      onClick,
                                      type = 'primary',
                                      linkClasses = "",
                                      href = null,
                                      target = null,
                                      loading = false,
                                      spin = false,
                                      error = false,
                                      done = false,
                                      text
                                  }) {

    let aClasses = [
        "btn",
        "btn-font-awesome",
    ];
    aClasses.push(`btn-${type}`);
    if (linkClasses) {
        aClasses.push(linkClasses);
    }
    let svgClasses = [
        "svg-icon",
        "svg-icon-md",
    ];

    if (loading) {
        icon = faCircleNotch;
        spin = true;
    }
    return (
        <a
            className={aClasses.join(" ")}
            href={href}
            target={target}
            onClick={onClick}
        >
            <span className={svgClasses.join(" ")}>
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={icon} spin={spin}/>
                    {done && <FontAwesomeIcon className={"ok"} icon={faCheckCircle} inverse transform="shrink-8 up-8 right-6"/>}
                    {error && <FontAwesomeIcon className={"ko"} icon={faExclamationCircle} inverse transform="shrink-8 up-8 right-6"/>}
                </span>
            </span>
            {text}
        </a>
    );
}