import React, {forwardRef, useEffect, useState} from "react";
import clsx from "clsx";
import {isFragment} from "react-is";
import {CardHeaderTitle} from "../../../_intesys/_partials/controls";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";


export const CardHeader = forwardRef(
    (
        {
            children,
            icon,
            title,
            toolbar,
            className,
            titleClassName,
            sticky = false,
            labelRef,
            ...props
        },
        ref
    ) => {
        const [top, setTop] = useState(0);
        const [windowHeight, setWindowHeight] = useState(0);

        useEffect(() => {
            handleResize();

            function handleResize() {
                setWindowHeight(window.innerWidth);
            }

            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        });

        useEffect(() => {
            // Skip if sticky is disabled or on initial render when we don't know about window height.
            if (!sticky || windowHeight === 0) {
                return;
            }

            const headerElement = document.querySelector(".header");
            const subheaderElement = document.querySelector(".subheader");
            const headerMobileElement = document.querySelector(".header-mobile");

            let nextMarginTop = 0;

            // mobile header
            if (window.getComputedStyle(headerElement).height === "0px") {
                nextMarginTop = headerMobileElement.offsetHeight;
            } else {
                // desktop header
                if (document.body.classList.contains("header-minimize-topbar")) {
                    // hardcoded minimized header height
                    nextMarginTop = 60;
                } else {
                    // normal fixed header
                    if (document.body.classList.contains("header-fixed")) {
                        nextMarginTop += headerElement.offsetHeight;
                    }

                    if (document.body.classList.contains("subheader-fixed")) {
                        nextMarginTop += subheaderElement.offsetHeight;
                    }
                }
            }

            setTop(nextMarginTop);
        }, [sticky, windowHeight]);

        return (
            <div
                ref={ref}
                className={clsx("card-header", className)}
                style={
                    !sticky
                        ? undefined
                        : {top, position: "sticky", backgroundColor: "#fff"}
                }
            >
                {title != null && (
                    <div ref={labelRef} className={clsx("card-title", titleClassName)}>
                        {icon &&
                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl(icon)}
                            />
                        </span>} &nbsp;

                        {/* Wrap string and fragments in CardHeaderTitle */
                            typeof title === "string" || isFragment(title) ? (
                                <CardHeaderTitle>{title}</CardHeaderTitle>
                            ) : (
                                title
                            )}
                    </div>
                )}

                {toolbar}
                {children}
            </div>
        );
    }
);

export const CardFooterToolbar = forwardRef(
    ({children, className, ...props}, ref) => (
        <div {...props} ref={ref} className={clsx("card-toolbar", className)}>
            {children}
        </div>
    )
);

