import {BRANDS_VALUES} from "./BrandsHelpers";
import {DEFAULT_SEARCH_TYPE, DMS_SEARCH_TYPE, getUniqueDealersListForUser, RSP_SEARCH_TYPE} from "./DealersHelpers";


//     "ROLE_ADMIN",
//     "ROLE_USER",
//     "ROLE_BMW_ITALIA",
//     "ROLE_SUPERVISOR_DEALER",
//     "ROLE_DEALER",
//     "ROLE_SERVICE_DEALER",
//     "ROLE_SALE_DEALER",
//     "ROLE_ACCEPTANCE_DEALER",
//     "ROLE_BACKOFFICE_DEALER",
//     "ROLE_DMS_INCADEA",
//     "ROLE_DMS_AUTOLINE",
//     "ROLE_DMS_SIPADX"

export const ROLES_WITH_ADMIN_PERMISSIONS = [
    "ROLE_ADMIN"
];

export const ROLES_WITH_SINGLE_SELECTION = [
    "ROLE_ADMIN",
    "ROLE_USER",
    "ROLE_BMW_ITALIA",
    "ROLE_SUPERVISOR_DEALER",
    "ROLE_DEALER"
];

export const ROLES_WITH_HST = [
    "ROLE_SUPERVISOR_DEALER",
    "ROLE_DEALER",
    "ROLE_SERVICE_DEALER",
    "ROLE_SALE_DEALER",
    "ROLE_ACCEPTANCE_DEALER",
    "ROLE_BACKOFFICE_DEALER"
];

export const ROLES_WITH_ALL_DEALERS_VISIBILITY = [
    "ROLE_ADMIN",
    "ROLE_BMW_ITALIA",
];

export const ROLES_WITH_USERS_VISIBILITY = [
    "ROLE_ADMIN",
    "ROLE_BMW_ITALIA",
    "ROLE_SUPERVISOR_DEALER",
];

export const ROLES_WITH_ALL_BRANDS = [
    "ROLE_ADMIN",
    "ROLE_BMW_ITALIA",
];

export const ROLES_WITH_BRAND_PERMISSIONS = [
    "ROLE_SUPERVISOR_DEALER",
    "ROLE_DEALER",
    "ROLE_SERVICE_DEALER",
    "ROLE_SALE_DEALER",
    "ROLE_ACCEPTANCE_DEALER",
    "ROLE_BACKOFFICE_DEALER"
];

export const ROLES_WITH_DEFAULT_SEARCH_TYPE_DMS = [
    "ROLE_ACCEPTANCE_DEALER",
    "ROLE_SERVICE_DEALER",
];
export const ROLES_WITH_DEFAULT_SEARCH_TYPE_RSP = [
    "ROLE_DEALER",
    "ROLE_SALE_DEALER",
    "ROLE_BACKOFFICE_DEALER",
];

export const ROLES_WITH_FILTER_ON_FOLDERS = [
    "ROLE_SALE_DEALER",
];

export const ROLES_WITH_LICENSES_VISIBILITY = [
    "ROLE_ADMIN",
    "ROLE_USER",
    "ROLE_SUPERVISOR_DEALER",
    "ROLE_DEALER",
    "ROLE_SERVICE_DEALER",
    "ROLE_SALE_DEALER",
    "ROLE_ACCEPTANCE_DEALER",
    "ROLE_BACKOFFICE_DEALER",
];


export const READONLY_PERMISSION_ON_BRAND = "READONLY";


export function getPermissions(folderTypes) {
    return [READONLY_PERMISSION_ON_BRAND].concat(folderTypes);
}

export function roleHasHst(role) {
    if (role) {
        return ROLES_WITH_HST.includes(role);
    }
    return false;
}


// AUTHORITIES

export function authoritiesHaveOneOfRole(authorities, roles) {
    let result = false;
    if (roles) {
        for (let i = 0; i < roles.length; i++) {
            const roleToCheck = roles[i];
            if (authorities.includes(roleToCheck)) {
                result = true;
                break;
            }
        }
    }
    return result;
}

export function authoritiesHaveHst(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_HST);
}

export function authoritiesCanSeeAllDealers(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_ALL_DEALERS_VISIBILITY);
}

export function authoritiesCanSeeLicenses(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_LICENSES_VISIBILITY);
}

export function authoritiesCanSeeUsers(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_USERS_VISIBILITY);
}

export function authoritiesHaveBrandsPermissions(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_BRAND_PERMISSIONS);
}

export function authoritiesHaveAllBrandsPermissions(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_ALL_BRANDS);
}

export function authoritiesHaveSingleSelection(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_SINGLE_SELECTION);
}

export function authoritiesHaveDefaultSearchTypeDms(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_DEFAULT_SEARCH_TYPE_DMS);
}

export function authoritiesHaveDefaultSearchTypeRsp(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_DEFAULT_SEARCH_TYPE_RSP);
}

export function getAuthoritiesDefaultSearchType(authorities) {
    let defaultSearchType = DEFAULT_SEARCH_TYPE;
    if (authoritiesHaveDefaultSearchTypeDms(authorities)) {
        defaultSearchType = DMS_SEARCH_TYPE;
    }
    if (authoritiesHaveDefaultSearchTypeRsp(authorities)) {
        defaultSearchType = RSP_SEARCH_TYPE;
    }
    return defaultSearchType;
}

export function authoritiesHaveFilterOnFoldersRole(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_FILTER_ON_FOLDERS);
}

export function authoritiesHaveOneOfAdminPermissionRole(authorities) {
    return authoritiesHaveOneOfRole(authorities, ROLES_WITH_ADMIN_PERMISSIONS);
}

// USERS
export function getUserDefaultSearchType(user) {
    return getAuthoritiesDefaultSearchType(user.authorities);
}

export function getUserHasFilterOnFolders(user) {
    return authoritiesHaveFilterOnFoldersRole(user.authorities);
}

export function userIsAdmin(user) {
    if (user) {
        return authoritiesHaveOneOfAdminPermissionRole(user.authorities);
    }
    return false;
}

export function userCanSeeAllDealers(user) {
    if (user) {
        return authoritiesCanSeeAllDealers(user.authorities);
    }
    return false;
}

export function userCanSeeLicenses(user) {
    if (user) {
        return authoritiesCanSeeLicenses(user.authorities);
    }
    return false;
}

export function userCanSeeUsers(user) {
    if (user) {
        return authoritiesCanSeeUsers(user.authorities);
    }
    return false;
}

export function userCanChangeLegalEntity(user, dealers) {
    return getUniqueDealersListForUser(user, dealers).length > 1;
}

export function userHasHst(user) {
    if (user) {
        return authoritiesHaveHst(user.authorities);
    }
    return false;
}

export function userCanManageAllBrands(user) {
    if (user) {
        return authoritiesHaveAllBrandsPermissions(user.authorities);
    }
    return false;
}

export function userHasReadPermissionOverBrand(user, brand) {
    if (user["brand_" + brand]) {
        const permissions = user["brand_" + brand];
        //if user has at least one permission has read permissions on brand
        return permissions.length > 0;
    }
    return false;
}

export function userHasWritePermissionOverBrand(user, brand) {
    if (user) {
        if (userCanManageAllBrands(user)) {
            return true;
        } else {
            if (brand) {
                let result = false;
                if (user["brand_" + brand]) {
                    const permissions = user["brand_" + brand];
                    if (permissions.length >= 1) {
                        result = !permissions.includes(READONLY_PERMISSION_ON_BRAND);
                    }
                }
                return result;
            }
        }

    }
    return false;
}

// const folderTypesOrder = [
//     'PRIVACY',
//     'SALE_NEW',
//     'SERVICE',
//     'SALE_USED',
// ];

export function getUserFoldersWithWritePermission(user, brand, folderTypesExt, excludeWizard = true) {
    if (user && brand && folderTypesExt && user["brand_" + brand]) {
        return folderTypesExt
            .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0))
            .filter((folderTypeExt) => excludeWizard ? folderTypeExt.visibleInWizard : true)
            .filter((folderTypeExt) => userCanManageAllBrands(user) ? true : user["brand_" + brand].includes(folderTypeExt.slug))
            .map((folderTypeExt) => folderTypeExt.slug);
    }
    return [];
}

export function userHasWritePermissionOverBrandAndFolderType(user, brand, folderType) {
    if (folderType === 'VEHICLE_EXIT_REQUEST') {
        return false
    }
    if (userCanManageAllBrands(user)) {
        return true;
    } else {
        if (user["brand_" + brand]) {
            return user["brand_" + brand].includes(folderType);
        } else {
            return false;
        }
    }
}


export function userHasOnlyReadOnlyPermissions(user) {
    if (userCanManageAllBrands(user)) {
        return false;
    } else {
        let result = true;
        for (let i = 0; i < BRANDS_VALUES.length; i++) {
            const brand = BRANDS_VALUES[i];
            result = result && user["brand_" + brand].includes(READONLY_PERMISSION_ON_BRAND);
        }
        return result;
    }
}


export function dossierRequireFea(dossierSignatureType) {
    let result = false;
    if (dossierSignatureType) {
        result = dossierSignatureType.signatureType === 'FEA';
    }
    return result;
}

export function documentRequireFea(document, dossierSignatureType) {
    let result = false;
    if (document && document.signable) {
        if (dossierSignatureType) {
            result = dossierSignatureType.signatureType === 'FEA';
            if (dossierSignatureType.documentStatus !== 'EXPIRED') {
                if (dossierSignatureType.feaModuleId && dossierSignatureType.feaModuleId === document.id) {
                    result = false;
                }
            }
        }
    }
    return result;
}

export function dossierHasFeaDocument(dossierSignatureType) {

    let result = false;
    if (dossierSignatureType) {
        result = (dossierSignatureType.feaModuleId !== null && dossierSignatureType.documentStatus !== 'EXPIRED');
    }
    return result;
}


export function dossierFeaIsSigned(dossierSignatureType) {

    let result = false;
    if (dossierSignatureType) {
        result = dossierSignatureType.documentStatus === 'SIGNED';
    }
    return result;
}


export function dossierIsCompleteForFea(dossier) {
    let result = true;
    if (dossier) {
        if (!dossier.fiscalCode) {
            result = false;
        }
        if (!dossier.birthDate) {
            result = false;
        }
        if (!dossier.birthPlace) {
            result = false;
        }
    }
    return result;
}
