import React from "react";
import {FormattedMessage} from "react-intl";

const inputLabel = ({label, initialFeedBackLabel, touched, error, customFeedbackLabel}) => {
    if (touched && error) {
        return <div className="invalid-feedback">{error}</div>;
    }

    if (touched && !error && label) {
        return <div className="valid-feedback">
            <FormattedMessage id={'GENERIC.FEEDBACK.OK'} values={{label: label}}/>
        </div>;
    }

    return (
        <div className="feedback">
            {customFeedbackLabel && <>{customFeedbackLabel}</>}
            {!customFeedbackLabel && (
                <>
                    {initialFeedBackLabel}
                </>
            )}
        </div>
    );
};

const selectLabel = ({label, initialFeedBackLabel, touched, error, customFeedbackLabel}) => {
    if (touched && error) {
        return <div className="invalid-feedback d-block">{error}</div>;
    }

    if (touched && !error && label) {
        return <div className="valid-feedback d-block">
            <FormattedMessage id={'GENERIC.FEEDBACK.OK'} values={{label: label}}/>
        </div>;
    }

    return (
        <div className="feedback">
            {customFeedbackLabel && <>{customFeedbackLabel}</>}
            {!customFeedbackLabel && label && (
                <>
                    {initialFeedBackLabel}
                </>
            )}
        </div>
    );
};

export function FieldFeedbackLabel({
                                       label,
                                       initialFeedBackLabel,
                                       touched,
                                       error,
                                       type,
                                       customFeedbackLabel
                                   }) {
    switch (type) {
        case "text":
            return inputLabel({label, initialFeedBackLabel, touched, error, customFeedbackLabel});
        case "email":
            return inputLabel({label, initialFeedBackLabel, touched, error, customFeedbackLabel});
        case "password":
            return inputLabel({label, initialFeedBackLabel, touched, error, customFeedbackLabel});
        default:
            return selectLabel({label, initialFeedBackLabel, touched, error, customFeedbackLabel});
    }
}
