import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircleNotch, faExclamation, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

export function FontAwesomeIconButton({
                                          id = null,
                                          message,
                                          icon = faExclamation,
                                          onClick,
                                          type = 'primary',
                                          linkClasses = "",
                                          href = null,
                                          target = null,
                                          loading = false,
                                          spin = false,
                                          error = false,
                                          done = false,
                                      }) {

    let aClasses = [
        "btn",
        "btn-icon",
        "btn-light",
        "btn-sm",
        "btn-font-awesome-icon",
    ];
    if (linkClasses) {
        aClasses.push(linkClasses);
    }
    aClasses.push(`btn-hover-${type}`);
    let svgClasses = [
        "svg-icon",
        "svg-icon-md",
    ];
    svgClasses.push(`svg-logo-${type}`);

    if (loading) {
        icon = faCircleNotch;
        spin = true;
    }
    return (
        <OverlayTrigger
            overlay={
                <Tooltip id={id}>
                    {message}
                </Tooltip>
            }
        >
            <a
                className={aClasses.join(" ")}
                href={href}
                target={target}
                onClick={onClick}
            >
                <span className={svgClasses.join(" ")}>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={icon} spin={spin}/>
                        {done && <FontAwesomeIcon className={"ok"} icon={faCheckCircle} inverse transform="shrink-8 up-8 right-6"/>}
                        {error && <FontAwesomeIcon className={"ko"} icon={faExclamationCircle} inverse transform="shrink-8 up-8 right-6"/>}
                    </span>
                </span>
            </a>
        </OverlayTrigger>
    );
}