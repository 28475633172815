import {isString} from "formik";

export function getErrorMessage(error, intl) {
    if (!error) {
        return null;
    }

    if (isString(error)) {
        return error;
    }

    if (!error.error) {
        return null;
    }

    let errorType = "GENERIC.GENERIC";

    let intlParams = {
        fields: null,
        detail: null,
        message: null,
        stack: null,
    }


    let errorObj = error.error;
    if (typeof error.error.isAxiosError) {
        //axios error
        errorObj = error.error.response;
    }

    if (errorObj) {


        if (errorObj.message) {
            errorType = "GENERIC.WITH_MESSAGE";
            intlParams.message = errorObj.message;

            if (errorObj.stack) {
                errorType = "GENERIC.WITH_MESSAGE_AND_STACK";
                intlParams.stack = errorObj.stack;
            }
        }

        if (errorObj.status) {

            errorType = errorObj.status;
            //{statuCode: 400, message: CODICE, detail: qualcosa di scritto}
            if (errorObj.status === 400 || errorObj.status === 404 || errorObj.status === 422) {
                errorType = "VALIDATION.GENERIC";
                if (errorObj.detail) {
                    errorType = "VALIDATION.WITH_DETAIL";
                    intlParams.detail = errorObj.detail;
                }
                if (errorObj.data && errorObj.data.detail) {
                    errorType = "SERVER.WITH_DETAIL";
                    intlParams.detail = errorObj.data.detail;
                }
            }
            if (errorObj.status === 500) {
                errorType = "SERVER.GENERIC";
                if (errorObj.detail) {
                    errorType = "SERVER.WITH_DETAIL";
                    intlParams.detail = errorObj.detail;
                }
                if (errorObj.data.detail) {
                    errorType = "SERVER.WITH_DETAIL";
                    intlParams.detail = errorObj.data.detail;
                }
            }
            if (errorObj.status === 403) {
                errorType = "FORBIDDEN.GENERIC";
                if (errorObj.detail) {
                    errorType = "FORBIDDEN.WITH_DETAIL";
                    intlParams.detail = errorObj.detail;
                }
                if (errorObj.data.detail) {
                    errorType = "FORBIDDEN.WITH_DETAIL";
                    intlParams.detail = errorObj.data.detail;
                }
            }
        }


        let fieldsWithError = [];

        if (errorObj.data && errorObj.data.fieldErrors) {
            fieldsWithError = errorObj.data.fieldErrors;
        }

        if (fieldsWithError.length > 0) {
            if (fieldsWithError) {
                errorType = "VALIDATION.WITH_FIELDS";

                intlParams.fields = fieldsWithError.map((fieldError) => {
                    let fieldLabel = "";
                    if (fieldError.objectName) {
                        fieldLabel += fieldError.objectName;
                    }

                    if (fieldError.field) {
                        if (fieldLabel) {
                            fieldLabel += ".";
                        }

                        fieldLabel += fieldError.field;
                    }

                    if (fieldError.objectName && fieldError.field) {
                        //objectName need to be plural
                        const fieldLabelId = fieldError.objectName.toUpperCase() + "S.FIELDS." + fieldError.field.toUpperCase() + ".LABEL";
                        const translatedLabel = intl.formatMessage({id: fieldLabelId});
                        if (translatedLabel !== fieldLabelId) {
                            fieldLabel = translatedLabel;
                        }
                    }
                    if (!fieldLabel) {
                        fieldLabel = intl.formatMessage({id: "ERRORS.VALIDATION.FIELD_NOT_SPECIFIED"});
                    }

                    let errorMessage = "";
                    if (fieldError.message) {
                        const errorMessageId = "ERRORS.VALIDATION.MESSAGES." + fieldError.message.toUpperCase();
                        const translatedErrorMessage = intl.formatMessage({id: errorMessageId}, {field: fieldLabel});
                        if (translatedErrorMessage !== errorMessageId) {
                            errorMessage = translatedErrorMessage;
                        }
                    }

                    return errorMessage;
                }).join(", ");
            }
        }

        if (errorObj.data && errorObj.data.title) {
            errorType = "VALIDATION.WITH_DETAIL";
            intlParams.detail = errorObj.data.title;
        }
    }
    const messageId = "ERRORS." + errorType;

    return intl.formatMessage({id: messageId}, intlParams);
}