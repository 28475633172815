import {toast} from "react-toastify";
import {Spinner} from "react-bootstrap";
import React from "react";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

function notifyInfo(toastId, feedback) {
    toast.dismiss(toastId);
    toast(
        <div className={"d-flex align-items-center"}>
            <Spinner animation="border" role="status"/>
            <div className={"ml-2"}>
                <FormattedMessage id={feedback}/>
            </div>
        </div>,
        {
            toastId: toastId,
            type: toast.TYPE.INFO,
            autoClose: 500000,//long autoclose to allow slow requests to accomplish
            hideProgressBar: true,
        });
}

function notifySuccess(toastId, feedback) {
    toast.dismiss(toastId);
    toast(
        <div className={"d-flex align-items-center"}>
            <span className={"svg-icon svg-icon-md"}>
                <FontAwesomeIcon icon={faCheckCircle}/>
            </span>
            <div className={"ml-2"}>
                <FormattedMessage id={feedback}/>
            </div>
        </div>,
        {
            toastId: toastId,
            type: toast.TYPE.SUCCESS,
        });
}

function notifyError(toastId, feedback) {
    toast.dismiss(toastId);
    toast(
        <div className={"d-flex align-items-center"}>
            <span className={"svg-icon svg-icon-md"}>
                <FontAwesomeIcon icon={faExclamationCircle}/>
            </span>
            <div className={"ml-2"}>
                <FormattedMessage id={feedback}/>
            </div>
        </div>,
        {
            toastId: toastId,
            type: toast.TYPE.ERROR,
        });
}

function getCreateToastId(object) {
    return `CREATE_${object}`;
}

function getUpdateToastId(object) {
    return `UPDATE_${object}`;
}

function getDeleteToastId(object) {
    return `DELETE_${object}`;
}

// CREATE

export function notifyCreating(object) {
    const toastId = getCreateToastId(object);
    const feedback = `${object}.FEEDBACK.CREATING`;
    notifyInfo(toastId, feedback);
}

export function notifyCreated(object) {
    const toastId = getCreateToastId(object);
    const feedback = `${object}.FEEDBACK.CREATED`;
    notifySuccess(toastId, feedback);
}

export function notifyCreateError(object) {
    const toastId = getCreateToastId(object);
    const feedback = `${object}.FEEDBACK.CREATE_ERROR`;
    notifyError(toastId, feedback);
}

// UPDATE

export function notifyUpdating(object) {
    const toastId = getUpdateToastId(object);
    const feedback = `${object}.FEEDBACK.UPDATING`;
    notifyInfo(toastId, feedback);
}

export function notifyUpdated(object) {
    const toastId = getUpdateToastId(object);
    const feedback = `${object}.FEEDBACK.UPDATED`;
    notifySuccess(toastId, feedback);
}

export function notifyUpdateError(object) {
    const toastId = getUpdateToastId(object);
    const feedback = `${object}.FEEDBACK.UPDATE_ERROR`;
    notifyError(toastId, feedback);
}

// DELETE

export function notifyDeleting(object) {
    const toastId = getDeleteToastId(object);
    const feedback = `${object}.FEEDBACK.DELETING`;
    notifyInfo(toastId, feedback);
}

export function notifyDeleted(object) {
    const toastId = getDeleteToastId(object);
    const feedback = `${object}.FEEDBACK.DELETED`;
    notifySuccess(toastId, feedback);
}

export function notifyDeleteError(object) {
    const toastId = getDeleteToastId(object);
    const feedback = `${object}.FEEDBACK.DELETE_ERROR`;
    notifyError(toastId, feedback);
}