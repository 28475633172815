import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import Chart from "react-apexcharts";
import {useHtmlClassService} from "../../../../_intesys/layout";
import {Card, CardBody, CardHeader} from "../../../../_intesys/_partials/controls";
import ChartColorLegend from "./ChartColorLegend";
import {shallowEqual, useSelector} from "react-redux";
import {
    getChartDarkColor,
    getChartLightColor,
    getThresholdStatus,
    STATE_ERROR,
    STATE_FETCHING
} from "../../../../_intesys/_helpers";
import LoadingMessage from "../../../modules/Common/loading-dialog/LoadingMessage";
import ErrorAlert from "../../../modules/Common/error-alert/ErrorAlert";
import {FormattedMessage, injectIntl} from "react-intl";


function SmsGraph({className, intl, dealer}) {
    const uiService = useHtmlClassService();

    const {isError, smsUsed, smsUsedError, smsTotal} = useSelector(
        (state) => ({
            isLoading: state.statistics.smsUsedState === STATE_FETCHING,
            isError: state.statistics.smsUsedState === STATE_ERROR,
            smsUsed: state.statistics.smsUsed,
            smsUsedError: state.statistics.smsUsedError,
            smsTotal: state.statistics.smsTotal,
        }),
        shallowEqual
    );

    const [value, setValue] = useState();

    const [showLoading, setShowLoading] = useState(true);
    const [showData, setShowData] = useState(false);

    const [chartOptions, setChartOptions] = useState();
    const [chartSeries, setChartSeries] = useState();
    const [showChart, setShowChart] = useState(false);

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
            colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        if (!isNaN(smsUsed) && !isNaN(smsTotal)) {
            //smsTotal:100=smsUsed:x
            const value = smsUsed * 100 / smsTotal;
            setValue(value.toFixed(1));
            setShowLoading(false);
            setShowData(true);
        }

    }, [smsUsed, smsTotal]);

    useEffect(() => {
        if (isError) {
            setShowLoading(false);
        }
    }, [isError]);


    useEffect(() => {

        if (showData) {
            const type = getThresholdStatus(value);

            const lightColor = getChartLightColor(type);
            const darkColor = getChartDarkColor(type);

            const options = {
                plotOptions: {
                    radialBar: {
                        hollow: {
                            // margin: 0,
                            // size: "65%"
                        },
                        dataLabels: {
                            showOn: "always",
                            name: {
                                show: false,
                                fontWeight: "700",
                            },
                            value: {
                                color: layoutProps.colorsGrayGray700,
                                fontSize: "30px",
                                fontWeight: "700",
                                offsetY: 12,
                                show: true
                            },
                        },
                        track: {
                            // background: layoutProps.colorsThemeLightSuccess,
                            background: lightColor,
                            strokeWidth: '100%'
                        }
                    }
                },
                // colors: [layoutProps.colorsThemeBaseSuccess],
                colors: [darkColor],
                // stroke: {
                //     lineCap: "round",
                // },
                labels: ["Progress"],
                chart: {
                    'margin-left': 'auto',
                    // 'margin-right': '-100px',
                    // width: '350px',
                    // background: '#ccc'
                },
                grid: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }
                }
            };

            setChartOptions(options);
            setChartSeries([value]);
            setShowChart(true);
        }
    }, [layoutProps, showData, value]);

    const title = intl.formatMessage({id: "DASHBOARD.SMS.TITLE"});
    const dealerName = dealer ? ' - ' + dealer.name : '';

    return (
        <Card className={className}>
            <CardHeader
                title={title + dealerName}
                className={"border-0"}
                titleClassName={"border-0 pt-5"}
                icon={"/media/svg/icons/Devices/iPhone-X.svg"}
            >
            </CardHeader>
            <CardBody className={"d-flex flex-column"}>
                <div className="flex-grow-1 has-chart-right">

                    <LoadingMessage isLoading={showLoading} className={"text-center"}/>

                    {showChart && <>
                        <div style={{
                            display: "flex",
                            flexWrap: 'wrap'
                        }}>
                            <ChartColorLegend/>

                            <Chart
                                options={chartOptions}
                                series={chartSeries}
                                type="radialBar"
                                width="300"
                            />
                        </div>

                    </>}

                    <ErrorAlert error={smsUsedError}/>
                </div>
                {showData &&
                    <div className="pt-5">
                        <table className={"table table-bordered"}>
                            <tbody>
                            <tr>
                                <td>
                                    <FormattedMessage id={"DASHBOARD.SMS.TOTAL"}/>
                                </td>
                                <td>
                                    {smsTotal}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FormattedMessage id={"DASHBOARD.SMS.USED"}/>
                                </td>
                                <td>
                                    {smsUsed}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FormattedMessage id={"DASHBOARD.SMS.REMAINING"}/>
                                </td>
                                <td>
                                    {smsTotal - smsUsed}
                                </td>
                            </tr>

                            </tbody>
                        </table>

                    </div>
                }
            </CardBody>
        </Card>
    );
}


export default injectIntl(SmsGraph);