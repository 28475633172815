import React, {useEffect, useContext, createContext, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {yupSlice} from "../../../../../_intesys/i18n/_redux/yupSlice";
import {documentsSlice} from "../../_redux/documents/documentsSlice";
import {getAllExtraFields} from "../../../../../_intesys/_helpers";

const DocumentsUIContext = createContext();

export function useDocumentsUIContext() {
    return useContext(DocumentsUIContext);
}

export const DocumentsUIConsumer = DocumentsUIContext.Consumer;

export function DocumentsUIProvider({documentsUIEvents, currentFolderId, documentIdToOpenInEditMode = null, children}) {
    const dispatch = useDispatch();
    const [folderId, setFolderId] = useState(currentFolderId);
    const [folderType, setFolderType] = useState(currentFolderId);

    const [selectedId, setSelectedId] = useState(null);
    const [showEditDocumentDialog, setShowEditDocumentDialog] = useState(false);
    const [showDocumentQrCodeDialog, setShowDocumentQrCodeDialog] = useState(false);

    const {folderTypesExt} = useSelector(
        (state) => ({
            folderTypesExt: state.configurations.folderTypesExt,

        }),
        shallowEqual
    );

    const initDocument = {
        archivable: false,
        content: undefined,
        signable: false,
        status: undefined,
        type: undefined,
        folderId: folderId,
        file: undefined,
        sigType: undefined,
    };


    const extraFields = getAllExtraFields(folderTypesExt);

    for (const fieldKey in extraFields) {
        const field = extraFields[fieldKey];

        let value = undefined;
        if (field.type === 'checkbox') {
            value = false;
        }

        initDocument['variableFields_' + field.name] = value;
    }


    useEffect(() => {
        initDocument.folderId = currentFolderId
        setFolderId(currentFolderId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFolderId]);

    useEffect(() => {
        if (documentIdToOpenInEditMode) {
            openEditDocumentDialog(documentIdToOpenInEditMode)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentIdToOpenInEditMode]);


    const openNewDocumentDialog = (folderId, folderType) => {
        dispatch(documentsSlice.actions.resetItemState(undefined));
        dispatch(yupSlice.actions.setContext({context: 'DOCUMENTS'}));
        setFolderId(folderId);
        setFolderType(folderType);
        setSelectedId(undefined);
        setShowEditDocumentDialog(true);
    }
    const openEditDocumentDialog = (documentId) => {
        dispatch(documentsSlice.actions.resetItemState(undefined));
        dispatch(yupSlice.actions.setContext({context: 'DOCUMENTS'}));
        setSelectedId(documentId);
        setShowEditDocumentDialog(true);
    };
    const closeEditDocumentDialog = () => {
        dispatch(documentsSlice.actions.saveDone(undefined));
        dispatch(yupSlice.actions.setContext({context: undefined}));
        setFolderId(undefined);
        setFolderType(undefined);
        setSelectedId(undefined);
        setShowEditDocumentDialog(false);
    };

    //DELETE

    const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState(false);
    const openDeleteDocumentDialog = id => {
        dispatch(documentsSlice.actions.resetItemState(undefined));
        setSelectedId(id);
        setShowDeleteDocumentDialog(true);
    };
    const closeDeleteDocumentDialog = () => {
        dispatch(documentsSlice.actions.saveDone(undefined));
        setSelectedId(undefined);
        setShowDeleteDocumentDialog(false);
    };


    //QR CODE

    const openDocumentQrCodeDialog = (documentId) => {
        // dispatch(documentsSlice.actions.resetItemState(undefined));
        setSelectedId(documentId)
        setShowDocumentQrCodeDialog(true);
    }

    const closeDocumentQrCodeDialog = () => {
        setSelectedId(undefined);
        setShowDocumentQrCodeDialog(false);
    }

    const value = {
        folderId,
        setFolderId,
        folderType,
        setFolderType,
        selectedId,
        openNewDocumentDialog,
        showEditDocumentDialog,
        openEditDocumentDialog,
        closeEditDocumentDialog,
        initDocument,
        //delete document
        showDeleteDocumentDialog,
        openDeleteDocumentDialog,
        closeDeleteDocumentDialog,
        //document qr code
        showDocumentQrCodeDialog,
        openDocumentQrCodeDialog,
        closeDocumentQrCodeDialog,
        openShowDocumentPage: documentsUIEvents.openShowDocumentPage,
    };

    return (
        <DocumentsUIContext.Provider value={value}>
            {children}
        </DocumentsUIContext.Provider>
    );
}
