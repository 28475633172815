import React, {useMemo} from "react";
import {useHtmlClassService} from "../../../../_intesys/layout";
import {FormattedMessage} from "react-intl";

export function Footer() {
    //const today = new Date().getFullYear();
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            footerClasses: uiService.getClasses("footer", true),
            footerContainerClasses: uiService.getClasses("footer_container", true)
        };
    }, [uiService]);

    return (
        <div
            className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
            id="kt_footer"
        >
            <div
                className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
                <div className="text-dark order-2 order-md-1">
                    <FormattedMessage
                        id="FOOTER.COL1_TEXT"
                        values={{
                            a: chunks => (
                                <a href="mailto:bmwdvn@intesys.it" rel="noopener noreferrer"
                                   className="text-dark-75 text-hover-primary">
                                    <b>{chunks}</b>
                                </a>
                            ),
                            mail: "bmwdvn@intesys.it"
                        }}
                    />
                </div>
                <div className="nav nav-dark order-1 order-md-2">
                    <FormattedMessage id="FOOTER.COL2_TEXT"/>
                </div>
            </div>
        </div>
    );
}
