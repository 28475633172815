import {shallowEqual, useSelector} from "react-redux";
import {STATE_ERROR, STATE_FETCHED, STATE_FETCHING} from "../../../../_intesys/_helpers";
import React, {useMemo} from "react";
import {injectIntl} from "react-intl";
import {Card, CardBody} from "../../../../_intesys/_partials/controls";
import {useDocumentsUIContext} from "../../../modules/App/pages/documents/DocumentsUIContext";
import {DocumentsTable} from "../../../modules/App/pages/documents/DocumentsTable";

function DocumentsList({className, intl, dealer}) {
    const {isLoading, isError, isFetched, documents, error} = useSelector(
        (state) => ({
            isLoading: state.statistics.documentsState === STATE_FETCHING,
            isError: state.statistics.documentsState === STATE_ERROR,
            isFetched: state.statistics.documentsState === STATE_FETCHED,
            documents: state.statistics.documents,
            error: state.statistics.documentsError,
        }),
        shallowEqual
    );

    const title = intl.formatMessage({id: "DASHBOARD.DOCUMENTS.TITLE"});

    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
        return {
            openShowDocumentPage: documentsUIContext.openShowDocumentPage,
            openDocumentQrCodeDialog: documentsUIContext.openDocumentQrCodeDialog,
            openEditDocumentDialog: documentsUIContext.openEditDocumentDialog,
            openDeleteDocumentDialog: documentsUIContext.openDeleteDocumentDialog,
        };
    }, [documentsUIContext]);


    const dealerName = dealer ? ' - ' + dealer.name : '';

    return (
        <>
            <h2 className={"pt-4 pb-8"}>{title + dealerName}</h2>
            {/*<CardHeader title={title + dealerName} className={"border-0"} titleClassName={"border-0 pt-5"}>*/}
            {/*</CardHeader>*/}
            <Card className={className}>
                <CardBody className={"d-flex flex-column"}>

                    <DocumentsTable
                        documents={documents}
                        isLoading={isLoading}
                        isError={isError}
                        isFetched={isFetched}
                        error={error}
                        openShowDocumentPage={documentsUIProps.openShowDocumentPage}
                        openDocumentQrCodeDialog={documentsUIProps.openDocumentQrCodeDialog}
                        openEditDocumentDialog={documentsUIProps.openEditDocumentDialog}
                        openDeleteDocumentDialog={documentsUIProps.openDeleteDocumentDialog}
                    />
                </CardBody>
            </Card>
        </>
    );

}

export default injectIntl(DocumentsList);
