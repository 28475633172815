import React from "react";
import {getChartDarkColor, getLegendItems} from "../../../../_intesys/_helpers";
import {injectIntl} from "react-intl";


function ChartColorLegend({intl}) {
    const legendItems = getLegendItems(intl)

    const legendItemsComponent = legendItems.map((item, index) => <div
            key={index}
            className="apexcharts-legend-series"
            style={{
                margin: '2px 5px',

            }}
        >
            <span
                className="apexcharts-legend-marker"
                style={{
                    backgroundColor: getChartDarkColor(item.type),
                    color: getChartDarkColor(item.type),
                    height: '12px',
                    width: '12px',
                    left: '0px',
                    top: '0px',
                    borderWidth: '0px',
                    borderColor: 'rgb(255, 255, 255)',
                    borderRadius: '12px',

                }}
            >
            </span>
            <span className="apexcharts-legend-text"
                  style={{
                      color: 'rgb(55, 61, 63)',
                      fontSize: '12px',
                      fontWeight: '400',
                      fontFamily: 'Helvetica, Arial, sans-serif',

                  }}
            >
                {item.label}
            </span>
        </div>
    )

    return (
        <div
            className="apexcharts-legend apexcharts-align-center position-right"
            style={{
                // position: 'absolute',
                left: '0',
                top: '0',
                right: 'auto',
            }}
        >
            {legendItemsComponent}
        </div>
    );
}

export default injectIntl(ChartColorLegend);