import React from "react";
import {Field} from "formik";
import {Boolean, Input, RadioGroup, ReactDropZone, Select} from "../../../../../../_intesys/_partials/controls";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {
    documentTypeExtraFields,
    documentTypeHasField,
    getDocumentLabelFromType,
    getDocumentSigTypeIntlIdFromValue,
    getDocumentTypesFilteredByFolderType,
} from "../../../../../../_intesys/_helpers";
import {SIG_TYPES} from "../DocumentsUIHelper";

function DocumentEditDialogFormFields({
                                          isEdit = false,
                                          folderType,
                                          documentType,
                                          namePrefix = "",
                                          isWizard = false,
                                          intl
                                      }) {

    const {folderTypesExt} = useSelector(state => state.configurations);

    function renderExtraField(field) {
        let result;
        const fieldName = field.field;

        const inputName = `${namePrefix}variableFields_${fieldName}`;
        const inputLabel = field.label;
        const fieldType = field.type;
        //let fieldType = getExtraFieldType(fieldName);//TODO: change


        switch (fieldType) {
            case 'string':

                result =
                    <div className="col-md-6" key={fieldName}>
                        <Field
                            name={inputName}
                            component={Input}
                            label={inputLabel}
                            // placeholder={intl.formatMessage({id: "FOLDERS.FIELDS." + field.field.toUpperCase() + ".PLACEHOLDER"})}
                            // initialFeedBackLabel={intl.formatMessage({id: "FOLDERS.FIELDS." + field.field.toUpperCase() + ".FEEDBACK"})}
                        />
                    </div>;
                break;
            case 'checkbox':
                result =
                    <div className="col-md-12" key={fieldName}>
                        <Field
                            name={inputName}
                            component={Boolean}
                            type={"checkbox"}
                            label={inputLabel}
                            // label={intl.formatMessage({id: "USERS.FIELDS.ACTIVATED.LABEL"})}
                            // checkBoxLabel={intl.formatMessage({id: "USERS.FIELDS.ACTIVATED.PLACEHOLDER"})}
                            // placeholder={intl.formatMessage({id: "USERS.FIELDS.ACTIVATED.PLACEHOLDER"})}
                            // initialFeedBackLabel={intl.formatMessage({id: "USERS.FIELDS.ACTIVATED.FEEDBACK"})}
                        />
                    </div>;

                break;
            case 'date':
                result =
                    <div className="col-lg-6" key={fieldName}>
                        <Field
                            name={inputName}
                            component={Input}
                            type={"date"}
                            label={inputLabel}
                            // label={intl.formatMessage({id: "SERVICES.FIELDS.EXPIRESAT.LABEL"})}
                            // placeholder={intl.formatMessage({id: "SERVICES.FIELDS.EXPIRESAT.PLACEHOLDER"})}
                            // initialFeedBackLabel={intl.formatMessage({id: "SERVICES.FIELDS.EXPIRESAT.FEEDBACK"})}
                        />
                    </div>;
                break;
            default:
                result =
                    <div className="col-md-12" key={fieldName}>
                        <div className={"text-danger"}>
                            {field.field} non gestito
                        </div>
                    </div>;
        }
        return result;
    }

    return (
        <>
            <div className="form-group row">
                {(!isEdit && !isWizard) &&
                    <div className="col-md-12">
                        <Select
                            name={`${namePrefix}type`}
                            label={intl.formatMessage({id: "DOCUMENTS.FIELDS.TYPE.LABEL"})}
                            placeholder={intl.formatMessage({id: "DOCUMENTS.FIELDS.TYPE.PLACEHOLDER"})}
                            initialFeedBackLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.TYPE.FEEDBACK"})}
                        >
                            <option/>
                            {getDocumentTypesFilteredByFolderType(folderType, folderTypesExt, false).map((documentType) => (
                                <option key={documentType} value={documentType}>
                                    {getDocumentLabelFromType(documentType, folderType, folderTypesExt)}
                                </option>
                            ))}
                        </Select>

                    </div>

                }

                {(!isEdit && documentTypeHasField(documentType, folderType, folderTypesExt, "FILE")) &&

                    <div className="col-md-12">
                        <Field
                            name={`${namePrefix}file`}
                            maxFiles={1}
                            // accept={[
                            //     "application/pdf",//pdf
                            //     "image/jpeg",//jpeg
                            //     "image/png",//png
                            //     "image/tiff",//tiff
                            //     "application/msword",//doc
                            //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document"//docx
                            // ].join(", ")}
                            component={ReactDropZone}
                            multiple={false}
                            label={intl.formatMessage({id: "DOCUMENTS.FIELDS.FILE.LABEL"})}
                            placeholder={intl.formatMessage({id: "DOCUMENTS.FIELDS.FILE.PLACEHOLDER"})}
                            initialFeedBackLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.FILE.FEEDBACK"})}
                        />

                    </div>

                }

                {documentTypeHasField(documentType, folderType, folderTypesExt, "SIG_TYPE") && (

                    <div className="col-md-12">
                        <RadioGroup
                            name={`${namePrefix}sigType`}
                            label={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGTYPE.LABEL"}, {
                                strong: chunks => (
                                    <strong>{chunks}</strong>
                                )
                            })}
                            placeholder={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGTYPE.PLACEHOLDER"})}
                            initialFeedBackLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGTYPE.FEEDBACK"})}
                            options={SIG_TYPES.map((signatureType) => {
                                return {
                                    value: signatureType,
                                    label: intl.formatMessage({id: getDocumentSigTypeIntlIdFromValue(signatureType)})
                                }
                            })}
                        />
                    </div>

                )}

                {documentTypeHasField(documentType, folderType, folderTypesExt, "SIGNABLE") && (
                    <div className="col-md-12">
                        <Field
                            name={`${namePrefix}signable`}
                            component={Boolean}
                            type={"checkbox"}
                            label={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGNABLE.LABEL"})}
                            checkBoxLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGNABLE.CHECKBOX_LABEL_WITH_STRONG"}, {
                                strong: chunks => (
                                    <strong>{chunks}</strong>
                                )
                            })}
                            initialFeedBackLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGNABLE.FEEDBACK"})}
                            helpText={intl.formatMessage({id: "DOCUMENTS.FIELDS.SIGNABLE.HELP"})}
                        />
                    </div>
                )}

                {documentTypeHasField(documentType, folderType, folderTypesExt, "ARCHIVABLE") && (

                    <div className="col-md-12">
                        <Field
                            name={`${namePrefix}archivable`}
                            component={Boolean}
                            type={"checkbox"}
                            label={intl.formatMessage({id: "DOCUMENTS.FIELDS.ARCHIVABLE.LABEL"})}
                            checkBoxLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.ARCHIVABLE.CHECKBOX_LABEL_WITH_STRONG"}, {
                                strong: chunks => (
                                    <strong>{chunks}</strong>
                                )
                            })}
                            initialFeedBackLabel={intl.formatMessage({id: "DOCUMENTS.FIELDS.ARCHIVABLE.FEEDBACK"})}
                        />
                    </div>

                )}


                {documentTypeExtraFields(documentType, folderType, folderTypesExt).map((field) => renderExtraField(field))}


            </div>


        </>
    );

}


export default injectIntl(DocumentEditDialogFormFields);
