import React, {useCallback} from "react";
import {useDropzone} from 'react-dropzone'
import {getIn, useField} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {FormattedMessage} from "react-intl";
import {FieldTooltip} from "./FieldTooltip";

// Error codes
export const FILE_INVALID_TYPE = 'file-invalid-type'
export const FILE_TOO_LARGE = 'file-too-large'
export const FILE_TOO_SMALL = 'file-too-small'
export const TOO_MANY_FILES = 'too-many-files'

// https://react-dropzone.js.org/#src
export function ReactDropZone({
                                  label,
                                  field,
                                  form,
                                  maxFiles = 0,
                                  maxSize = Infinity,
                                  minSize = 0,
                                  multiple = true,
                                  accept,
                                  disabled,
                                  initialFeedBackLabel,
                                  withFeedbackLabel = true,
                                  withValidationClasses = true,
                                  customFeedbackLabel,
                                  helpText = "",
                                  ...props
                              }) {


    const {touched, errors} = form;

    const fieldError = getIn(errors, field.name);
    const fieldTouched = getIn(touched, field.name);

    const helpers = useField(field.name)[2];
    const {setTouched} = helpers;


    const onDrop = useCallback((acceptedFiles) => {
        //https://github.com/formium/formik/issues/117
        setTouched(true);
        form.setFieldValue(
            field.name,
            acceptedFiles
        );
    }, [field.name, form, setTouched]);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop,
        maxFiles: maxFiles,
        accept: accept,
        multiple: multiple,
        maxSize: maxSize,
        minSize: minSize,
        disabled: disabled
    });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    //https://react-dropzone.js.org/#section-accepting-specific-number-of-files
    //https://gist.github.com/RaphaelRegnier/797015e23a64e8959182528646b46954

    const getErrorMessageParameters = (errorCode) => {
        let params = {};
        switch (errorCode) {
            case FILE_INVALID_TYPE:
                accept = Array.isArray(accept) && accept.length === 1 ? accept[0] : accept
                params.messageSuffix = Array.isArray(accept) ?
                    <FormattedMessage id={'DROPZONE.ERRORS.ONE_OF'} values={{formats: accept.join(', ')}}/> : accept;
                break;
            case FILE_TOO_LARGE:
                params.maxSize = maxSize;
                break;
            case FILE_TOO_SMALL:
                params.minSize = minSize;
                break;
            case TOO_MANY_FILES:
                break;
            default:
                break;
        }
        return params;
    }
    const fileRejectionItems = fileRejections.map(({file, errors}) => {
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>
                        {/*{e.message}*/}
                        <FormattedMessage id={"DROPZONE.ERRORS." + e.code.toUpperCase()}
                                          values={getErrorMessageParameters(e.code)}/>
                    </li>)}
                </ul>

            </li>
        )
    });

    return (
        <>
            <label>{label}<FieldTooltip text={helpText}/></label>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>
                    <FormattedMessage id={"DROPZONE.DRAG_AND_DROP." + ((maxFiles === 1) ? "MULTIPLE" : "SINGLE")}/>
                </p>
            </div>
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={fieldError}
                    touched={fieldTouched}
                    label={label}
                    initialFeedBackLabel={initialFeedBackLabel}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}

            {(field.value && field.value.length > 0 && acceptedFiles.length === 0 && fileRejections.length === 0) &&
                <div className={"valid-feedback d-block"}>
                    <div>
                        <FormattedMessage id={"DROPZONE.LOADED_FILES"}/>
                    </div>

                    <ul>
                        {field.value.map((file, index) => <li key={index}>{file.name}</li>)}
                    </ul>
                </div>
            }

            {acceptedFiles.length > 0 &&
                <div className={"valid-feedback d-block"}>
                    <div>
                        <FormattedMessage id={"DROPZONE.FILES"}/>
                    </div>
                    <ul>{files}</ul>
                </div>
            }
            {fileRejections.length > 0 &&
                <div className={"invalid-feedback d-block"}>
                    <div>
                        <FormattedMessage id={"DROPZONE.REJECTED_FILES"}/>
                    </div>
                    <ul>{fileRejectionItems}</ul>
                </div>
            }
        </>
    );
}