import axios from "axios";

export const DOCUMENTS_URL = "/api/documents";
const {REACT_APP_API_URL} = process.env;

export function searchDocumentsExt(data) {
    return axios.post(`${REACT_APP_API_URL}${DOCUMENTS_URL}-ext/search?page=${data.page}&size=${data.size}&sort=${data.sort}`, data);
}

export function getDocumentsByFolderId(folderId) {
    const params = {
        folderId: {
            equals: folderId,
        },
        page: 0,
        size: 10000,
        sort: "id,desc",
    };
    return searchDocumentsExt(params);
}

export function createDocument(document) {
    return axios.post(`${REACT_APP_API_URL}${DOCUMENTS_URL}`, document);
}

export function documentSign(documentId) {
    return axios.post(`${REACT_APP_API_URL}${DOCUMENTS_URL}/${documentId}/sign`, document);
}

export function downloadDocument(documentId) {
    //https://stackoverflow.com/questions/60454048/how-does-axios-handle-blob-vs-arraybuffer-as-responsetype
    return axios.get(`${REACT_APP_API_URL}${DOCUMENTS_URL}/${documentId}/download`, {responseType: 'arraybuffer'});//responseType: 'blob'
}

export function getDocumentQrCode(documentId) {
    return axios.get(`${REACT_APP_API_URL}/mobile/fdd/qrcode/generate/${documentId}`)
}

export function getDocumentById(documentId) {
    return axios.get(`${REACT_APP_API_URL}${DOCUMENTS_URL}/${documentId}`);
}

export function updateDocument(document) {
    return axios.put(`${REACT_APP_API_URL}${DOCUMENTS_URL}`, document);
}

export function deleteDocument(documentId) {
    return axios.delete(`${REACT_APP_API_URL}${DOCUMENTS_URL}/${documentId}`);
}

export function getDocumentsCount(params) {
    return axios.get(`${REACT_APP_API_URL}${DOCUMENTS_URL}/count`, {params});
}

export function wizard(params) {
    return axios.post(`${REACT_APP_API_URL}${DOCUMENTS_URL}/wizard`, params);
}

export function createFeaDocument(document) {
    return axios.post(`${REACT_APP_API_URL}${DOCUMENTS_URL}/fea`, document);
}