import React, {useState, useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_intesys/_partials/controls";
import {injectIntl} from "react-intl";
import {
    getDocumentLabelFromType,
    STATE_FETCHING
} from "../../../../../../_intesys/_helpers";

export function DocumentEditDialogHeader({id, intl}) {
    const [title, setTitle] = useState("");
    const {documentForEdit, isLoading, folderTypesExt, folder} = useSelector(
        (state) => ({
            documentForEdit: state.documents.itemForEdit,
            isLoading: state.documents.itemState === STATE_FETCHING,
            folderTypesExt: state.configurations.folderTypesExt,
            folder: state.folders.itemForEdit,
        }),
        shallowEqual
    );

    useEffect(() => {
        let _title = id ? "" : intl.formatMessage({id: "DOCUMENTS.EDIT.TITLE.NEW"});
        if (documentForEdit && id) {
            _title = intl.formatMessage({id: "DOCUMENTS.EDIT.TITLE.UPDATE"}, {
                id: documentForEdit.id,
                type: getDocumentLabelFromType(documentForEdit.type, folder?.type, folderTypesExt)
            });
        }

        setTitle(_title);
    }, [documentForEdit, folder, folderTypesExt, id, intl]);

    return (
        <>
            {isLoading && <ModalProgressBar variant="query"/>}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
        </>
    );
}


export default injectIntl(DocumentEditDialogHeader);