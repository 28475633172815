import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ModalProgressBar} from "../../../../../../_intesys/_partials/controls";
import * as actions from "../../../_redux/documents/documentsActions";
import * as foldersActions from "../../../_redux/folders/foldersActions";
import {useDocumentsUIContext} from "../DocumentsUIContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    userCanDeleteDocument,
    STATE_DELETED,
    STATE_DELETING,
    STATE_ERROR,
    getDocumentLabelFromType
} from "../../../../../../_intesys/_helpers";
import ErrorAlert from "../../../../Common/error-alert/ErrorAlert";
import {faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Notifier from "../../../../Common/notifier/Notifier";

function DocumentDeleteDialog({intl}) {
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
        return {
            id: documentsUIContext.selectedId,
            showDeleteDocumentDialog: documentsUIContext.showDeleteDocumentDialog,
            closeDeleteDocumentDialog: documentsUIContext.closeDeleteDocumentDialog,
        };
    }, [documentsUIContext]);

    const dispatch = useDispatch();

    const {document, isDeleting, isDeleted, isError, error, folderTypesExt} = useSelector(
        (state) => ({
            document: state.documents.itemForEdit,
            isDeleting: state.documents.itemState === STATE_DELETING,
            isDeleted: state.documents.itemState === STATE_DELETED,
            isError: state.documents.itemState === STATE_ERROR,
            error: state.documents.itemError,
            folderTypesExt: state.configurations.folderTypesExt,
        }),
        shallowEqual
    );

    const {folder} = useSelector(
        (state) => ({
            folder: state.folders.itemForEdit,
        }),
        shallowEqual
    );

    const {user} = useSelector(state => state.auth);

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    useEffect(() => {
        if (userCanDeleteDocument(user, folder, document)) {
            setShowDeleteButton(true);
        }
    }, [document, folder, user]);

    useEffect(() => {
        if (documentsUIProps.showDeleteDocumentDialog) {
            if (documentsUIProps.id) {
                dispatch(actions.fetchDocument(documentsUIProps.id));
            }
        }
    }, [documentsUIProps.showDeleteDocumentDialog, documentsUIProps.id, dispatch]);

    useEffect(() => {
        if (documentsUIProps.showDeleteDocumentDialog) {
            if (document) {
                dispatch(foldersActions.fetchFolder(document.folderId));
            }
        }
    }, [documentsUIProps.showDeleteDocumentDialog, document, dispatch]);

    // if !id we should close modal
    useEffect(() => {
        if (!documentsUIProps.id) {
            documentsUIProps.closeDeleteDocumentDialog();
        }
    }, [documentsUIProps]);

    useEffect(() => {
    }, [isDeleting, dispatch]);

    const deleteDocument = () => {
        dispatch(actions.deleteDocument(documentsUIProps.id));
    };

    useEffect(() => {
        if (isDeleted) {
            documentsUIProps.closeDeleteDocumentDialog();
        }
    }, [isDeleted, documentsUIProps]);

    return (
        <Modal
            show={documentsUIProps.showDeleteDocumentDialog}
            onHide={documentsUIProps.closeDeleteDocumentDialog}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {isDeleting && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <FormattedMessage id={"DOCUMENTS.DELETE.TITLE"} values={{
                        id: document ? document.id : "",
                        type: getDocumentLabelFromType(document?.type, folder?.type, folderTypesExt)
                    }}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Notifier
                    object={"DOCUMENTS"}
                    isDeleting={isDeleting}
                    isDeleted={isDeleted}
                    isError={isError}
                    error={error}
                />
                <ErrorAlert error={error}/>
                {isDeleting ?
                    <span>
                        <FormattedMessage id={"DOCUMENTS.DELETE.DELETING"}/>
                    </span>
                    :
                    <span>
                        <FormattedMessage id={"DOCUMENTS.DELETE.MESSAGE"}/>
                    </span>
                }
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={documentsUIProps.closeDeleteDocumentDialog}
                        className="btn btn-light btn-elevate"
                    >
                        <FontAwesomeIcon icon={faTimes} className={"mr-1"}/>
                        <FormattedMessage id={"GENERIC.CANCEL"}/>
                    </button>
                    {showDeleteButton &&
                        <button
                            type="button"
                            onClick={deleteDocument}
                            className="btn btn-danger btn-elevate ml-3"
                        >
                            <FontAwesomeIcon icon={faTrash} className={"mr-1"}/>
                            <FormattedMessage id={"GENERIC.DELETE"}/>
                        </button>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default injectIntl(DocumentDeleteDialog);