import React from "react";
import {useField} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import Select from 'react-select';
import {FieldTooltip} from "./FieldTooltip";

const getFieldCSSClasses = (touched, errors, withValidationClasses) => {
    const classes = [];
    if (withValidationClasses) {
        if (touched && errors) {
            classes.push("is-invalid");
        }

        if (touched && !errors) {
            classes.push("is-valid");
        }
    }

    return classes.join(" ");
};

export function ReactSelect({
                                label,
                                initialFeedBackLabel,
                                withFeedbackLabel = true,
                                withValidationClasses = true,
                                type = "text",
                                customFeedbackLabel,
                                children,
                                form,
                                options,
                                disabled,
                                helpText = "",
                                ...props
                            }) {

    const [field, meta, helpers] = useField(props.field.name);
    const {setValue, setTouched} = helpers;

    const {touched, error} = meta;

    const callOnChangeFunction = (selectedOption) => {
        if (props.onChangeFunction) {
            setValue(props.onChangeFunction(selectedOption));
        } else {
            let value = selectedOption;
            if (props.isMulti && !selectedOption) {
                value = [];
            }
            setValue(value);
        }
    }

    const callValueFunction = () => {
        if (props.valueFunction) {
            return props.valueFunction(options, field);
        } else {
            if (field.value) {
                return field.value;
            } else {
                if (props.isMulti) {
                    return [];
                } else {
                    return undefined;
                }
            }
        }
    }

    return (
        <>
            {label && <label>{label}<FieldTooltip text={helpText}/></label>}
            <Select
                className={getFieldCSSClasses(touched, error, withValidationClasses)}
                isDisabled={disabled}
                options={options}
                {...props}
                onChange={props.onChange ? props.onChange : callOnChangeFunction}
                onBlur={setTouched}
                value={callValueFunction()}
            />
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={error}
                    touched={touched}
                    label={label}
                    initialFeedBackLabel={initialFeedBackLabel}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
