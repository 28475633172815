import {userHasOnlyReadOnlyPermissions} from "./PermissionsHelpers";
import * as Yup from "yup";
import {convertDatetimeFromApi} from "./ApiDatetimeHelpers";
import {STATE_ERROR, STATE_FETCHING} from "./ReducerHelpers";
import React from "react";
import {getDealerLegalEntityFromHst} from "./DealersHelpers";

export function userCanShowDossier(user, dossier) {
    if (dossier && user) {
        return true;
    }
    return false;
}

export function userCanCreateDossier(user) {
    if (user) {
        return !userHasOnlyReadOnlyPermissions(user);
    }
    return false;
}

export function userCanUpdateDossier(user, dossier) {
    if (dossier && user) {
        //return (dossier.numberOfFolders === 0) && !userHasOnlyReadOnlyPermissions(user);
        return !userHasOnlyReadOnlyPermissions(user);
    }
    return false;
}

export function userCanDeleteDossier(user, dossier) {
    if (dossier && user) {
        return (dossier.numberOfFolders === 0) && !userHasOnlyReadOnlyPermissions(user);
    }
    return false;
}

function isFes(hst, folderType, dealers, signatureTypeServices) {
    // console.log('isFes',hst, folderType, dealers, signatureTypeServices);
    if (hst && dealers && signatureTypeServices) {
        //const dealer = getDealerFromHst(dealers, hst);
        const legalEntity = getDealerLegalEntityFromHst(dealers, hst);
        const signatureTypeService = signatureTypeServices.find(signatureTypeService => legalEntity === signatureTypeService.legalEntity);
        // TODO: add expiration date check - Umberto Quintarelli decided to not check
        // signatureTypeService.service.expiresAt
        if (signatureTypeService && signatureTypeService.service.signatureType === 'FES') {
            const found = signatureTypeService.service.folderTypeSet.find(singleFolderType => singleFolderType === folderType);
            return (found === folderType);
        }
    }
    return false;
}

export function getDossiersValidationSchema(folderType, dealers, signatureTypeServices) {
    return Yup.object().shape({
        //dossierType: Yup.string().required(),
        hst: Yup.string().required(),
        firstName: Yup.string().ensure().max(255).required(),
        lastName: Yup.string().ensure().max(255).required(),
        city: Yup.string().ensure().max(255).required(),
        postalCode: Yup.string().ensure().max(255).required(),
        street: Yup.string().ensure().max(255).required(),
        mobilePhone: Yup.string().ensure().required().matches(/^\+[0-9]{2}[ ]?[0-9]{9,10}$/, {excludeEmptyString: true}),
        email: Yup.string().ensure().email().max(255).required(),
        birthDate: Yup.date().when(`hst`, (hst, schema) => {
            return isFes(hst, folderType, dealers, signatureTypeServices) ? schema.nullable() : schema.required();
        }),
        birthPlace: Yup.string().ensure().when(`hst`, (hst, schema) => {
            return isFes(hst, folderType, dealers, signatureTypeServices) ? schema.nullable() : schema.required();
        }),
        fiscalCode: Yup.string().ensure().matches(/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/gmi, {excludeEmptyString: true}).when(`hst`, (hst, schema) => {
            return isFes(hst, folderType, dealers, signatureTypeServices) ? schema.nullable() : schema.required();
        }),

        // birthDate: Yup.date().required(),
        //birthPlace: Yup.string().ensure().required(),
        //https://regex101.com/r/sC9dsl/1
        //fiscalCode:  Yup.string().ensure().required().matches(/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/gmi, {excludeEmptyString: true}),
    });
}


export function getDossiersMissingValidationSchema() {
    return Yup.object().shape({
        birthDate: Yup.date().required(),
        birthPlace: Yup.string().ensure().required(),
        //https://regex101.com/r/sC9dsl/1
        fiscalCode: Yup.string().ensure().required().matches(/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/gmi, {excludeEmptyString: true}),
    });
}

export function getLegalEntityDossierBatchStatusUiData(legalEntity, batchStatuses, intl) {
    let showSupportMessage = false;
    let updateButtonDisabled = true;
    let lastUpdateDateMessage = undefined;
    let statusMessage = undefined;

    let isError = false;
    let isLoading = false;
    let error = undefined;
    let supportMessage = undefined;
    if (intl) {
        supportMessage = intl.formatMessage({id: "DOSSIERS.CALL_WS_BATCH.SUPPORT_MESSAGE"}, {
            a: chunks => (
                <a href="mailto:bmwdvn@intesys.it" rel="noopener noreferrer"
                   className="text-dark-75 text-hover-primary">
                    <b>{chunks}</b>
                </a>
            ),
            mail: "bmwdvn@intesys.it"
        });
    }


    if (legalEntity && batchStatuses && intl) {
        const batchData = batchStatuses[legalEntity]?.data;
        error = batchStatuses[legalEntity]?.error;
        const batchStatus = batchStatuses[legalEntity]?.state;
        if (batchStatus) {
            isError = (batchStatus === STATE_ERROR);
            isLoading = (batchStatus === STATE_FETCHING);
            if (isError) {
                showSupportMessage = true;
            }
        }

        if (batchData) {
            const status = batchData.syncStatus;
            const statusLabel = intl.formatMessage({id: "DOSSIERS.CALL_WS_BATCH.STATUSES." + status});
            const lastSync = convertDatetimeFromApi(batchData.lastSync);


            const lastSyncLabel = intl.formatDate(lastSync, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            }) + ' ' + intl.formatTime(lastSync);


            lastUpdateDateMessage = intl.formatMessage({
                id: "DOSSIERS.CALL_WS_BATCH.LAST_UPDATE_DATE_MESSAGE",
            }, {
                lastUpdate: lastSyncLabel,
            });

            statusMessage = intl.formatMessage({
                id: "DOSSIERS.CALL_WS_BATCH.STATUS_MESSAGE",
            }, {
                status: statusLabel,
            });

            //TO_SYNC, SYNCING, SYNC_FAILED, SYNCED
            if (status === "SYNCED") {
                updateButtonDisabled = false;
            }
            if (status === "SYNC_FAILED") {
                showSupportMessage = true;
            }
        }
    }
    return {
        'isError': isError,
        'isLoading': isLoading,
        'error': error,
        'lastUpdateDateMessage': lastUpdateDateMessage,
        'statusMessage': statusMessage,
        'showSupportMessage': showSupportMessage,
        'supportMessage': supportMessage,
        'updateButtonDisabled': updateButtonDisabled,
    }
}