import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockUsers from "../../app/modules/Admin/__mocks__/mockUser";


export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300,onNoMatch: "passthrough"  });

  mockAuth(mock);
  mockUsers(mock);

  return mock;
}
