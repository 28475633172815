import {createSlice} from "@reduxjs/toolkit";


const initialYupState = {
    context: undefined,
};

export const yupSlice = createSlice({
    name: "yup",
    initialState: initialYupState,
    reducers: {
        setContext: (state, action) => {
            state.context = action.payload.context;
            window.yupContext = state.context;
        }
    }
});