import React, {useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import * as documentsActions from "../../_redux/documents/documentsActions";
import * as signedDocumentsActions from "../../_redux/signed-documents/signedDocumentsActions";
import * as auditsActions from "../../_redux/audits/auditsActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    convertDatetimeFromApi,
    getDocumentSigTypeIntlIdFromValue,
    getDocumentStatusIntlIdFromValue,
    userCanDeleteDocument,
    userCanSeeSignQrCode,
    userCanDoStartSign,
    userCanUpdateDocument,
    showSignedDocumentDownloadButton,
    showSignedDocumentDownloadAuditButton,
    STATE_FETCHING,
    STATE_ERROR,
    STATE_FETCHED,
    formatBytes,
    documentIsBlockedByFeaDocument, getDocumentLabelFromType,
} from "../../../../../_intesys/_helpers";
import ErrorAlert from "../../../Common/error-alert/ErrorAlert";
import {FormattedDateTime} from "../../../Common/formatter/FormattedDateTime";
import {
    faBinoculars,
    faEdit,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileDownload,
    faFileSignature,
    faQrcode,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIconButton} from "../../../Common/button/FontAwesomeIconButton";
import LoadingMessage from "../../../Common/loading-dialog/LoadingMessage";
// import {readDossierSignatureType} from "../../_redux/dossiers/dossiersActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {readFolderSignatureType} from "../../_redux/folders/foldersActions";

export function DocumentsTable({
                                   documents,
                                   isLoading,
                                   isError,
                                   isFetched,
                                   error,
                                   openShowDocumentPage,
                                   openDocumentQrCodeDialog,
                                   openEditDocumentDialog,
                                   openDeleteDocumentDialog,
                                   refreshFolders
                               }) {

    const dispatch = useDispatch();

    const {user} = useSelector(state => state.auth);

    const {
        startSigns,
        documentsDownloads,
        signedDocumentsDownloads,
        auditsDownloads,
        folderSignatureTypes,
        folderTypesExt
    } = useSelector(
        (state) => ({
            startSigns: state.documents.startSigns,
            documentsDownloads: state.documents.downloads,
            signedDocumentsDownloads: state.signedDocuments.downloads,
            auditsDownloads: state.audits.downloads,
            folderSignatureTypes: state.folders.folderSignatureTypes,
            folderTypesExt: state.configurations.folderTypesExt,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (documents && documents.length > 0) {
            let folderIds = [];
            for (let i = 0; i < documents.length; i++) {
                const currentDocument = documents[i];
                const currentFolderId = currentDocument.folder.id;

                if (!folderIds.includes(currentFolderId)) {
                    folderIds.push(currentFolderId);
                }
            }
            for (let j = 0; j < folderIds.length; j++) {
                const currentFolderId = folderIds[j];
                dispatch(readFolderSignatureType(currentFolderId, false));
            }
        }
    }, [documents, dispatch]);

    return (
        <div>
            {isLoading &&
                <LoadingMessage isLoading={true} className={"text-center"}/>
            }
            {isError &&
                <ErrorAlert error={error}/>
            }

            {isFetched &&
                <>
                    {documents.length === 0 &&
                        <div>
                            <FormattedMessage id={'GENERIC.NO_RECORDS_FOUND'}/>
                        </div>
                    }
                    {documents.length > 0 &&
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.ID.LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.TYPE.LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.FILENAME.LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.SIGTYPE.LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.ARCHIVABLE.SHORT_LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.FILESIZE.SHORT_LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.SIGNURL.LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.SIGNABLE.LABEL"}/>
                                </th>
                                {/*<th>*/}
                                {/*    <FormattedMessage id={"DOCUMENTS.FIELDS.SIGNEDDOCUMENTID.LABEL"}/>*/}
                                {/*</th>*/}
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.LASTMODIFIEDDATE.LABEL"}/>
                                </th>
                                <th>
                                    <FormattedMessage id={"DOCUMENTS.FIELDS.STATUS.LABEL"}/>
                                </th>

                                <th style={{minWidth: '140px'}}>
                                    <FormattedMessage id={"GENERIC.ACTIONS"}/>
                                </th>


                            </tr>
                            </thead>
                            <tbody>
                            {documents.map((document) => {
                                    const documentIsDownloading = documentsDownloads[document.id]?.state === STATE_FETCHING;
                                    const documentIsDownloaded = documentsDownloads[document.id]?.state === STATE_FETCHED;
                                    const documentDownloadHasError = documentsDownloads[document.id]?.state === STATE_ERROR;

                                    const signedDocumentIsDownloading = signedDocumentsDownloads[document.signedDocumentId]?.state === STATE_FETCHING;
                                    const signedDocumentIsDownloaded = signedDocumentsDownloads[document.signedDocumentId]?.state === STATE_FETCHED;
                                    const signedDocumentDownloadHasError = signedDocumentsDownloads[document.signedDocumentId]?.state === STATE_ERROR;

                                    const auditIsDownloading = auditsDownloads[document.signedDocumentId]?.state === STATE_FETCHING;
                                    const auditIsDownloaded = auditsDownloads[document.signedDocumentId]?.state === STATE_FETCHED;
                                    const auditDownloadHasError = auditsDownloads[document.signedDocumentId]?.state === STATE_ERROR;

                                    const isStartSignLoading = startSigns[document.id]?.state === STATE_FETCHING;
                                    const isStartSignDone = startSigns[document.id]?.state === STATE_FETCHED;
                                    const startSignHasError = startSigns[document.id]?.state === STATE_ERROR;

                                    const signatureType = folderSignatureTypes[document.folder.id]?.data;

                                    return (
                                        <tr key={document.id}>
                                            <td>
                                                {document.id}
                                            </td>
                                            <td>
                                                {getDocumentLabelFromType(document.type, document.folder.type, folderTypesExt)}
                                            </td>
                                            <td>
                                                {document.fileName}
                                            </td>
                                            <td>
                                                {document.sigType &&
                                                    <FormattedMessage
                                                        id={getDocumentSigTypeIntlIdFromValue(document.sigType)}/>
                                                }
                                            </td>
                                            <td>
                                                {document.archivable && <span>Si</span>}
                                            </td>
                                            <td>
                                                {document.fileSize !== null ? formatBytes(document.fileSize) : ''}
                                            </td>
                                            <td>
                                                {document.signUrl &&
                                                    <FontAwesomeIconButton
                                                        id={"document-sign-url-tooltip"}
                                                        message={<FormattedMessage
                                                            id={"DOCUMENTS.LIST.BUTTONS.SIGNURL_BTN"}/>}
                                                        icon={faExternalLinkAlt}
                                                        target={"_blank"}
                                                        href={document.signUrl}
                                                        type={"info"}
                                                    />}
                                            </td>
                                            <td>
                                                {document.signable && <span>Si</span>}
                                            </td>
                                            {/*<td>*/}
                                            {/*    {document.signedDocumentId}*/}
                                            {/*</td>*/}
                                            <td>
                                                <FormattedDateTime
                                                    value={convertDatetimeFromApi(document.lastModifiedDate)}/>
                                            </td>
                                            <td className={"text-nowrap"}>

                                                <FormattedMessage id={getDocumentStatusIntlIdFromValue(document.status)}/>
                                                {documentIsBlockedByFeaDocument(document, signatureType) &&

                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id={"fea-blocked"}>
                                                                <FormattedMessage
                                                                    id={"DOCUMENTS.LIST.TEXTS.BLOCKED_BY_FEA"}/>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faExclamationTriangle}
                                                                         className={"text-danger ml-2"}/>
                                                    </OverlayTrigger>

                                                }


                                            </td>
                                            <td className={"text-right"}>

                                                <FontAwesomeIconButton
                                                    id={"document-start-sign-tooltip"}
                                                    message={<FormattedMessage id={"DOCUMENTS.LIST.BUTTONS.SHOW"}/>}
                                                    icon={faBinoculars}
                                                    onClick={() => openShowDocumentPage(document.id)}
                                                    // type={"danger"}

                                                />

                                                {showSignedDocumentDownloadButton(document) ?
                                                    <FontAwesomeIconButton
                                                        id={"document-download-signed-document-tooltip"}
                                                        message={<FormattedMessage
                                                            id={"DOCUMENTS.LIST.BUTTONS.DOWNLOAD_SIGNED_DOCUMENT"}/>}
                                                        icon={faFileDownload}
                                                        onClick={() => dispatch(signedDocumentsActions.downloadSignedDocument(document.signedDocumentId))}
                                                        type={"warning"}
                                                        linkClasses={"ml-1"}
                                                        loading={signedDocumentIsDownloading}
                                                        error={signedDocumentDownloadHasError}
                                                        done={signedDocumentIsDownloaded}
                                                    /> :
                                                    <FontAwesomeIconButton
                                                        id={"document-download-tooltip"}
                                                        message={<FormattedMessage id={"DOCUMENTS.LIST.BUTTONS.DOWNLOAD"}/>}
                                                        icon={faFileDownload}
                                                        onClick={() => dispatch(documentsActions.downloadDocument(document.id))}
                                                        type={"info"}
                                                        linkClasses={"ml-1"}
                                                        loading={documentIsDownloading}
                                                        error={documentDownloadHasError}
                                                        done={documentIsDownloaded}
                                                    />
                                                }

                                                {showSignedDocumentDownloadAuditButton(document) &&
                                                    <FontAwesomeIconButton
                                                        id={"document-download-audit-tooltip"}
                                                        message={<FormattedMessage
                                                            id={"DOCUMENTS.LIST.BUTTONS.DOWNLOAD_AUDIT"}/>}
                                                        icon={faFileDownload}
                                                        onClick={() => dispatch(auditsActions.downloadAudit(document.signedDocumentId))}
                                                        type={"success"}
                                                        linkClasses={"ml-1"}
                                                        loading={auditIsDownloading}
                                                        error={auditDownloadHasError}
                                                        done={auditIsDownloaded}
                                                    />
                                                }

                                                {userCanUpdateDocument(user, document.folder, document) &&
                                                    <FontAwesomeIconButton
                                                        id={"document-start-sign-tooltip"}
                                                        message={<FormattedMessage id={"DOCUMENTS.LIST.BUTTONS.EDIT"}/>}
                                                        icon={faEdit}
                                                        onClick={() => openEditDocumentDialog(document.id)}
                                                        linkClasses={"ml-1"}

                                                    />}

                                                {userCanDoStartSign(user, document.folder, document, signatureType) &&
                                                    <FontAwesomeIconButton
                                                        id={"document-start-sign-tooltip"}
                                                        message={<FormattedMessage
                                                            id={"DOCUMENTS.LIST.BUTTONS.START_SIGN"}/>}
                                                        icon={faFileSignature}
                                                        onClick={() => dispatch(documentsActions.startSignDocument(document.id))}
                                                        linkClasses={"ml-1"}
                                                        loading={isStartSignLoading}
                                                        error={startSignHasError}
                                                        done={isStartSignDone}

                                                    />}

                                                {userCanSeeSignQrCode(user, document.folder, document, signatureType) &&
                                                    <FontAwesomeIconButton
                                                        id={"document-qr-code-tooltip"}
                                                        message={<FormattedMessage id={"DOCUMENTS.LIST.BUTTONS.QR_CODE"}/>}
                                                        icon={faQrcode}
                                                        onClick={() => openDocumentQrCodeDialog(document.id)}
                                                        type={"info"}
                                                        linkClasses={"ml-1"}
                                                    />}

                                                {userCanDeleteDocument(user, document.folder, document) &&
                                                    <FontAwesomeIconButton
                                                        id={"document-qr-code-tooltip"}
                                                        message={<FormattedMessage id={"DOCUMENTS.LIST.BUTTONS.DELETE"}/>}
                                                        icon={faTrash}
                                                        onClick={() => openDeleteDocumentDialog(document.id)}
                                                        type={"danger"}
                                                        linkClasses={"ml-1"}
                                                    />}

                                            </td>

                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </Table>
                    }
                </>
            }
        </div>

    );
}
