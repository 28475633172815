import axios from "axios";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "/api/account";

//export const TOKEN_URL = "http://192.168.1.46:8085/sso/token";

const {REACT_APP_API_URL} = process.env;

export function login(email, password) {
    return axios.post(LOGIN_URL, {email, password});
}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, {email, fullname, username, password});
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, {email});
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(REACT_APP_API_URL + ME_URL);
}

/*
export function getToken(sessionId) {
    return axios.get(TOKEN_URL, {withCredentials: true});
}
*/
