/* Pagination Helpers */


import React from "react";
import {FormattedMessage} from "react-intl";

export function getHandlerTableChange(setQueryParams) {
    return (type, {page, sizePerPage, sortField, sortOrder, filters, data}) => {
        const pageNumber = page || 1;
        setQueryParams((prev) =>
            type === "sort"
                ? {...prev, sortOrder, sortField}
                : type === "pagination"
                    ? {...prev, pageNumber, pageSize: sizePerPage}
                    : type === "filter"
                        ? {...prev, filters: filters, pageNumber: 1}
                        : prev
        );
    };
}

export function NoDataIndication() {
    return (
        <>
            <div className={"text-center"}>
                <FormattedMessage id={"GENERIC.NO_RECORDS_FOUND"}/>
            </div>
        </>
    );
}
