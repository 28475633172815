const CHARTS_COLORS = {
    g: {
        light: "#c9f7f5",
        dark: "#1bc5bd",
    },
    ok: {
        light: "#e1f0ff",
        dark: "#3699ff",
    },
    warning: {
        light: "#fff4de",
        dark: "#ffa800",
    },
    alert: {
        light: "#ffe2e5",
        dark: "#f64e60",
    },
};

const FOLDER_TYPES_COLORS = {
    "SERVICE": "#ec5151",
    "PRIVACY": "#34c62f",
    "SALE_NEW": "#2459e9",
    "SALE_USED": "#ef9a48",
    "VEHICLE_EXIT_REQUEST": "#48CDF0",
    "GENERIC": "#9c17cd",
};

export function getChartLightColor(type = "ok") {
    return CHARTS_COLORS[type].light;
}

export function getChartDarkColor(type = "ok") {
    return CHARTS_COLORS[type].dark;
}

export function getFolderTypeColor(folderType) {
    return FOLDER_TYPES_COLORS[folderType] ?? '#000000';
}