import {
    documentRequireFea,
    dossierFeaIsSigned,
    dossierHasFeaDocument,
    userHasWritePermissionOverBrandAndFolderType
} from "./PermissionsHelpers";
import * as Yup from "yup";
import {documentTypeHasFieldRequired, getAllExtraFields} from "./DocumentTypesHelpers";

export function userCanCreateDocument(user, folder) {
    if (folder) {
        return userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type);
    }
    return false;
}

export function showSignedDocumentDownloadButton(document) {
    if (document) {
        //return (document.signable && document.signedDocumentId);
        return (document.signedDocumentId);
    }
    return false;
}

export function showSignedDocumentDownloadAuditButton(document) {
    if (document.status === 'SIGNED' && document.sigType === 'OTP') {
        return (document.signedDocumentId);
    }
    return false;
}

export function userCanUpdateDocument(user, folder, document) {
    if (document && folder && user) {
        return (document.status === 'DRAFT') && userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type);
    }
    return false;
}

export function documentIsBlockedByFeaDocument(document, signatureType) {
    let result = false;

    if (documentRequireFea(document, signatureType)) {
        if (dossierHasFeaDocument(signatureType)) {
            if (!dossierFeaIsSigned(signatureType)) {
                result = true
            }
        } else {
            result = true;
        }
    }
    return result;
}

export function userCanDoStartSign(user, folder, document, signatureType) {
    if (document && folder && user && signatureType) {
        return (!documentIsBlockedByFeaDocument(document, signatureType)) && (document.signable && document.status === 'READY_TO_SIGN' && document.sigType === 'OTP') && userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type);
    }
    return false;
}

export function userCanSeeSignQrCode(user, folder, document, signatureType) {
    if (document && folder && user && signatureType) {
        return (!documentIsBlockedByFeaDocument(document, signatureType)) && (document.signable && document.status === 'READY_TO_SIGN' && document.sigType === 'GRAPHOMETRIC' && document.mimeType === 'application/pdf') && userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type);
    }
    return false;
}

export function userCanDeleteDocument(user, folder, document) {
    if (document && folder && user) {
        return (document.status === 'DRAFT' || document.status === 'FINISH') && userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type);
    }
    return false;
}

export function getDocumentsValidationSchema(isEdit, folderType, folderTypesExt, namePrefix = "",) {
    //TODO: verify archivable and signable
    let yupFields = {
        type: Yup.string().max(255).required(),
        archivable: Yup.boolean().when(`${namePrefix}type`, (type, schema) => {
            return schema;
        }),
        signable: Yup.boolean().when(`${namePrefix}type`, (type, schema) => {
            return schema;
        }),
        sigType: Yup.string().max(255).ensure().when(`${namePrefix}type`, (type, schema) => {
            return documentTypeHasFieldRequired(type, folderType, folderTypesExt, "SIG_TYPE") ? schema.required() : schema;
        }),
    };

    const extraFields = getAllExtraFields(folderTypesExt);

    for (const fieldKey in extraFields) {
        const field = extraFields[fieldKey];

        switch (field.type) {
            case 'string':
                yupFields["variableFields_" + field.name] = Yup.string().max(255).ensure().when(`${namePrefix}type`, (type, schema) => {
                    return documentTypeHasFieldRequired(type, folderType, folderTypesExt, field.name) ? schema.required() : schema;
                });


                break;
            case 'checkbox':
                yupFields["variableFields_" + field.name] = Yup.boolean().when(`${namePrefix}type`, (type, schema) => {
                    return schema;
                });
                break;
            case 'date':
                yupFields["variableFields_" + field.name] = Yup.date().when(`${namePrefix}type`, (type, schema) => {
                    return documentTypeHasFieldRequired(type, folderType, folderTypesExt, field.name) ? schema.required() : schema.nullable();
                });
                break;
            default:
                break;
        }
    }


    /*
    const extraFields = getAllExtraFields(folderTypesExt);


    let variableFields = {};
    for (const fieldKey in extraFields) {
        const field = extraFields[fieldKey];

        console.log('$' + `${namePrefix}type`);
        switch (field.type) {
            case 'string':
                variableFields[field.name] = Yup.string().max(255).ensure().when('$' + `${namePrefix}type`, (type, schema) => {
                    console.log('this', this);
                    console.log('type', type);
                    console.log('schema', schema);

                    console.log('required', field.name, type, documentTypeHasFieldRequired(type, folderType, folderTypesExt, field.name))
                    return documentTypeHasFieldRequired(type, folderType, folderTypesExt, field.name) ? schema.required() : schema;
                });


                break;
            case 'checkbox':
                variableFields[field.name] = Yup.boolean().when(`${namePrefix}type`, (type, schema) => {
                    return schema;
                });
                break;
            case 'date':
                variableFields[field.name] = Yup.date().when(`${namePrefix}type`, (type, schema) => {
                    return documentTypeHasFieldRequired(type, folderType, folderTypesExt, field.name) ? schema.required() : schema.nullable();
                });
                break;
        }

    }

    yupFields["variableFields"] = Yup.object().shape(variableFields);
     */


    //TODO: add extra fields

    if (!isEdit) {

        yupFields["file"] = Yup.array().ensure().when([`${namePrefix}type`, `${namePrefix}signable`], (type, signable, schema) => {
            if (documentTypeHasFieldRequired(type, folderType, folderTypesExt, "FILE")) {

                if (signable) {
                    return schema.min(1).max(1).test(`${namePrefix}file`, 'File accettati: PDF', (value) => {
                        if (value && value.length > 0) {
                            return ['application/pdf'].includes(value[0].type);
                        }
                    })
                } else {

                    return schema.min(1).max(1).test(`${namePrefix}file[0].fileFormat`, 'File accettati: immagini, Pdf, documenti di testo', (value) => {
                        if (value && value.length > 0) {
                            return [
                                "application/pdf",//pdf
                                "image/jpeg",//jpeg
                                "image/png",//png
                                "image/tiff",//tiff
                                "application/msword",//doc
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"//docx
                            ].includes(value[0].type);
                        }
                    })
                }
            } else {
                return schema.min(0).max(0)
            }
        });
    }

    return Yup.object().shape(yupFields);
}
