import axios from "axios";
import {getFiltersFromQueryParams} from "../../../../../_intesys/_helpers";

export const FOLDERS_URL = "/api/folders";
export const FOLDERS_EXT_URL = "/api/folders-ext";
const {REACT_APP_API_URL} = process.env;

export function searchFolders(data) {
    return axios.post(`${REACT_APP_API_URL}${FOLDERS_URL}/search?page=${data.page}&size=${data.size}&sort=${data.sort}`, data);
}

export function searchFoldersExt(data) {
    return axios.post(`${REACT_APP_API_URL}${FOLDERS_EXT_URL}/search?page=${data.page}&size=${data.size}&sort=${data.sort}`, data);
}

export function createFolder(folder) {
    return axios.post(`${REACT_APP_API_URL}${FOLDERS_URL}`, folder);
}

export function updateFolder(folder) {
    return axios.put(`${REACT_APP_API_URL}${FOLDERS_URL}`, folder);
}

export function countFolders(queryParams) {
    const params = getFiltersFromQueryParams(queryParams);
    return axios.get(`${REACT_APP_API_URL}${FOLDERS_URL}/count`, {params});
}

export function getFolderById(folderId) {
    return axios.get(`${REACT_APP_API_URL}${FOLDERS_URL}/${folderId}`);
}

export function deleteFolder(folderId) {
    return axios.delete(`${REACT_APP_API_URL}${FOLDERS_URL}/${folderId}`);
}

export function getFolderFea(folderId) {
    return axios.get(`${REACT_APP_API_URL}${FOLDERS_EXT_URL}/${folderId}/fea`);
}
