/* Api Filters Helpers */
import {Comparator, FILTER_TYPES} from "react-bootstrap-table2-filter";
import {formatDayJSForApi, getDayJsItem} from "./ApiDatetimeHelpers";

export function getFiltersFromQueryParams(queryParams, type = "generic") {

    let params = {
        page: queryParams.pageNumber - 1,
        size: queryParams.pageSize,
        sort: queryParams.sortField + "," + queryParams.sortOrder
    };

    if (queryParams.filter) {
        if (queryParams.filter.generic) {
            params.genericFilter = {
                contains: queryParams.filter.generic
            };
        }
        if (queryParams.filter.createdBy) {
            params.createdBy = {
                equals: queryParams.filter.createdBy
            };
        }
    }

    if (queryParams.filters) {
        for (const [key, value] of Object.entries(queryParams.filters)) {
            let parameterName = key;
            let apiOperator = "";
            let apiValue = "";
            //value.caseSensitive
            switch (value.filterType) {
                case FILTER_TYPES.TEXT: {
                    apiValue = value.filterVal;
                    if (value.comparator === Comparator.LIKE) {
                        apiOperator = "contains";
                    }
                    if (value.comparator === Comparator.EQ) {
                        apiOperator = "equals";
                    }
                    break;
                }
                case FILTER_TYPES.SELECT: {
                    apiValue = value.filterVal;
                    if (value.comparator === Comparator.LIKE) {
                        apiOperator = "contains";
                    }
                    /*
                    if (value.comparator === "EQ") {
                        apiOperator = "equals";
                    }
                    */
                    if (value.comparator === Comparator.EQ) {
                        apiOperator = "equals";
                    }
                    break;
                }
                case FILTER_TYPES.MULTISELECT: {
                    apiValue = value.filterVal;
                    if (value.comparator === Comparator.LIKE) {
                        apiOperator = "in";
                    }
                    if (value.comparator === Comparator.EQ) {
                        apiOperator = "in";
                    }
                    break;
                }
                case FILTER_TYPES.NUMBER: {
                    apiValue = value.filterVal.number;
                    // if (apiValue) {
                    //     apiValue = apiValue;
                    // }
                    apiValue = parseInt(apiValue);

                    switch (value.filterVal.comparator) {

                        case Comparator.EQ:
                            apiOperator = "equals";
                            break;
                        case Comparator.NE:
                            apiOperator = "notEquals";
                            break;
                        case Comparator.GT:
                            apiOperator = "greaterThan";
                            break;
                        case Comparator.GE:
                            apiOperator = "greaterThanOrEqual";
                            break;
                        case Comparator.LT:
                            apiOperator = "lessThan";
                            break;
                        case Comparator.LE:
                            apiOperator = "lessThanOrEqual";
                            break;
                        default:
                            break;
                    }
                    break;
                }
                case FILTER_TYPES.DATE: {
                    apiValue = value.filterVal.date;

                    let dayJs = null;
                    if (apiValue) {
                        dayJs = getDayJsItem(apiValue);
                        dayJs.hour(0).minute(0).second(0).millisecond(0);

                        switch (value.filterVal.comparator) {

                            case Comparator.EQ:
                                const fromDate = dayJs.add(1, "day");//return a cloned day.js object

                                if (!params[parameterName]) {
                                    params[parameterName] = {};
                                }
                                params[parameterName]["lessThan"] = formatDayJSForApi(fromDate);
                                apiOperator = "greaterThanOrEqual";
                                break;
                            case Comparator.NE:
                                //TODO: maybe try to find a way to implement
                                //apiOperator = "notEquals";
                                apiOperator = "";
                                console.error("notEquals comparator not managed by api");
                                break;
                            case Comparator.GT:
                                apiOperator = "greaterThanOrEqual";
                                if (dayJs) {
                                    dayJs = dayJs.add(1, "day");
                                }
                                break;
                            case Comparator.GE:
                                apiOperator = "greaterThanOrEqual";
                                break;
                            case Comparator.LT:
                                apiOperator = "lessThanOrEqual";

                                break;
                            case Comparator.LE:
                                apiOperator = "lessThanOrEqual";
                                if (dayJs) {
                                    dayJs = dayJs.add(1, "day");
                                }
                                break;
                            default:
                                break;
                        }

                        apiValue = formatDayJSForApi(dayJs);
                    }


                    break;
                }
                default:
                    break;
            }

            //filter is authority instead sort is authorities
            if (parameterName === "authorities") {
                parameterName = "authority";
            }

            if (apiOperator && apiValue) {
                if (!params[parameterName]) {
                    params[parameterName] = {};
                }
                params[parameterName][apiOperator] = apiValue;

                /*
                if (Array.isArray(apiValue)){

                    apiValue.forEach(value =>{
                        params[parameterName + "." + apiOperator] = apiValue;
                        }
                    );

                }else{
                    params[parameterName + "." + apiOperator] = apiValue;
                }

                 */
            }
        }
    }
    return params;
}

/*
export function searchDealersFromName(searchText) {
    const dealers = [];

    return dealers.filter((dealer) => {
        const value = dealer.name.toString().trim().toLowerCase();
        return (value.indexOf(searchText) > -1)
    });
}

export function getDealersHstFromName(searchText) {
    const filteredDealers = searchDealersFromName(searchText);
    filteredDealers.map((dealer) => dealer.hst2011);
}

 */