import {userIsAdmin} from "./PermissionsHelpers";

export const serviceTypes = [
    {
        "serviceType": "DISK_SPACE",
        "label": "Spazio archiviazione cloud",
        "fields": [
            {
                "field": "EXPIRES_AT",
                "required": true
            },
            {
                "field": "TOTAL_SPACE",
                "required": true
            },
            {
                "field": "NOTES",
                "required": false
            }
        ]
    },
    {
        "serviceType": "STRONGDOX_SPACE",
        "label": "Spazio archiviazione sostitutiva LegalSolutionDOC",
        "fields": [
            {
                "field": "EXPIRES_AT",
                "required": true
            },
            {
                "field": "TOTAL_SPACE",
                "required": true
            },
            {
                "field": "NOTES",
                "required": false
            }
        ],
        "signatureTypes": [
            {
                "type": "FEA",
                "label": "Firma Elettronica Avanzata"
            },
            {
                "type": "FES",
                "label": "Firma Elettronica Semplice"
            },
        ]
    },
    {
        "serviceType": "OTP",
        "label": "Numero OTP",
        "fields": [
            {
                "field": "TOTAL_OTP",
                "required": true
            },
            {
                "field": "NOTES",
                "required": false
            }
        ]
    },
    {
        "serviceType": "SIGNATURE_TYPE",
        "label": "Tipologia firma (FES/FEA)",
        "fields": [
            {
                "field": "EXPIRES_AT",
                "required": true
            },
            {
                "field": "SIGNATURE_TYPE",
                "required": true
            },
            {
                "field": "NOTES",
                "required": false
            }
        ],
        "signatureTypes": [
            {
                "type": "FEA",
                "label": "Firma Elettronica Avanzata"
            },
            {
                "type": "FES",
                "label": "Firma Elettronica Semplice"
            },
        ]
    },
    {
        "serviceType": "TPIM",
        "label": "Integrazione terze parti",
        "fields": [
            {
                "field": "EXPIRES_AT",
                "required": true
            },
        ]
    },
    {
        "serviceType": "SIGNATURE_NOTIFICATION",
        "label": "Notifica firma",
        "fields": [
            {
                "field": "EXPIRES_AT",
                "required": true
            },
            {
                "field": "SIGNATURE_TYPE",
                "required": true
            },
        ]
    },
];

export const folderTypeSets = [
    {
        type: 'PRIVACY',
        label: 'Privacy'
    },
    {
        type: 'SALE_NEW',
        label: 'Vendita Nuovo'
    },
    {
        type: 'SALE_USED',
        label: 'Vendita Usato'
    },
    {
        type: 'SERVICE',
        label: 'Service'
    },
    {
        type: 'VEHICLE_EXIT_REQUEST',
        label: 'Richiesta Uscita Veicolo'
    }
]

export function userCanShowService(user, service) {
    if (service && user) {
        return userIsAdmin(user);
    }
    return false;
}

export function userCanCreateService(user) {
    if (user) {
        return userIsAdmin(user);
    }
    return false;
}

export function userCanUpdateService(user, service) {
    if (service && user) {
        return userIsAdmin(user);
    }
    return false;
}


export function userCanDeleteService(user, service) {
    if (service && user) {
        return userIsAdmin(user);
    }
    return false;
}

export function serviceTypeHasExpireDateField(serviceType) {
    return serviceType === 'DISK_SPACE' || serviceType === 'STRONGDOX_SPACE' || serviceType === 'SIGNATURE_TYPE' || serviceType === 'SIGNATURE_NOTIFICATION' || serviceType === 'TPIM';
}

export function serviceTypeHasTotalSpaceField(serviceType) {
    return serviceType === 'DISK_SPACE' || serviceType === 'STRONGDOX_SPACE';
}

export function serviceTypeHasOtpField(serviceType) {
    return serviceType === 'OTP';
}

export function serviceTypeHasSignatureTypeField(serviceType) {
    return serviceType === 'SIGNATURE_TYPE';
}

export function serviceTypeHasFolderTypeSetField(serviceType) {
    return serviceType === 'SIGNATURE_TYPE' || serviceType === 'SIGNATURE_NOTIFICATION';
}

export function getSizeInMb(value) {
    return value / 1048576;
}

export function getSizeInB(value) {
    return value * 1048576;
}