import * as requestFromServer from "./dossiersCrud";
import {dossiersSlice} from "./dossiersSlice";
import {
    callTypes,
    convertDateFromItalianToIso,
    convertDateFromIsoToItalian,
    getFiltersFromQueryParams
} from "../../../../../_intesys/_helpers";
import CPromise from "c-promise2";

const {actions} = dossiersSlice

const fixDossierDataForApp = (dossier) => {
    dossier.birthDate = convertDateFromItalianToIso(dossier.birthDate);
    return dossier;
}

const fixDossierDataForApi = (dossier) => {
    const result = {...dossier};
    result.birthDate = convertDateFromIsoToItalian(result.birthDate);
    return result;
}

let fetchDossiersChain = null;

export const fetchDossiers = (queryParams) => dispatch => {
    const callType = callTypes.list;

    fetchDossiersChain && fetchDossiersChain.cancel();

    dispatch(actions.startCall({callType}));

    fetchDossiersChain = new CPromise((resolve, reject, {onCancel}) => {
        const params = getFiltersFromQueryParams(queryParams);
        params.status = {
            equals: "OPEN"
        };
        requestFromServer
            .searchDossiers(params)
            .then(resolve, reject);
    }).then(response => {
        const totalCount = +response.headers["x-total-count"];
        const entities = response.data;
        dispatch(actions.itemsFetched({totalCount, entities}));
    }).catch(error => {
        if (!CPromise.isCanceledError(error)) {
            dispatch(actions.catchError({error, callType}));
        }
    });
};

export const fetchDossier = id => dispatch => {
    const callType = callTypes.fetch;
    if (!id) {
        return dispatch(actions.itemFetched({item: undefined}));
    }

    dispatch(actions.startCall({callType}));
    return requestFromServer
        .getDossierById(id)
        .then(response => {
            let dossier = response.data;

            const dossierFetched = fixDossierDataForApp(dossier);

            dispatch(actions.itemFetched({item: dossierFetched}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const deleteDossier = id => dispatch => {
    const callType = callTypes.delete;
    dispatch(actions.startCall({callType}));
    return requestFromServer
        .deleteDossier(id)
        .then(response => {
            dispatch(actions.itemDeleted({id}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const createDossier = dossier => dispatch => {
    const callType = callTypes.create;
    dispatch(actions.startCall({callType}));

    let dossierToCreate = fixDossierDataForApi({...dossier});

    return requestFromServer
        .createDossier(dossierToCreate)
        .then(response => {
            const dossier = response.data;

            const dossierInserted = fixDossierDataForApp(dossier);

            dispatch(actions.itemCreated({item: dossierInserted}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const updateDossier = dossier => dispatch => {
    const callType = callTypes.update;
    dispatch(actions.startCall({callType}));
    let dossierToUpdate = fixDossierDataForApi({...dossier});

    return requestFromServer
        .updateDossier(dossierToUpdate)
        .then((response) => {
            const dossier = response.data;

            const dossierUpdated = fixDossierDataForApp(dossier);

            dispatch(actions.itemUpdated({item: dossierUpdated}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const readRspSynchronizationStatus = legalEntity => dispatch => {
    const key = legalEntity;
    dispatch(actions.getRspSynchronizationStatusStart({key}));

    return requestFromServer
        .getRspSynchronizationStatus(legalEntity)
        .then(response => {
            const data = response.data;
            dispatch(actions.getRspSynchronizationStatusDone({data, key}));
        })
        .catch(error => {
            dispatch(actions.getRspSynchronizationStatusError({error, key}));
        });
};

export const scheduleRspSynchronization = legalEntity => dispatch => {
    const key = legalEntity;
    dispatch(actions.scheduleRspSynchronizationStart({key}));

    return requestFromServer
        .scheduleRspSynchronization(legalEntity)
        .then(response => {
            // empty response
            const data = response.data;
            dispatch(actions.scheduleRspSynchronizationDone({data, key}));
            dispatch(readRspSynchronizationStatus(legalEntity));
        })
        .catch(error => {
            dispatch(actions.scheduleRspSynchronizationError({error, key}));
        });
};
