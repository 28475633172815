import React, {useEffect} from "react";
import {Switch, Route, useLocation} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {Layout} from "../_intesys/layout";
import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Logged from "./modules/Auth/pages/Logged";
import Logout from "./modules/Auth/pages/Logout";

import jwt from 'jsonwebtoken';

export default function Routes(props) {

    const location = useLocation();

    const {getDealersError} = useSelector(
        ({dealers}) => ({
            getDealersError: dealers.error,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (getDealersError) {
            if (!location.pathname.includes('/error')) {
                window.location.href = '/error/error-dealer';
            }
        }
    }, [getDealersError, location.pathname]);

    const {isAuthorized, jwtToken} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
            jwtToken: auth.authToken
        }),
        shallowEqual
    );

    useEffect(() => {
        const checkJwtToken = () => {
            if (jwtToken) {
                const decodedToken = jwt.decode(jwtToken);
                const currentTime = Date.now() / 1000;
                const isExpired = decodedToken.exp < currentTime;
                if (isExpired) {
                    window.location.href = '/logout';
                }
            }
        }

        checkJwtToken();
        setInterval(() => {
            checkJwtToken();
        }, 1000 * 60 * 2);//2 minutes
    }, [jwtToken]);

    useEffect(() => {
        if (!isAuthorized && (location.pathname !== '/logged' && location.pathname !== '/logout' && !location.pathname.includes('/error'))) {
            const {REACT_APP_API_URL, REACT_APP_DOMAIN_URL} = process.env;
            window.location.href = REACT_APP_API_URL + "/sso/auth/?RelayState=" + REACT_APP_DOMAIN_URL + "/logged?redirect=" + location.pathname + location.search;
        }
    }, [isAuthorized, location.pathname, location.search]);

    return (
        <Switch>
            <Route path="/logged" component={Logged}/>
            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>
            <Layout>
                <BasePage/>
            </Layout>
        </Switch>
    );
}
