import axios from "axios";

const {REACT_APP_API_URL} = process.env;

export function getFolderTypesExtV2() {
    return axios.get(`${REACT_APP_API_URL}/api/v2/folder-types/ext`);
}

export function getSignatureTypeServices() {
    return axios.get(`${REACT_APP_API_URL}/api/intesys-services?serviceType.equals=SIGNATURE_TYPE`);
}
