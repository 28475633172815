import * as requestFromServer from "./foldersCrud";
import {foldersSlice} from "./foldersSlice";
import {
    callTypes,
    convertDatetimeForApi,
    convertDatetimeFromApi,
    getFiltersFromQueryParams
} from "../../../../../_intesys/_helpers";
import CPromise from "c-promise2";
import store from "../../../../../redux/store";

const {actions} = foldersSlice;

const fixFolderDataForApp = (folder) => {
    folder.processDate = convertDatetimeFromApi(folder.processDate);
    return folder;
}
const fixFolderDataForApi = (folder) => {
    const result = {...folder};
    result.processDate = convertDatetimeForApi(result.processDate);
    return result;
}

let fetchFoldersChain = null;

export const fetchFolders = (queryParams, dossierId, brands) => dispatch => {
    const callType = callTypes.list;

    fetchFoldersChain && fetchFoldersChain.cancel();

    if (brands.length === 0) {
        return dispatch(actions.itemsFetched({totalCount: 0, entities: []}));
    }

    dispatch(actions.startCall({callType}));

    fetchFoldersChain = new CPromise((resolve, reject, {onCancel}) => {

        const params = getFiltersFromQueryParams(queryParams);

        if (dossierId) {
            params.dossierId = {
                equals: dossierId,
            };
        }

        params.status = {
            equals: "OPEN"
        }

        if (!params.brand) {
            params.brand = {
                in: brands
            }
        }

        //remove "dossierDTO." from filters
        for (const key of Object.keys(params)) {
            if (key.length > 11 && key.substring(0, 11) === "dossierDTO.") {
                const newKey = key.substring(11, key.length);
                params[newKey] = params[key];
                delete params[key];
            }
        }

        //remove "folderDTO." from filters
        for (const key of Object.keys(params)) {
            if (key.length > 11 && key.substring(0, 11) === "folderDTO.") {
                const newKey = key.substring(11, key.length);
                params[newKey] = params[key];
                delete params[key];
            }
        }

        requestFromServer
            .searchFoldersExt(params)
            .then(resolve, reject);
    }).then(response => {
        const totalCount = +response.headers["x-total-count"];
        const entities = response.data;
        dispatch(actions.itemsFetched({totalCount, entities}));
    }).catch(error => {
        if (!CPromise.isCanceledError(error)) {
            dispatch(actions.catchError({error, callType}));
        }
    });
}

export const fetchFolder = id => dispatch => {
    const callType = callTypes.fetch;
    if (!id) {
        return dispatch(actions.itemFetched({item: undefined}));
    }

    dispatch(actions.startCall({callType}));
    return requestFromServer
        .getFolderById(id)
        .then(response => {
            let folder = response.data;

            const folderFetched = fixFolderDataForApp(folder);

            dispatch(actions.itemFetched({item: folderFetched}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const deleteFolder = id => dispatch => {
    const callType = callTypes.delete;
    dispatch(actions.startCall({callType}));
    return requestFromServer
        .deleteFolder(id)
        .then(response => {
            dispatch(actions.itemDeleted({id}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const createFolder = folder => dispatch => {
    const callType = callTypes.create;
    dispatch(actions.startCall({callType}));

    const folderToCreate = fixFolderDataForApi(folder);

    return requestFromServer
        .createFolder(folderToCreate)
        .then(response => {
            const folder = response.data;
            const folderInserted = fixFolderDataForApp(folder);
            dispatch(actions.itemCreated({item: folderInserted}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const updateFolder = folder => dispatch => {
    const callType = callTypes.update;
    dispatch(actions.startCall({callType}));

    const folderToUpdate = fixFolderDataForApi(folder);

    return requestFromServer
        .updateFolder(folderToUpdate)
        .then((response) => {
            const folder = response.data;
            const folderUpdated = fixFolderDataForApp(folder);
            dispatch(actions.itemUpdated({item: folderUpdated}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const readFolderSignatureType = (folderId, reload = true) => dispatch => {
    const key = folderId;

    // no need to reload folderSignatureTypes for folder
    const state = store.getState();
    if (!reload) {
        if (state.folders.folderSignatureTypes[folderId]) {
            return;
        }
    }


    dispatch(actions.getFolderSignatureTypesStart({key}));
    return requestFromServer
        .getFolderFea(folderId)
        .then(response => {
            const data = response.data;
            dispatch(actions.getFolderSignatureTypesDone({data, key}));
        })
        .catch(error => {
            dispatch(actions.getFolderSignatureTypesError({error, key}));
        });
};


//
// export const countFolders = (queryParams) => dispatch => {
//     return requestFromServer
//         .countFolders(queryParams)
//         .then(response => {
//             dispatch(actions.folderUpdated({count: response.data}));
//         })
//         .catch(error => {
//             dispatch(actions.catchError({error, callType: callTypes.createOrUpdate}));
//         });
// };
