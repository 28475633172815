// BRANDS
import React from "react";
import {getFolderTypeExtFromFolderType} from "./FolderTypesHelpers";
import {READONLY_PERMISSION_ON_BRAND} from "./PermissionsHelpers";
import {getDocumentTypeExtFromDocumentType} from "./DocumentTypesHelpers";

export function getBrandIntlIdFromValue(value) {
    return "BRANDS." + value.toUpperCase();
}

// USERS - AUTHORITIES
export function getUserRoleIntlIdFromValue(value) {
    return "USERS.FIELDS.AUTHORITIES.VALUES." + value.toUpperCase();
}

// USERS - PERMISSIONS
export function getPermissionIntlIdFromValue(value) {
    return "USERS.FIELDS.PERMISSIONS.VALUES." + value.toUpperCase();
}

// USERS -  ACTIVATED
export function getUserActivatedIntlIdFromValue(activated) {
    return "USERS.FIELDS.ACTIVATED.VALUES." + (activated ? "ACTIVE" : "NOTACTIVE");
}

// LICENSES - ACTIVATIONS - STATUS
export function getActivationStatusIntlIdFromValue(value) {
    return "ACTIVATIONS.FIELDS.STATUS.VALUES." + value.toUpperCase();
}

// DOSSIERS - SEARCHTYPE
export function getDossierSearchTypeIntlIdFromValue(value) {
    return "DOSSIERS.FIELDS.SEARCHTYPE.VALUES." + value.toUpperCase();
}

// DOSSIERS - FOLDERS - TYPE
// export function getFolderTypeIntlIdFromValue(value) {
//     return "FOLDERS.FIELDS.TYPE.VALUES." + value;
// }

// DOSSIERS - FOLDERS - STATUS
export function getFolderStatusIntlIdFromValue(value) {
    return "FOLDERS.FIELDS.STATUS.VALUES." + value;
}

// DOSSIERS - FOLDERS - DOCUMENTS - SIGTYPE
export function getDocumentSigTypeIntlIdFromValue(value) {
    return "DOCUMENTS.FIELDS.SIGTYPE.VALUES." + value;
}

// DOSSIERS - FOLDERS - DOCUMENTS - STATUS
export function getDocumentStatusIntlIdFromValue(value) {
    return "DOCUMENTS.FIELDS.STATUS.VALUES." + value;
}

//DASHBOARD AND WIZARD

export function getDocumentTypeLabelForCreateButton(intl, brand, folderType, documentType, folderTypesExt) {
    const specificIntlId = `DASHBOARD.BUTTONS.CREATE_DOCUMENT.${folderType}.${documentType}`;
    let label = '';
    if (intl.messages[specificIntlId]) {
        label = intl.formatMessage({
            id: specificIntlId
        }, {
            b: chunks => (
                <b>{chunks}</b>
            )
        });
    } else {
        const documentTypeLabel = getDocumentLabelFromType(documentType, folderType, folderTypesExt);

        label = intl.formatMessage({
            id: "DASHBOARD.BUTTONS.CREATE_DOCUMENT.GENERIC",
        }, {
            documentType: documentTypeLabel,
        });
    }
    return <span>{label}</span>;
}

export function getFolderBusinessIdLabel(intl, folderType, type) {
    const specificIntlId = `FOLDERS.FIELDS.BUSINESSID.${folderType}.${type}`;

    let label = '';

    if (intl.messages[specificIntlId]) {
        label = intl.formatMessage({id: specificIntlId});
    } else {
        label = intl.formatMessage({id: `FOLDERS.FIELDS.BUSINESSID.${type}`});
    }

    return label;
}

export function getDocumentLabel(documentTypeExt) {
    let label = '';
    if (documentTypeExt) {
        label = documentTypeExt.description;
    }
    return label;
}

export function getDocumentLabelFromType(documentType, folderType, folderTypesExt) {
    const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt);
    return getDocumentLabel(documentTypeExt);
}

export function getFolderLabel(folderTypeExt) {
    let label = '';
    if (folderTypeExt) {
        label = folderTypeExt.description;
    }
    return label;
}

export function getFolderLabelFromType(folderType, folderTypesExt) {
    return getFolderLabel(getFolderTypeExtFromFolderType(folderType, folderTypesExt));
}

export function getPermissionLabel(intl, folderType, folderTypesExt) {
    if (folderType === READONLY_PERMISSION_ON_BRAND) {
        return intl.formatMessage({id: getPermissionIntlIdFromValue(folderType)});
    } else {
        const folderLabel = getFolderLabel(getFolderTypeExtFromFolderType(folderType, folderTypesExt));
        return intl.formatMessage({id: getPermissionIntlIdFromValue('GENERIC')}, {
            'label': folderLabel,
        });
    }
}