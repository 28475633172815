import {userCanManageAllBrands, userHasReadPermissionOverBrand, userHasWritePermissionOverBrand} from "./PermissionsHelpers";
import {getDealerBrandsFromHst} from "./DealersHelpers";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import React from "react";
import {getBrandIntlIdFromValue} from "./IntlHelpers";

export const BRAND_BMW = "bmw";
export const BRAND_BMW_I = "bmwi";
export const BRAND_MINI = "mini";
export const BRAND_BMW_MOTORRAD = "moto";

export const BRANDS_VALUES = [
    BRAND_BMW,
    BRAND_BMW_I,
    BRAND_MINI,
    BRAND_BMW_MOTORRAD
];

export const BRANDS_LOGOS = {
    bmw: "BMW_Grey_Logo.svg",
    bmwi: "BMW_i_Grey_RGB.svg",
    mini: "mini-logo.svg",
    moto: "bmw_motorrad_logo.svg",
};
export const BRANDS_LOGOS_WHITE = {
    bmw: "BMW_White_Logo.svg",
    bmwi: "BMW_i_White-Colour_RGB.svg",
    mini: "mini-logo-white.svg",
    moto: "bmw_motorrad_logo_white.svg",
};


export const BRANDS = [
    {
        value: BRAND_BMW,
        label: "BMW",
    },
    {
        value: BRAND_BMW_I,
        label: "BMW i",
    },
    {
        value: BRAND_MINI,
        label: "MINI",
    },
    {
        value: BRAND_BMW_MOTORRAD,
        label: "BMW Motorrad",
    },
];


export function getBrandLabel(brand) {
    return BRANDS.find((brandObject) => {
        return brandObject.value === brand;
    }).label;
}


export function getBrandLogoUrl(brand, white = false) {
    let logo = BRANDS_LOGOS[brand];
    if (white) {
        logo = BRANDS_LOGOS_WHITE[brand];
    }
    return toAbsoluteUrl(`/media/logos/brands/${logo}`);
}

export function getBrandLogo(brand, intl, width, height, white) {
    let style = {};
    if (width) {
        style.width = width;
    }
    if (height) {
        style.height = height;
    }
    return <img key={brand} src={getBrandLogoUrl(brand, white)} alt={intl.formatMessage({id: getBrandIntlIdFromValue(brand)})} style={style} className={"p-2"}/>

}

export function getUserReadBrands(user = null) {
    if (userCanManageAllBrands(user)) {
        return BRANDS_VALUES;
    } else {
        return BRANDS_VALUES.filter((brand) => (
            userHasReadPermissionOverBrand(user, brand)
        ));
    }
}

export function getUserWriteBrands(user = null) {
    if (userCanManageAllBrands(user)) {
        return BRANDS_VALUES;
    } else {
        return BRANDS_VALUES.filter((brand) =>
            userHasWritePermissionOverBrand(user, brand)
        );
    }
}

export function getUserWriteBrandsFilteredOnHst(dealers, hst, user = null) {
    if (dealers && hst && user) {
        const userWriteBrands = getUserWriteBrands(user);
        const hstBrands = getDealerBrandsFromHst(dealers, hst);
        return userWriteBrands.filter((value) => hstBrands.includes(value));
    } else {
        return [];
    }

}