import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_intesys/layout";
import {MyPage} from "./pages/MyPage";
import Dashboard from "./pages/dashboard/Dashboard";
import {useSelector} from "react-redux";
import {userCanSeeUsers, userIsAdmin} from "../_intesys/_helpers";

const AdminPage = lazy(() =>
    import("./modules/Admin/pages/adminPage")
);
const AppPage = lazy(() =>
    import("./modules/App/pages/AppPage")
);

export default function BasePage() {
    // useEffect(() => {
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const {user} = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={Dashboard}/>
                <ContentRoute path="/my-page" component={MyPage}/>

                {(userIsAdmin(user) || userCanSeeUsers(user)) &&
                    <Route path="/admin" component={AdminPage}/>
                }
                <Route path="/app" component={AppPage}/>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
