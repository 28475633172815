import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Route} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_intesys/layout";
import {actions} from "../_redux/authRedux";

export default function Logout() {
    const {hasAuthToken} = useSelector(
        (state) => ({
            hasAuthToken: Boolean(state.auth.authToken),
        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    const {REACT_APP_LOGOUT_URL, REACT_APP_DOMAIN_URL} = process.env;
    const logoutUrl = REACT_APP_LOGOUT_URL + REACT_APP_DOMAIN_URL;

    useEffect(() => {
        dispatch(actions.logout());
    }, [dispatch]);

    return hasAuthToken ? <LayoutSplashScreen/> : <Route render={() => (window.location.href = `${logoutUrl}`)}/>;
}