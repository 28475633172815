export function getFolderTypes(folderTypesExt, excludeWizard = true) {
    if (folderTypesExt) {
        return folderTypesExt
            .filter((folderTypeExt) => excludeWizard ? folderTypeExt.visibleInWizard : true)
            .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0))
            .map(folderTypeExt => folderTypeExt.slug);
    }
    return [];
}

export function getFolderTypeExtOrdered(folderTypesExt, excludeWizard = true) {
    if (folderTypesExt) {
        return folderTypesExt
            .filter((folderTypeExt) => excludeWizard ? folderTypeExt.visibleInWizard : true)
            .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0));
    }
    return [];
}

export function getFolderTypeExtFromFolderType(folderType, folderTypesExt) {
    if (folderType && folderTypesExt) {
        return folderTypesExt.find(folderTypeExt => folderType === folderTypeExt.slug);
    }
    return false;
}

export function getFieldsFromFolderType(folderType, folderTypesExt) {
    if (folderType && folderTypesExt) {
        const folderTypeExt = getFolderTypeExtFromFolderType(folderType, folderTypesExt);
        return folderTypeExt.fields.map((folderTypeField) => folderTypeField.field);
    }
    return [];
}

export function getFolderTypesFromFolderType(folderType, folderTypesExt) {
    if (folderType && folderTypesExt) {
        const folderTypeExt = getFolderTypeExtFromFolderType(folderType, folderTypesExt);
        return folderTypeExt.folderTypes;
    }
    return [];
}

export function getFolderTypesFilteredByFolderType(folderType, folderTypesExt) {
    if (folderType && folderTypesExt) {
        const filteredFolderTypesExt = folderTypesExt.filter((folderTypeExt) => {
            return (folderTypeExt.folderTypes.includes(folderType));
        });
        return filteredFolderTypesExt.map((folderTypeExt) => {
            return folderTypeExt.folderType;
        });
    }
    return [];
}

export function getFolderTypeField(folderType, folderTypesExt, field) {
    if (folderType && folderTypesExt) {
        const folderTypeExt = getFolderTypeExtFromFolderType(folderType, folderTypesExt);
        return folderTypeExt.fields.find((folderTypeField) => folderTypeField.field === field);
    }
    return null;
}

export function folderTypeHasField(folderType, folderTypesExt, field) {
    return getFieldsFromFolderType(folderType, folderTypesExt).includes(field);
}

export function folderTypeHasFieldRequired(folderType, folderTypesExt, field) {
    if (folderType && folderTypesExt) {
        const folderTypeField = getFolderTypeField(folderType, folderTypesExt, field);
        if (folderTypeField) {
            return folderTypeField.required;
        } else {
            // field not present for folderType
        }
    }
    return false;

}
