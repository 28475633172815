import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {call, put, takeLatest, select} from "redux-saga/effects";
import {getAllDealers} from "./dealersCrud";
import {actionTypes as authActionTypes} from "../../../../modules/Auth/_redux/authRedux";

export const actionTypes = {
    Load: "[Load] Action",
    Loaded: "[Loaded] Action",
    Delete: "[Delete] Action",
    Error: "[Error] Action",
};

const initialState = {
    dealers: undefined,
    error: false,
};

export const reducer = persistReducer(
    {storage, key: "dealers", whitelist: ["dealers", "error"]},
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.Loaded: {
                return {...state, dealers: action.payload.dealers};
            }
            case actionTypes.Delete: {
                return initialState;
            }
            case actionTypes.Error: {
                return {...state, error: true};
            }
            default:
                return state;
        }
    }
);

export const actions = {
    requestDealers: () => ({type: actionTypes.Load}),
    fulfillDealers: dealers => ({type: actionTypes.Loaded, payload: {dealers}}),
    error: (error) => ({type: actionTypes.Error, payload: {error}}),
    delete: () => ({type: actionTypes.Delete}),
};

function fetchDealers() {
    return getAllDealers()
        .then(response => ({response}))
        .catch(error => ({error}))
}

export function* saga() {
    //https://stackoverflow.com/questions/38405700/getstate-in-redux-saga
    yield takeLatest(authActionTypes.UserLoaded, function* requestDealersSaga() {
        yield put(actions.requestDealers());
    });

    yield takeLatest(actionTypes.Load, function* loadDealersSaga() {
        const state = yield select();
        if (!state.dealers.dealers) {
            // https://redux-saga.js.org/docs/basics/ErrorHandling.html
            const {response, error} = yield call(fetchDealers)
            if (response) {
                yield put(actions.fulfillDealers(response.data.concs));
            } else {
                yield put(actions.error(error));
            }
            // const {data: dealers} = yield getAllDealers();
            // yield put(actions.fulfillDealers(dealers.concs));
        }
    });

    yield takeLatest(authActionTypes.Logout, function* requestDealersSaga() {
        yield put(actions.delete());
    });

}
