import React from "react";
import {useField, Field} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {FieldTooltip} from "./FieldTooltip";

const getFieldCSSClasses = (touched, errors, withValidationClasses) => {
    const classes = [];
    if (withValidationClasses) {
        if (touched && errors) {
            classes.push("is-invalid");
        }

        if (touched && !errors) {
            classes.push("is-valid");
        }
    }

    return classes.join(" ");
};

export function RadioGroup({
                               label,
                               options = [],
                               initialFeedBackLabel,
                               withFeedbackLabel = true,
                               withValidationClasses = true,
                               type = "select",
                               customFeedbackLabel,
                               children,
                               helpText = "",
                               ...props
                           }) {
    const [field, meta] = useField(props);
    const {touched, error} = meta;

    return (
        <>
            {label && <label>{label}<FieldTooltip text={helpText}/></label>}

            <div key={field.name + "_inline-radio"} className="mb-3">
                {options.map((option) =>
                    <div
                        key={field.name + "_inline-radio_" + option.value}
                        className="form-check form-check-inline "
                    >
                        <Field
                            className={`form-check-input ${getFieldCSSClasses(touched, error, withValidationClasses)}`}
                            name={field.name}
                            type="radio"
                            id={field.name + "_" + option.value}
                            {...field}
                            {...props}
                            value={option.value}
                        />
                        <label title="" htmlFor={field.name + "_" + option.value} className="form-check-label">{option.label}</label>
                    </div>
                )}
            </div>

            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={error}
                    touched={touched}
                    label={label}
                    initialFeedBackLabel={initialFeedBackLabel}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
