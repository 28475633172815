import React, {useEffect, useMemo, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import {useDocumentsUIContext} from "../DocumentsUIContext";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {STATE_FETCHED, STATE_FETCHING, userCanSeeSignQrCode} from "../../../../../../_intesys/_helpers";
import * as actions from "../../../_redux/documents/documentsActions";
import ErrorAlert from "../../../../Common/error-alert/ErrorAlert";
import * as  QRCode from "qrcode.react";
import * as foldersActions from "../../../_redux/folders/foldersActions";
import * as dossiersActions from "../../../_redux/dossiers/dossiersActions";
import jwt from 'jsonwebtoken';
import {FontAwesomeButton} from "../../../../Common/button/FontAwesomeButton";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import {readFolderSignatureType} from "../../../_redux/folders/foldersActions";

function DocumentQrCodeDialog({
                                  intl,
                              }) {

    // const [dossierId, setDossierId] = useState();
    const [folderId, setFolderId] = useState();

    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
        return {
            id: documentsUIContext.selectedId,
            showDocumentQrCodeDialog: documentsUIContext.showDocumentQrCodeDialog,
            closeDocumentQrCodeDialog: documentsUIContext.closeDocumentQrCodeDialog,
        };
    }, [documentsUIContext]);

    const {document, qrCode, isLoading, isFetched, error, folder, user, dossier, signatureType} = useSelector(
        (state) => ({
            document: state.documents.itemForEdit,
            //https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
            qrCode: state.documents.qrCodes[documentsUIProps.id]?.qrCode,
            isLoading: state.documents.qrCodes[documentsUIProps.id]?.state === STATE_FETCHING,
            isFetched: state.documents.qrCodes[documentsUIProps.id]?.state === STATE_FETCHED,
            error: state.documents.qrCodes[documentsUIProps.id]?.error,

            folder: state.folders.itemForEdit,
            user: state.auth.user,

            dossier: state.dossiers.itemForEdit,
            signatureType: state.folders.folderSignatureTypes[folderId]?.data,

        }),
        shallowEqual
    );


    const dispatch = useDispatch();


    useEffect(() => {
        if (documentsUIProps.showDocumentQrCodeDialog) {
            if (documentsUIProps.id) {
                if (!document || document.id !== documentsUIProps.id) {
                    dispatch(actions.fetchDocument(documentsUIProps.id));
                }
            }
        }
    }, [documentsUIProps.showDocumentQrCodeDialog, documentsUIProps.id, dispatch, document]);

    useEffect(() => {
        if (documentsUIProps.showDocumentQrCodeDialog) {
            if (document) {
                if (!folder || folder.id !== document.folderId) {
                    dispatch(foldersActions.fetchFolder(document.folderId));
                }
            }
        }
    }, [documentsUIProps.showDocumentQrCodeDialog, document, folder, dispatch]);

    useEffect(() => {
        if (documentsUIProps.showDocumentQrCodeDialog) {
            if (folder) {
                if (!dossier || dossier.id !== folder.dossierId) {
                    dispatch(dossiersActions.fetchDossier(folder.dossierId));
                }
            }
        }
    }, [documentsUIProps.showDocumentQrCodeDialog, folder, dossier, dispatch]);

    // useEffect(() => {
    //     if (dossier) {
    //         setDossierId(dossier.id);
    //         dispatch(readDossierSignatureType(dossier.id));
    //     }
    // }, [dossier, dispatch]);

    useEffect(() => {
        if (documentsUIProps.showDocumentQrCodeDialog) {
            if (folder) {
                setFolderId(folder.id);
                dispatch(readFolderSignatureType(folder.id));
            }
        }
    }, [documentsUIProps.showDocumentQrCodeDialog, folder, dispatch]);


    const [showQrCode, setShowQrCode] = useState(false);

    useEffect(() => {
        if (userCanSeeSignQrCode(user, folder, document, signatureType)) {
            setShowQrCode(true);
        }
    }, [user, folder, document, signatureType]);

    useEffect(() => {
        if (documentsUIProps.showDocumentQrCodeDialog) {
            if (documentsUIProps.id) {
                if (showQrCode) {
                    dispatch(actions.getDocumentQrCode(documentsUIProps.id));
                }
            }
        }
    }, [documentsUIProps.showDocumentQrCodeDialog, documentsUIProps.id, showQrCode, dispatch]);

    const [isExpired, setIsExpired] = useState(false);
    useEffect(() => {

        if (qrCode) {
            const decodedToken = jwt.decode(qrCode.token);
            const currentTime = Date.now() / 1000;
            const isExpired = decodedToken.exp < currentTime;
            if (isExpired) {
                setIsExpired(true);
            }
        }
    }, [qrCode]);

    useEffect(() => {
        if (qrCode) {
            const decodedToken = jwt.decode(qrCode.token);
            const tokenExpire = decodedToken.exp;

            const interval = setInterval(() => {
                const currentTime = Date.now() / 1000;
                const isExpired = tokenExpire < currentTime;
                if (isExpired) {
                    setIsExpired(true);
                    clearInterval(interval);
                }
            }, 1000);
            return () => {
                clearInterval(interval);
                setIsExpired(false);
            };

        }
    }, [qrCode]);

    const reloadQrCode = () => {
        dispatch(actions.getDocumentQrCode(documentsUIProps.id));
        setIsExpired(false);
    }
    return (
        <Modal
            show={documentsUIProps.showDocumentQrCodeDialog}
            onHide={documentsUIProps.closeDocumentQrCodeDialog}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <FormattedMessage id={"DOCUMENTS.QR_CODE.TITLE"}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                {isLoading && (
                    <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success"/>
                    </div>
                )}
                <ErrorAlert error={error}/>
                {isExpired && <div className={"text-center"}>
                    <FontAwesomeButton
                        icon={faRedo}
                        onClick={reloadQrCode}
                        text={<FormattedMessage id={"DOCUMENTS.QR_CODE.BUTTONS.REGENERATE_QR_CODE"}/>}
                    />

                </div>}
                {(showQrCode && isFetched && !isExpired) &&
                    <div className={"text-center"}>
                        <QRCode value={qrCode.token} size={200} className={"mx-auto"}/>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={documentsUIProps.closeDocumentQrCodeDialog}
                    className="btn btn-light btn-elevate"
                >
                    <FormattedMessage id={"GENERIC.CANCEL"}/>
                </button>
            </Modal.Footer>
        </Modal>
    );
}


export default injectIntl(DocumentQrCodeDialog);
