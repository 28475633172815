import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useLocation} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_intesys/layout";
import {actions} from "../_redux/authRedux";

export default function Logged() {
    const [redirectToError, setRedirectToError] = useState(false);
    const [redirectTo, setRedirectTo] = useState("/");

    const {hasAuthToken, hasUser} = useSelector(
        (state) => ({
            hasAuthToken: Boolean(state.auth.authToken),
            hasUser: Boolean(state.auth.user),
        }),
        shallowEqual
    );

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('redirect')) {
            const redirect = searchParams.get('redirect');
            setRedirectTo(redirect);
        }

        if (searchParams.has('JWT_TOKEN')) {
            const jwtToken = searchParams.get('JWT_TOKEN');
            dispatch(actions.userLogged(jwtToken));
        }

    }, [location, dispatch]);


    useEffect(() => {
        const timer = setTimeout(() => {
            setRedirectToError(true);
        }, 10000);
        return () => clearTimeout(timer);
    }, []);


    return redirectToError ?
        <Redirect to="/error/error-v1"/> :
        (
            (hasAuthToken && hasUser) ?
                <Redirect to={redirectTo}/>
                :
                <LayoutSplashScreen/>
        );
}