import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {FieldTooltip} from "./FieldTooltip";
import {getIn} from "formik";

const getFieldCSSClasses = (touched, errors, withValidationClasses) => {
    const classes = ["form-control"];
    if (withValidationClasses) {
        if (touched && errors) {
            classes.push("is-invalid");
        }

        if (touched && !errors) {
            classes.push("is-valid");
        }
    }

    return classes.join(" ");
};

export function Input({
                          field, // { name, value, onChange, onBlur }
                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          label,
                          initialFeedBackLabel,
                          withFeedbackLabel = true,
                          withValidationClasses = true,
                          customFeedbackLabel,
                          type = "text",
                          helpText = "",
                          ...props
                      }) {

    const fieldError = getIn(errors, field.name);
    const fieldTouched = getIn(touched, field.name);

    return (
        <>
            {label && <label>{label}<FieldTooltip text={helpText}/></label>}
            <input
                type={type}
                className={getFieldCSSClasses(fieldTouched, fieldError, withValidationClasses)}
                {...field}
                {...props}
                value={field.value || ''}
            />
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={fieldError}
                    touched={fieldTouched}
                    label={label}
                    initialFeedBackLabel={initialFeedBackLabel}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
