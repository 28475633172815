import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export function FieldTooltip({text = null}) {
    return <span className={"ml-2"}>
        {text &&
            <OverlayTrigger

                overlay={<Tooltip id="dossier-hst-brands">
                    {text}
                </Tooltip>}
            >
                <sup className={"svg-icon svg-icon-sm svg-logo-primary"}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                </sup>
            </OverlayTrigger>
        }
    </span>;
}