import React from "react";
import {Modal} from "react-bootstrap";
import {Formik, Form} from "formik";
import {FormattedMessage, injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {getDocumentsValidationSchema} from "../../../../../../_intesys/_helpers";
import ErrorAlert from "../../../../Common/error-alert/ErrorAlert";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DocumentEditDialogFormFields from "./DocumentEditDialogFormFields";


export function DocumentEditDialogForm({saveDocument, document, documentError, folderType, isLoading, onHide, isCreating, isUpdating, showSaveButton, intl}) {

    const {folderTypesExt} = useSelector(state => state.configurations);

    const isEdit = document.id > 0;

    const DocumentEditSchema = getDocumentsValidationSchema(isEdit, folderType, folderTypesExt);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={document}
                validationSchema={DocumentEditSchema}
                onSubmit={(values) => saveDocument(values)}
            >
                {({handleSubmit, values, setFieldValue}) => (
                    <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            {isLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success"/>
                                </div>
                            )}
                            <Form className="form form-label-right">

                                <ErrorAlert error={documentError}/>

                                <DocumentEditDialogFormFields
                                    isEdit={isEdit}
                                    folderType={folderType}
                                    documentType={values.type}
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                                disabled={isCreating || isUpdating}
                            >
                                <FontAwesomeIcon icon={faTimes} className={"mr-1"}/>
                                <FormattedMessage id={"GENERIC.CANCEL"}/>
                            </button>
                            {showSaveButton &&
                                <button
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    className="btn btn-primary btn-elevate ml-3"
                                    disabled={isCreating || isUpdating}
                                >
                                    <FontAwesomeIcon icon={faSave} className={"mr-1"}/>
                                    <FormattedMessage id={"GENERIC.SAVE"}/>
                                </button>
                            }
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );

}


export default injectIntl(DocumentEditDialogForm);