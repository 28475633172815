import * as requestFromServer from "./usersCrud";
import {usersSlice} from "./usersSlice";
import {BRANDS, callTypes, getFiltersFromQueryParams} from "../../../../../_intesys/_helpers";
import CPromise from "c-promise2";
import {saveAs} from 'file-saver';

const {actions} = usersSlice;

let fetchUsersChain = null;

export const fetchUsers = queryParams => dispatch => {
    const callType = callTypes.list;

    fetchUsersChain && fetchUsersChain.cancel();

    dispatch(actions.startCall({callType}));

    fetchUsersChain = new CPromise((resolve, reject, {onCancel}) => {
        const params = getFiltersFromQueryParams(queryParams,);
        requestFromServer
            .findUsers(params)
            .then(resolve, reject);
    }).then(response => {
        const totalCount = +response.headers["x-total-count"];
        const entities = response.data;
        dispatch(actions.itemsFetched({totalCount, entities}));
    }).catch(error => {
        if (!CPromise.isCanceledError(error)) {
            dispatch(actions.catchError({error, callType}));
        }
    });
};

export const fixUserDataForApp = (user) => {
    user.brands.forEach((brandObj) => {
        user["brand_" + brandObj.brand] = [...brandObj.permissions];
    });
    BRANDS.forEach((brandObj) => {
        const brand = brandObj.value;
        if (user["brand_" + brand] === undefined) {
            user["brand_" + brand] = [];
        }
    });
    return user;
}

export const fetchUser = login => dispatch => {
    const callType = callTypes.fetch;

    if (!login) {
        return dispatch(actions.itemFetched({item: undefined}));
    }
    dispatch(actions.startCall({callType}));
    return requestFromServer
        .getUserByLogin(login)
        .then(response => {
            let user = response.data;
            const userFetched = fixUserDataForApp(user);
            dispatch(actions.itemFetched({item: userFetched}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

const fixUserDataForApi = (user) => {
    const result = {...user};

    let brands = [];
    BRANDS.forEach((brandObj) => {
        const brand = brandObj.value;
        brands.push({
            "brand": brand,
            "permissions": result["brand_" + brand]
        });
    });

    result.brands = brands;
    return result;
}

export const createUser = user => dispatch => {
    const callType = callTypes.create;
    dispatch(actions.startCall({callType}));

    const userToCreate = fixUserDataForApi(user);

    return requestFromServer
        .createUser(userToCreate)
        .then(response => {
            const user = response.data;
            const userInserted = fixUserDataForApp(user);
            dispatch(actions.itemCreated({item: userInserted}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const exportUsers = () => dispatch => {
    const callType = callTypes.create;

    return requestFromServer
        .exportUsers()
        .then(response => {
            if (response.status === 200) {
                const blob = new Blob([response.data], {type: 'application/octet-stream'});
                saveAs(blob, "Export_Utenti.xlsx");
            }
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};

export const updateUser = user => dispatch => {
    const callType = callTypes.update;
    dispatch(actions.startCall({callType}));

    const userToUpdate = fixUserDataForApi(user);
    return requestFromServer
        .updateUser(userToUpdate)
        .then(response => {
            const user = response.data;
            const userUpdated = fixUserDataForApp(user);
            dispatch(actions.itemUpdated({item: userUpdated}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType}));
        });
};


export const fetchRoles = () => dispatch => {
    return requestFromServer
        .getAllRoles()
        .then(response => {
            const roles = response.data;
            dispatch(actions.rolesFetched({roles}));
        })
        .catch(error => {
        });
};
