import {injectIntl} from "react-intl";
import {setLocale} from 'yup';
import printValue from 'yup/lib/util/printValue';
import {useLocation} from "react-router-dom";

export function YupSetLocale(props) {
    const {intl} = props;
    const location = useLocation();

    const getContext = () => {
        if (window.yupContext) {
            return window.yupContext;
        } else {
            const pathName = location.pathname;
            const pathNameArray = pathName.split('/');
            const result = pathNameArray[2];
            if (result) {
                return result.toUpperCase();
            }
            console.error("Yup context not found", location.pathname);
            return "NOTFOUND";
        }

    }
    //TODO: find better way to get FIELD NAME

    let getTranslatedPath = (path) => intl.formatMessage({id: getContext() + ".FIELDS." + path.toUpperCase() + ".LABEL"});

    //https://github.com/jquense/yup
    //https://github.com/LoicMahieu/yup-locales
    //https://github.com/jquense/yup/blob/master/src/locale.js
    //https://github.com/LoicMahieu/yup-locales/pull/16/commits/70e085b5aebee8c721e94115d1fa6265dfe84a14
    setLocale({
        // use constant translation keys for messages without values
        mixed: {
            default: ({path}) => intl.formatMessage({id: "YUP.MIXED.DEFAULT",}, {path: getTranslatedPath(path)}),
            required: ({path}) => {
                if (typeof path === 'string' && path.indexOf('variableFields_') !== -1) {
                    return intl.formatMessage({id: "YUP.MIXED.REQUIRED_GENERIC"});
                } else {
                    return intl.formatMessage({id: "YUP.MIXED.REQUIRED",}, {path: getTranslatedPath(path)});
                }
            },
            oneOf: ({path, values}) => intl.formatMessage({id: "YUP.MIXED.ONE_OF",}, {path: getTranslatedPath(path), values: values}),
            notOneOf: ({path, values}) => intl.formatMessage({id: "YUP.MIXED.NOT_ONE_OF",}, {path: getTranslatedPath(path), values: values}),
            notType: ({path, type, value, originalValue}) => {
                let isCast = originalValue != null && originalValue !== value;
                let msg =
                    intl.formatMessage({id: "YUP.MIXED.NOT_TYPE",}, {
                        path: getTranslatedPath(path),
                        type: type,
                        value: printValue(value, true)
                    }) +
                    (isCast
                        ? intl.formatMessage({id: "YUP.MIXED.NOT_TYPE.CAST",}, {value: printValue(originalValue, true)})
                        : '')
                    + '.';
                if (value === null) {
                    msg += intl.formatMessage({id: "YUP.MIXED.NOT_TYPE.NULL",});
                }
                return msg;
            },
            defined: ({path}) => intl.formatMessage({id: "YUP.MIXED.DEFINED",}, {path: getTranslatedPath(path)}),
        },
        string: {
            length: ({path, length}) => intl.formatMessage({id: "YUP.STRING.LENGTH",}, {path: getTranslatedPath(path), length: length}),
            min: ({path, min}) => intl.formatMessage({id: "YUP.STRING.MIN",}, {path: getTranslatedPath(path), min: min}),
            max: ({path, max}) => intl.formatMessage({id: "YUP.STRING.MAX",}, {path: getTranslatedPath(path), max: max}),
            matches: ({path, regex}) => intl.formatMessage({id: "YUP.STRING.MATCHES",}, {path: getTranslatedPath(path), regex: regex.toString()}),
            email: ({path}) => intl.formatMessage({id: "YUP.STRING.EMAIL",}, {path: getTranslatedPath(path)}),
            url: ({path}) => intl.formatMessage({id: "YUP.STRING.URL",}, {path: getTranslatedPath(path)}),
            trim: ({path}) => intl.formatMessage({id: "YUP.STRING.TRIM",}, {path: getTranslatedPath(path)}),
            lowercase: ({path}) => intl.formatMessage({id: "YUP.STRING.LOWERCASE",}, {path: getTranslatedPath(path)}),
            uppercase: ({path}) => intl.formatMessage({id: "YUP.STRING.UPPERCASE",}, {path: getTranslatedPath(path)}),
        },
        number: {
            min: ({path, min}) => intl.formatMessage({id: "YUP.NUMBER.MIN",}, {path: getTranslatedPath(path), min: min}),
            max: ({path, max}) => intl.formatMessage({id: "YUP.NUMBER.MAX",}, {path: getTranslatedPath(path), max: max}),
            lessThan: ({path, less}) => intl.formatMessage({id: "YUP.NUMBER.LESS_THAN",}, {path: getTranslatedPath(path), less: less}),
            moreThan: ({path, more}) => intl.formatMessage({id: "YUP.NUMBER.MORE_THAN",}, {path: getTranslatedPath(path), more: more}),
            positive: ({path}) => intl.formatMessage({id: "YUP.NUMBER.POSITIVE",}, {path: getTranslatedPath(path)}),
            negative: ({path}) => intl.formatMessage({id: "YUP.NUMBER.NEGATIVE",}, {path: getTranslatedPath(path)}),
            integer: ({path}) => intl.formatMessage({id: "YUP.NUMBER.INTEGER",}, {path: getTranslatedPath(path)}),
        },
        date: {
            min: ({path, min}) => intl.formatMessage({id: "YUP.DATE.MIN",}, {path: getTranslatedPath(path), min: min}),
            max: ({path, max}) => intl.formatMessage({id: "YUP.DATE.MAX",}, {path: getTranslatedPath(path), max: max}),
        },
        boolean: {},
        object: {
            noUnknown: ({path}) => intl.formatMessage({id: "YUP.OBJECT.NO_UNKNOWN",}, {path: getTranslatedPath(path)}),
        },
        array: {
            min: ({path, min}) => intl.formatMessage({id: "YUP.ARRAY.MIN",}, {path: getTranslatedPath(path), min: min}),
            max: ({path, max}) => intl.formatMessage({id: "YUP.ARRAY.MAX",}, {path: getTranslatedPath(path), max: max}),
        }
    });
    return null;
}

export default injectIntl(YupSetLocale);