import {createSlice} from "@reduxjs/toolkit";
import {
    initialState,
    resetItemState,
    startCall,
    catchError,
    itemFetched,
    itemsFetched,
    itemCreated,
    itemUpdated,
    itemDeleted,
    saveDone,
    STATE_INITIAL, STATE_FETCHING, STATE_FETCHED, getError, STATE_ERROR
} from "../../../../../_intesys/_helpers";

const initialFolderSignatureTypesState = {
    state: STATE_INITIAL,
    error: null,
    data: undefined,
};


export const foldersSlice = createSlice({
    name: "folders",
    initialState: {
        ...initialState,

        folderSignatureTypes: {},
    },
    reducers: {
        resetItemState: resetItemState,
        saveDone: saveDone,
        catchError: catchError,
        startCall: startCall,
        itemsFetched: itemsFetched,
        itemFetched: itemFetched,
        itemCreated: itemCreated,
        itemUpdated: itemUpdated,
        itemDeleted: itemDeleted,

        getFolderSignatureTypesStart: (state, action) => {
            const {key} = action.payload;
            if (state.folderSignatureTypes[key] === undefined) {
                state.folderSignatureTypes[key] = {...initialFolderSignatureTypesState};
            }
            state.folderSignatureTypes[key].state = STATE_FETCHING;
        },
        getFolderSignatureTypesDone: (state, action) => {
            const {key} = action.payload;

            state.folderSignatureTypes[key].state = STATE_FETCHED;
            state.folderSignatureTypes[key].data = action.payload.data;
            state.folderSignatureTypes[key].error = null;
        },
        getFolderSignatureTypesError: (state, action) => {
            const error = getError(action);
            const {key} = action.payload;

            state.folderSignatureTypes[key].state = STATE_ERROR;
            state.folderSignatureTypes[key].data = undefined;
            state.folderSignatureTypes[key].error = error;
        }
    }
});
