import {createSlice} from "@reduxjs/toolkit";
import {STATE_INITIAL, STATE_FETCHING, STATE_FETCHED, STATE_ERROR, getError} from "../../../../../_intesys/_helpers";


const initialState = {
    smsUsedState: STATE_INITIAL,
    smsUsed: undefined,
    smsUsedError: null,

    smsTotal: 1000,

    spaceUsedState: STATE_INITIAL,
    spaceUsed: undefined,
    spaceUsedError: null,

    spaceTotal: 1000,

    documentsState: STATE_INITIAL,
    documents: [],
    documentsError: null,

    signedDocumentsCountState: STATE_INITIAL,
    signedDocumentsCount: undefined,
    signedDocumentsCountError: null,

};

export const statisticsSlice = createSlice({
    name: "statistics",
    initialState: initialState,
    reducers: {

        smsUsedCall: (state, action) => {
            state.smsUsedState = STATE_FETCHING;
            state.smsUsedError = null;
        },
        smsUsedDone: (state, action) => {
            state.smsUsedState = STATE_FETCHED;
            state.smsUsed = action.payload.smsUsed;

            if (action.payload.smsTotal) {
                state.smsTotal = action.payload.smsTotal;
            } else {
                //TODO: check why total is null
                state.smsTotal = action.payload.smsUsed + 1;
            }
            //state.smsTotal = (action.payload.smsUsed * 1.2).toFixed(0);
            state.smsUsedError = null;
        },
        smsUsedError: (state, action) => {
            const error = getError(action);
            state.smsUsedState = STATE_ERROR;
            state.smsUsed = undefined;
            state.smsUsedError = error;
        },

        spaceUsedCall: (state, action) => {
            state.spaceUsedState = STATE_FETCHING;
            state.spaceUsedError = null;
        },
        spaceUsedDone: (state, action) => {
            state.spaceUsedState = STATE_FETCHED;
            // state.spaceUsed = action.payload.spaceUsed;
            // state.spaceTotal = action.payload.spaceUsed.totalSize.size * 15;

            state.spaceUsed = action.payload.spaceUsed;

            if (action.payload.spaceTotal) {
                state.spaceTotal = action.payload.spaceTotal;
            } else {
                //TODO: check why total is null
                state.spaceTotal = action.payload.spaceUsed + 1;
            }
            state.spaceUsedError = null;
        },
        spaceUsedError: (state, action) => {
            const error = getError(action);
            state.spaceUsedState = STATE_ERROR;
            state.spaceUsed = undefined;
            state.spaceUsedError = error;
        },
        spaceArchivedUsedDone: (state, action) => {
            state.spaceArchivedUsedState = STATE_FETCHED;
            // state.spaceArchivedUsed = action.payload.spaceArchivedUsed;
            // state.spaceArchivedTotal = action.payload.spaceArchivedUsed.totalSize.size * 15;

            state.spaceArchivedUsed = action.payload.spaceArchivedUsed;

            if (action.payload.spaceArchivedTotal) {
                state.spaceArchivedTotal = action.payload.spaceArchivedTotal;
            } else {
                //TODO: check why total is null
                state.spaceArchivedTotal = action.payload.spaceArchivedUsed + 1;
            }
            state.spaceArchivedUsedError = null;
        },
        spaceArchivedUsedError: (state, action) => {
            const error = getError(action);
            state.spaceArchivedUsedState = STATE_ERROR;
            state.spaceArchivedUsed = undefined;
            state.spaceArchivedUsedError = error;
        },


        documentsCall: (state, action) => {
            state.documentsState = STATE_FETCHING;
            state.documentsError = null;
        },
        documentsFetched: (state, action) => {
            state.documentsState = STATE_FETCHED;
            state.documents = action.payload.documents;
            state.documentsError = null;
        },
        documentsError: (state, action) => {
            const error = getError(action);
            state.documentsState = STATE_ERROR;
            state.documents = undefined;
            state.documentsError = error;
        },

        signedDocumentsCountCall: (state, action) => {
            state.signedDocumentsCountState = STATE_FETCHING;
            state.signedDocumentsCountError = null;
        },
        signedDocumentsCountFetched: (state, action) => {
            state.signedDocumentsCountState = STATE_FETCHED;
            state.signedDocumentsCount = action.payload.signedDocumentsCount;
            state.signedDocumentsCountError = null;
        },
        signedDocumentsCountError: (state, action) => {
            const error = getError(action);
            state.signedDocumentsCountState = STATE_ERROR;
            state.signedDocumentsCount = undefined;
            state.signedDocumentsCountError = error;
        },

        startSignDone: (state, action) => {
            const {document} = action.payload;
            const documentId = document.id;
            //if found in list -> update
            state.documents = state.documents.map(entity => {
                if (entity.id === documentId) {
                    return {...entity, ...document};
                }
                return entity;
            });
        }

    }
});
