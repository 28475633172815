import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {checkIsActive} from "../../../../../_intesys/_helpers";
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {userIsAdmin} from "../../../../_helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faArchive, faKey, faUsers} from "@fortawesome/free-solid-svg-icons";

export function HeaderMenu({layoutProps}) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    const {user} = useSelector(state => state.auth);
    const isAdmin = userIsAdmin(user);
    let adminMenu = undefined;

    if (isAdmin) {
        adminMenu = <>
            {/* Admin */}
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/admin')}`}>
                <NavLink className="menu-link menu-toggle" to="/google-material">
                    <span className="menu-text">
                        <FormattedMessage id={"MENU.ADMIN"}/>
                    </span>
                    <i className="menu-arrow"/>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/admin/users')}`}>
                            <NavLink className="menu-link" to="/admin/users">
                                <span className="svg-icon menu-icon">
                                    <FontAwesomeIcon icon={faUsers}/>
                                </span>
                                <span className="menu-text">
                                    <FormattedMessage id={"MENU.USERS"}/>
                                </span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/app/licenses')}`}>
                            <NavLink className="menu-link" to="/app/licenses">
                                <span className="svg-icon menu-icon">
                                    <FontAwesomeIcon icon={faKey}/>
                                </span>
                                <span className="menu-text">
                                    <FormattedMessage id={"MENU.LICENSES"}/>
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>

            </li>
            {/*end::1 Level*/}
        </>
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">
                        <FormattedMessage id={"MENU.DASHBOARD"}/>
                    </span>
                    {/*{layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}*/}
                </NavLink>
            </li>
            {/*end::1 Level*/}


            {adminMenu}

            {/*App*/}
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/app/')}`}>
                <NavLink className="menu-link menu-toggle" to="/app">
                    <span className="menu-text">
                        <FormattedMessage id={"MENU.APP"}/>
                    </span>
                    <i className="menu-arrow"/>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/app/dossiers')}`}>
                            <NavLink className="menu-link" to="/app/dossiers">
                                <span className="svg-icon menu-icon">
                                    <FontAwesomeIcon icon={faAddressBook}/>
                                </span>
                                <span className="menu-text">
                                    <FormattedMessage id={"MENU.DOSSIERS"}/>
                                </span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/app/folders')}`}>
                            <NavLink className="menu-link" to="/app/folders">
                                <span className="svg-icon menu-icon">
                                    <FontAwesomeIcon icon={faArchive}/>
                                </span>
                                <span className="menu-text">
                                    <FormattedMessage id={"MENU.FOLDERS"}/>
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

        </ul>
        {/*end::Header Nav*/}
    </div>;
}
