export const STATE_INITIAL = "INITIAL";

export const STATE_ERROR = "ERROR";
export const STATE_FETCHING = "FETCHING";
export const STATE_FETCHED = "FETCHED";
export const STATE_CREATING = "CREATING";
export const STATE_CREATED = "CREATED";
export const STATE_UPDATING = "UPDATING";
export const STATE_UPDATED = "UPDATED";
export const STATE_DELETING = "DELETING";
export const STATE_DELETED = "DELETED";

export const initialState = {
    // list
    listState: STATE_INITIAL,
    listEntities: [],
    listError: null,
    listTotalCount: 0,

    //single
    itemState: STATE_INITIAL,
    itemForEdit: undefined,
    itemError: null,
    itemLastId: undefined,
    itemLast: undefined,


    //list by key
    listByKey: {},

    //downloads
    downloads: {},
    // download: undefined,
    // downloadState: STATE_INITIAL,
    // downloadError: null,
};

export const callTypes = {
    list: "list",
    listByKey: "listByKey",
    // createOrUpdate: "createOrUpdate",
    fetch: "fetch",
    create: "create",
    update: "update",
    delete: "delete",
};

const initialListByKeyState = {
    listState: STATE_INITIAL,
    listEntities: [],
    listError: null,
    listTotalCount: 0,
};

const initialDownloadState = {
    state: STATE_INITIAL,
    error: null,
    download: undefined,
};


export const resetItemState = (state, action) => {
    state.itemState = STATE_FETCHED;
    state.itemError = null;
    state.itemForEdit = undefined;
    state.itemLastId = undefined;
    state.itemLast = undefined;
};


export const startCall = (state, action) => {
    const {key} = action.payload;

    switch (action.payload.callType) {
        case callTypes.list:
            state.listState = STATE_FETCHING;
            state.listError = null;
            // state.listEntities = [];
            // state.listTotalCount = 0;
            break;
        case callTypes.fetch:
            state.itemState = STATE_FETCHING;
            state.itemError = null;
            state.itemForEdit = undefined;
            state.itemLastId = undefined;
            state.itemLast = undefined;
            break;
        case callTypes.create:
            state.itemState = STATE_CREATING;
            state.itemError = null;
            state.itemForEdit = undefined;
            state.itemLastId = undefined;
            state.itemLast = undefined;
            break;
        case callTypes.update:
            state.itemState = STATE_UPDATING;
            state.itemError = null;
            //state.itemForEdit = undefined;
            state.itemLastId = undefined;
            state.itemLast = undefined;
            break;
        case callTypes.delete:
            state.itemState = STATE_DELETING;
            state.itemError = null;
            state.itemForEdit = undefined;
            state.itemLastId = undefined;
            state.itemLast = undefined;
            break;
        case callTypes.listByKey:
            if (state.listByKey[key] === undefined) {
                state.listByKey[key] = {...initialListByKeyState};
            }
            state.listByKey[key].listState = STATE_FETCHING;
            break
        default:
            break;
    }
};

export const getError = (action) => {
    let error = {
        type: action.type,
        error: action.payload.error,
        // callType: action.payload.callType,
        callType: null
    };
    if (action.payload.callType) {
        error.callType = action.payload.callType;
    }
    return error;
}

export const catchError = (state, action) => {
    const error = getError(action);
    console.error(error);
    const {key} = action.payload;

    switch (action.payload.callType) {
        case callTypes.list:
            state.listState = STATE_ERROR;
            state.listError = error;
            break;
        case callTypes.fetch:
            state.itemState = STATE_ERROR;
            state.itemError = error;
            break;
        case callTypes.create:
            state.itemState = STATE_ERROR;
            state.itemError = error;
            break;
        case callTypes.update:
            state.itemState = STATE_ERROR;
            state.itemError = error;
            break;
        case callTypes.delete:
            state.itemState = STATE_ERROR;
            state.itemError = error;
            break;
        case callTypes.listByKey:
            state.listByKey[key].listError = error;
            state.listByKey[key].listState = STATE_ERROR;
            break;

        default:
            break;
    }
};


export const itemsFetched = (state, action) => {
    const {totalCount, entities} = action.payload;

    state.listState = STATE_FETCHED;
    state.listError = null;
    state.listEntities = entities;
    state.listTotalCount = totalCount;
};

export const itemsByKeyFetched = (state, action) => {
    const {totalCount, entities, key} = action.payload;

    state.listByKey[key].listState = STATE_FETCHED;
    state.listByKey[key].listError = null;
    state.listByKey[key].listEntities = entities;
    state.listByKey[key].listTotalCount = totalCount;
};


export const itemFetched = (state, action) => {
    const {item} = action.payload;

    state.itemState = STATE_FETCHED;
    state.itemError = null;
    state.itemForEdit = item;
    if (item) {
        state.itemLastId = item.id;
        state.itemLast = item;
    }
};

export const itemDeleted = (state, action) => {
    const {id} = action.payload;
    state.itemState = STATE_DELETED;
    state.itemError = null;
    state.itemForEdit = null;

    if (state.listEntities.length) {
        state.listEntities = state.listEntities.filter(el => el.id !== parseInt(id));
    }

    for (const [key] of Object.entries(state.listByKey)) {
        state.listByKey[key].listEntities = state.listByKey[key].listEntities.filter(el => el.id !== parseInt(id));
    }
}

export const itemCreated = (state, action) => {
    const {item} = action.payload;

    state.itemState = STATE_CREATED;
    state.itemError = null;
    state.itemForEdit = item;
    if (item.id) {
        state.itemLastId = item.id;
        state.itemLast = item;
    }

    //TODO: verify!!!
    //not good idea because item in the list is different from single
    /*
    const { key} = action.payload;
    state.listEntities.push(item);

    if (key && state.listByKey !== undefined && state.listByKey[key] !== undefined) {
        state.listByKey[key].listEntities.push(item);
    }
     */
}
export const itemUpdated = (state, action) => {
    const {item} = action.payload;

    state.itemState = STATE_UPDATED;
    state.itemError = null;
    state.itemForEdit = item;
    if (item.id) {
        state.itemLastId = item.id;
        state.itemLast = item;
    }

    //not good idea because item in the list is different from single
    /*
    const { key} = action.payload;
    state.listEntities = state.listEntities.map(entity => {
        if (entity.id === item.id) {
            return item;
        }
        return entity;
    });


    if (key && state.listByKey !== undefined && state.listByKey[key] !== undefined) {
        state.listByKey[key].listEntities = state.listByKey[key].listEntities.map(entity => {
            if (entity.id === item.id) {
                return item;
            }
            return entity;
        });
    }
    */
};

export const saveDone = (state, action) => {
    state.itemState = STATE_FETCHED;
    //in dossiers edit if you reset item the page will be blank
    // state.itemError = null;
    // state.itemForEdit = undefined;
    // state.itemLastId = undefined;
};


export const downloadStart = (state, action) => {
    const {key} = action.payload;
    if (state.downloads[key] === undefined) {
        state.downloads[key] = {...initialDownloadState};
    }
    state.downloads[key].state = STATE_FETCHING;
    // state.downloadState = STATE_FETCHING;
    // state.download = undefined;
    // state.downloadError = null;
};

export const downloadDone = (state, action) => {
    const {key} = action.payload;

    state.downloads[key].state = STATE_FETCHED;
    state.downloads[key].download = action.payload.download;
    state.downloads[key].error = null;
    // state.downloadState = STATE_FETCHED;
    // state.download = action.payload.download;
    // state.downloadError = null;
};

export const downloadError = (state, action) => {
    const error = getError(action);
    const {key} = action.payload;

    state.downloads[key].state = STATE_ERROR;
    state.downloads[key].download = undefined;
    state.downloads[key].error = error;
    // state.downloadState = STATE_ERROR;
    // state.download = undefined;
    // state.downloadError = error;
}