import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest, select} from "redux-saga/effects";
import {actionTypes as authActionTypes} from "../../../../modules/Auth/_redux/authRedux";
import {
    getFolderTypesExtV2, getSignatureTypeServices,
} from "./configurationsCrud";

export const actionTypes = {
    Load: "[Load] Action",
    FolderTypesExtLoaded: "[Loaded] Action FolderTypesExt",
    SignatureTypeServicesLoaded: "[Loaded] Action SignatureTypeServicesLoaded",
    Delete: "[Delete] Action",
};

const initialState = {
    folderTypesExt: undefined,
    signatureTypeServices: undefined,
};

export const reducer = persistReducer(
    {
        storage,
        key: "configurations",
        whitelist: ["folderTypesExt", "signatureTypeServices"]
    },
    (state = initialState, action) => {
        switch (action.type) {

            case actionTypes.FolderTypesExtLoaded: {
                return {...state, folderTypesExt: action.payload.folderTypesExt};
            }
            case actionTypes.SignatureTypeServicesLoaded: {
                return {...state, signatureTypeServices: action.payload.signatureTypeServices};
            }

            case actionTypes.Delete: {
                return initialState;
            }
            default:
                return state;
        }
    }
);

export const actions = {
    requestData: () => ({type: actionTypes.Load}),
    fulfillFolderTypesExt: folderTypesExt => ({type: actionTypes.FolderTypesExtLoaded, payload: {folderTypesExt}}),
    fulfillSignatureTypeServices: signatureTypeServices => ({type: actionTypes.SignatureTypeServicesLoaded, payload: {signatureTypeServices}}),
    delete: () => ({type: actionTypes.Delete}),
};

export function* saga() {
    //https://stackoverflow.com/questions/38405700/getstate-in-redux-saga
    yield takeLatest(authActionTypes.UserLoaded, function* requestDealersSaga() {
        yield put(actions.requestData());
    });

    yield takeLatest(actionTypes.Load, function* loadDealersSaga() {
        const state = yield select();
        if (!state.folderTypesExt) {
            const {data: folderTypesExt} = yield getFolderTypesExtV2();
            yield put(actions.fulfillFolderTypesExt(folderTypesExt));
        }
        if (!state.signatureTypeServices) {
            const {data: signatureTypeServices} = yield getSignatureTypeServices();
            yield put(actions.fulfillSignatureTypeServices(signatureTypeServices));
        }
    });

    yield takeLatest(authActionTypes.Logout, function* requestDealersSaga() {
        yield put(actions.delete());
    });

}
